import React from 'react';
import { useContext,useState,useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import Sidebar from './layout/Sidebar';
import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;
const Dashboard = () => {
     const { slug } = useParams();
     const { usertoken} = useContext(AuthContext);
     const [posts, setPosts] = useState({ favdata:{data:[]} });
    const [alertMessage, setAlertMessage] = useState({ visible: false, success: false, title:'', message: '' });
    const [processing, setProcessing] = useState(false);
    const [loading, setLoading] = useState(true);
     useEffect(() => {
        const fetchPosts = async () => {
             try {
                 setLoading(true);
                 const defaultSlug = 'shop-local';
                const actualSlug = slug || defaultSlug;
                 const response = await axios.get(`${apiUrl}/favorite-lists/${actualSlug}`, {
                    headers: {
                        'Authorization': `Bearer ${usertoken}`
                    }
                });
                setPosts(response.data);
               
           } catch (error) {
               // setError('Error fetching blogs');
                setLoading(false);
                console.error('Error fetching blogs:', error);
            } finally {
             setLoading(false);
            }
            };
            
             if (usertoken) {
                    fetchPosts();
                }
           
        }, [usertoken]);
    
    const generateKey = (section, id) => `${section}-${id}`;
    const StarRating = ({ rating }) => {
        const totalStars = 5;
        const filledStars = Math.floor(rating);
        const halfStar = rating % 1 !== 0;
        const emptyStars = totalStars - filledStars - (halfStar ? 1 : 0);

        return (
            <div className="stars">
                {Array(filledStars).fill().map((_, index) => (
                    <img key={`filled-${index}`} src="/img/full-rating-star.png" alt="Star" />
                ))}
                {halfStar && <img src="/img/half-rating-star.png" alt="Half Star" />}
                {Array(emptyStars).fill().map((_, index) => (
                    <img key={`empty-${index}`} src="/img/empty-rating-star.png" alt="Empty Star" />
                ))}
            </div>
        );
    };
    
    
    const onUnFavDeal = async (productId,type) => {
        const token = sessionStorage.getItem('authToken');
         if (!token) {
            setAlertMessage({
                visible: true,
                success: false,
                message: 'Please log in to add this deal to your favorites.',
                title: 'Login Required!'
            });
            return;
        }
        try {
           setProcessing(true);   
            const data = new FormData();
             data.append('id', productId);
             data.append('type', type);
          const response = await axios.post(`${apiUrl}/rm_favorite`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`
                }
            });
            if(response.data.success){
                  setPosts((prevPosts) => ({
                    ...prevPosts,
                    favdata: {
                        ...prevPosts.favdata,
                        data: prevPosts.favdata.data.filter((fav) => fav.id !== productId)
                    }
                }));
                setAlertMessage({ visible: true, success: true, message: response.data.message,title:'Awesome!' });
            }else{
               setAlertMessage({ visible: true, success: false, message: response.data.error,title:'Alert!' });
            }
        } catch (error) {
            setProcessing(false);   
            setAlertMessage({ visible: true, success: false, message: error,title:'Alert!' });
          
        } finally {
            setProcessing(false);  
                
        }
  };
    return (
        <div>
            <div className="main_content">
                <div className="main_title">
                    <div className="container-fluid">
                        <div className="inner_title">
                            <h2>Dashboard</h2>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="breadcrumb">
                        <ul>
                            <li><a href="/">Home</a></li>
                            <li><span><i className="fa fa-angle-right"></i></span></li>
                            <li><span>Dashboard</span></li>
                        </ul>
                    </div>
                </div>
                <div className="dashboard_page">
                    <div className="container-fluid">
                        <div className="inner_dashboard">
                            <Sidebar />
                            <div className="content_right">
                                <div className="dash_title">
                                    <h4>My Favorite Business Listings</h4>
                                </div>
                                 {loading ? (
                            <p>Loading...</p>
                        ) : ( 
                                <div className="dash_business_listing">
                                    {posts.favdata.data.length > 0 ? posts.favdata.data.map((fav,index) => (
                                        <div key={generateKey('favdeal', index)} className="listing_column" id="id_<%= fav.id %>">
                                            <div className="listing_image">
                                                <img src={`${fav.image}`} alt={`${fav.name}`} />
                                            </div>
                                            <div className="listing_info">
                                                <span className="list_title">{fav.name}</span>
                                                <div className="list_stars">
                                                    <StarRating rating={fav.averageRating || 0} /> 
                                                </div>
                                            </div>
                                            <div className="listing_action">
                                                <ul> 
                                                    <li><img src="/img/listing-comment-icon.svg" alt="" /><a href="#">{fav.countRating || 0} Reviews</a></li>
                                                    <li><img src="/img/listing-favorite-icon.svg" alt="" /><a href="#" onClick={(e) => { e.preventDefault(); onUnFavDeal(fav.id, 'shop-local'); }}>{processing ? 'Processing...' : 'Unfavorite'}</a></li>
                                                </ul>
                                            </div>
                                        </div>
                                    )) : !loading && <p>No posts available.</p>}
                                
                                </div>
                                  )}
                            </div>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
            {alertMessage.visible && (
                <AlertModal
                  title={alertMessage.title}
                  message={alertMessage.message}
                  success={alertMessage.success}
                  onClose={() => setAlertMessage({ visible: false })}
                />
              )}
        </div>
    );
};

const AlertModal = ({ title,message, success, onClose }) => {
  return (
    <div className={`modal fade show cust_modal_overlay`} style={{ display: 'block' }} id="myModalalertmessage" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className={`modal-dialog modal-confirm ${success ? 'success' : 'danger'}`}>
        <div className="modal-content">
          <div className="modal-header">
            <div className="icon-box">
						<i className="fa fa-check"></i>
					</div>
            <h4 className="modal-title">{title}</h4>
           
          </div>
          <div className="modal-body">
            <p className="text-center viewmessage">{message}</p>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-success btn-block" onClick={onClose}>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Dashboard;