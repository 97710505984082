// src/components/HomePage.js
import React, { useState, useContext, useEffect   } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
const apiUrl = process.env.REACT_APP_API_URL;
const LoginPage = () => {
    const [username, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
     const navigate = useNavigate();
    const { isAuthenticated,login } = useContext(AuthContext);
    
     useEffect(() => {
        if (isAuthenticated) {
            navigate('/dashboard');
        }
    }, [isAuthenticated, navigate]);
    
    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setErrors('');
        if (password !== confirmPassword) {
             setErrors({ confirmPassword: 'Passwords do not match' });
            setLoading(false);
            return;
        }
        
        try {
            const response = await axios.post(`${apiUrl}/signup`, { username, email, password, password_confirmation: confirmPassword });
            const token = response.data.token;
            const userData = response.data.user;
            login(token, userData);
            navigate('/choose-plan');
        } catch (err) {
             if (err.response && err.response.data.errors) {
                setErrors(err.response.data.errors);
            } else {
                console.error('Registration error:', err.response ? err.response.data : err.message);
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="main_content">
            <div className="main_title">
                <div className="container-fluid">
                    <div className="inner_title">
                        <h2>Register</h2>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
				<div className="breadcrumb">
					<ul>
						<li><a href="/">Home</a></li>
						<li><span><i className="fa fa-angle-right"></i></span></li>
						<li><span>Register</span></li>
					</ul>
				</div>
				<div className="common_account_sec">
					<h4>Sign Up</h4>
					<div className="cus_account_form regis_form">
						<form onSubmit={handleSubmit}>
							<div className="form_field">
								<div className="form_row">
										<div className="form_col colfull">
                                             {errors.general && <span className="alert alert-danger">{errors.general}</span>}
										</div>
									</div>
                                <div className="form_row">
									<div className="form_col colhalf">
										<div className="form-group">
											<div className="input_field">
												<input required type="text" id="username" name="username" className="form-control" placeholder="Username" value={username} onChange={(e) => setName(e.target.value)} />
                                                {errors.username && <span className="text-danger">{errors.username}</span>}
											</div>
										</div>
									</div>
									<div className="form_col colhalf">
										<div className="form-group">
											<div className="input_field">
												<input required id="email" type="email" name="email" className="form-control" placeholder="Email" value={email} onChange={(e) => setEmail(e.target.value)} />
                                                {errors.username && <span className="text-danger">{errors.username}</span>}
											</div>
											
										</div>
									</div>
									<div className="form_col colhalf">
										<div className="form-group">
											<div className="input_field">
												<input id="password" required type="password" name="password" className="form-control" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                                {errors.password  && <span className="text-danger">{errors.password}</span>}
                                                
											</div>
											
										</div>
									</div>
									<div className="form_col colhalf">
										<div className="form-group">
											<div className="input_field">
												<input required type="password" name="password_confirmation" className="form-control" placeholder="Confirm Password" id="confirmPassword" onChange={(e) => setConfirmPassword(e.target.value)} value={confirmPassword} />
                                                {errors.confirmPassword   && <span className="text-danger">{errors.confirmPassword }</span>}
											</div>
										</div>
									</div>
									<div className="form_col colfull">
										<div className="form-group">
											<div className="form-check">
												<input required className="form-check-input" type="checkbox" value="" id="rememberme" id="privacy" />
												<label className="form-check-label" htmlFor="rememberme">I agree to the terms of use and privacy</label>
											</div>
										</div>
									</div>
									<div className="form_col colfull">
										<div className="form-btn">
											<button type="submit" disabled={loading} className="cus_form_btn submit_btn">{loading ? 'Registering...' : 'Create Account'}</button>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
				<div className="clearfix"></div>
			</div>
        </div>
    );
};

export default LoginPage;