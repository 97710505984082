// src/components/HomePage.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { format } from 'date-fns';
import ProductItem from './ProductItem';
import EnquiryModal from './EnquiryModal';
import $ from 'jquery'; 
const apiUrl = process.env.REACT_APP_API_URL;
const ShopPage = () => {
    const [posts, setPosts] = useState({ products: [] });
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [selectedProduct, setSelectedProduct] = useState(null);
    const [searchTerm, setSearchTerm] = useState('');
    const handleShowModal = (productId, quantity) => {
        setSelectedProduct({ productId, quantity });
        setShowModal(true);
    };

    const handleCloseModal = () => {
        setShowModal(false);
    };
    
    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    };
    useEffect(() => {
        const fetchPosts = async () => {
             try {
                const response = await axios.get(`${apiUrl}/products`);
                setPosts(response.data);
                setLoading(false);
           } catch (error) {
                setError('Error fetching products');
                setLoading(false);
                console.error('Error fetching products:', error);
            }
        };

        fetchPosts();
    }, []);
   
    const generateKey = (section, id) => `${section}-${id}`;
    
    return (
        <div>
            <div className="main_content">
                <div className="main_title">
                    <div className="container-fluid">
                        <div className="inner_title">
                            <h2>Shop</h2>
                        </div>
                    </div>
                </div>
                <div className="product_shop">
                    <div className="container-fluid">
                        <div className="breadcrumb">
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li><span><i className="fa fa-angle-right"></i></span></li>
                                <li><a href="/shop">Shop</a></li>
                            </ul>
                            <div className="product_search">
                                <div className="search_field">
                                    <form action="/shop" method="GET">
                                        <input type="text" name="p"  value={searchTerm} className="form-control" placeholder="Search"  onChange={handleChange} />
                                        <button type="submit" id="searchInput" className="search_btn"><img src="/img/search-icon.svg" alt="" /></button>
                                    </form>
                                </div>
                            </div>
                        </div>
                        <div className="shop_product_list">
                        {posts.products.length > 0 ? posts.products.map(product => (
                            <ProductItem key={product.id} product={product} productimg={posts.urllink} onShowModal={handleShowModal}  />
                            )) : !loading && <p>No Product available.</p>}
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
            </div>
        
        {showModal && (
                <EnquiryModal
                    showModal={showModal}
                    handleClose={handleCloseModal}
                    productId={selectedProduct.productId}
                    productQuantity={selectedProduct.quantity}
                />
            )}
            
       </div>
    );
};

export default ShopPage;