import React from 'react';

const ProductFeatures = ({ features }) => {
    // Process features if they exist
    const featuresArray = features ? features.split(',') : [];

    return (
        <div className="prod_feature">
            {featuresArray.length > 0 ? (
                <ul>
                    {featuresArray.map((feature, index) => (
                        <li key={index}><img src="/img/product-feature-icon.svg" alt="" /><span>{feature.trim()}</span></li>
                    ))}
                </ul>
            ) : (
                <p>No features available.</p>
            )}
        </div>
    );
};

export default ProductFeatures;