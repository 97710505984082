// src/components/HomePage.js
import React, { useEffect, useState, useContext } from 'react';
import { useJsApiLoader, GoogleMap, Marker } from '@react-google-maps/api';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
const apiUrl = process.env.REACT_APP_API_URL;
const googleMapApi = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
const containerStyle = {
  width: '100%',
  height: '292px'
};

const center = {
  lat: -3.745,
  lng: -38.523
};
const MenuFiles = ({ menuFiles, urlLink }) => {
    if (!menuFiles || menuFiles.length === 0) {
        return null;
    }

    return (
        <div>
            {menuFiles.map((file, index) => (
                <iframe
                    key={index}
                    src={`${urlLink}/${file.image}`}
                    width="800"
                    height="600"
                    title={`Menu File ${index}`}
                ></iframe>
            ))}
        </div>
    );
};
const GalleryImages = ({ galleryImages, urlLink }) => {
    if (!galleryImages || galleryImages.length === 0) {
        return null;
    }

    return (
        <div className="photogallery_list">
            <ul>
            {galleryImages.map((pimg, index) => (
                <li key={generateKey('imgs', index)}><a href={`${urlLink}/${pimg.image}`} data-lightbox="gallery-images"><img src={`${urlLink}/${pimg.image}`} alt="" style={{ maxWidth: '100px' }} /></a></li>
              
            ))}
            </ul>
        </div>
    );
};
const generateKey = (section, id) => `${section}-${id}`;
const ShopLocalDetailPage = () => {
    const [coordinates, setCoordinates] = useState(center);
     const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: googleMapApi,
    libraries: ['places'],
  });
  const [newReview, setNewReview] = useState(null);
    const { cslug,slug } = useParams();
    const [blog, setBlog] = useState({ detail:{ menu_file: [] }});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
     const [successMessage, setSuccessMessage] = useState('');
     const [errorMessage, setErrorMessage] = useState('');
     const [alertMessage, setAlertMessage] = useState({ visible: false, success: false, title:'', message: '' });
    const [formData, setFormData] = useState({
        fullname: '',
        phone: '',
        business_position: '',
        message: '',
        listing_id: '',
        listing_name: ''
    });
     useEffect(() => {
        const fetchPost = async () => {
             try {
                setLoading(true);
                
                const response = await axios.get(`${apiUrl}/shoplocal/${encodeURIComponent(slug)}`);
                setBlog(response.data);
                const lat = parseFloat(response.data.detail.address1_latitude);
                const lng = parseFloat(response.data.detail.address1_longitude);
                setCoordinates({
                  lat: lat || center.lat,
                  lng: lng || center.lng,
                });
               
            } catch (error) {
                setError('Error fetching blog');
                setLoading(false);
                console.error('Error fetching blogs:', error);
            } finally {
            setLoading(false);
        }
        };
        fetchPost();
    }, [cslug,slug]);
    
    const handleSubmit = async (e) => {
         e.preventDefault();
         setProcessing(true);
          const data = new FormData();
        data.append('fullname', formData.fullname);
        data.append('phone', formData.phone);
        data.append('business_position', formData.business_position);
        data.append('message', formData.message);
        data.append('listing_id', formData.listing_id);
        data.append('listing_name', formData.listing_name);
         try {
            const response = await axios.post(`${apiUrl}/claim_listing`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`
                }
            });
            if(response.data.success){
                setSuccessMessage('Form submitted successfully!');
                setErrorMessage(null);
                setProcessing(false);
                
            }else{
                setErrorMessage(response.data.error);
                 setSuccessMessage(null);
                 setProcessing(false);
            }
        } catch (err) {
            setErrorMessage('Error submitting the form');
            setSuccessMessage(null);
            console.error('Error:', err);
             setProcessing(false);
        }finally {
            setProcessing(false);
        }
     };
     
     const onFavDeal = async (productId,type) => {
        const token = sessionStorage.getItem('authToken');
         if (!token) {
            setAlertMessage({
                visible: true,
                success: false,
                message: 'Please log in to add this deal to your favorites.',
                title: 'Login Required!'
            });
            return;
        }
        try {
           setProcessing(true);   
            const data = new FormData();
             data.append('id', productId);
             data.append('type', type);
          const response = await axios.post(`${apiUrl}/addfavorite`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`
                }
            });
            if(response.data.success){
                setAlertMessage({ visible: true, success: true, message: response.data.message,title:'Awesome!' });
            }else{
               setAlertMessage({ visible: true, success: false, message: response.data.error,title:'Alert!' });
            }
        } catch (error) {
            setProcessing(false);   
            setAlertMessage({ visible: true, success: false, message: error,title:'Alert!' });
          
        } finally {
            setProcessing(false);  
                
        }
  };
  
  const handleReviewSubmitted = (newReview) => {
     setNewReview(newReview);
   };
            
    if (loading) {
        return <p>Loading...</p>;
    }
 if (!isLoaded) {
    return <div>Loading...</div>;
  }
    if (!blog.detail) {
        return <p>Blog not found.</p>;
    }
    return (
    <div>
       <div className="main_content">
			<div className="main_title">
				<div className="container-fluid">
					<div className="inner_title">
						<h2>{blog.name}</h2>
					</div>
				</div>
			</div>
			<div className="container-fluid">
				<div className="breadcrumb">
					<ul>
						<li><a href="/">Home</a></li>
						<li><span><i className="fa fa-angle-right"></i></span></li>
						<li><a href="/shop-local">Shop Local</a></li>
						<li><span><i className="fa fa-angle-right"></i></span></li>
						<li><a href={`/${blog.detail.category.slug}`}>{blog.detail.category.name}</a></li>
						<li><span><i className="fa fa-angle-right"></i></span></li>
						<li><span>{blog.detail.name}</span></li>
					</ul>
				</div>
			</div>
			<div className="container-fluid">
				<div className="single_prod_detail">
					<div className="prod_content_left">
						<div className="single_prod_img">
							<img src={`${blog.urllink}/${blog.detail.image}`} alt={`${blog.detail.name}`} />
						</div>
						<div className="inner_prod_content">
							<div className="single_prod_cate_fav">
								<div className="single_category">
									<span>Business Listing Category: {blog.detail.category.name}</span>
								</div>
								<div className="single_favorite">	
									<img src="/img/prod_fav_grey.svg" alt="" onClick={(e) => { e.preventDefault(); onFavDeal(blog.detail.id, 'shop-local'); }} style={{ cursor: 'pointer' }} />
									<span>{processing ? 'Processing...' : 'Favorite'}</span>
								</div>
							</div> 
							<div className="single_prod_title">
								<h2>{blog.detail.name}</h2>
							</div>
							<div className="single_prod_content">
								<ul className="nav nav-tabs" id="prod_tabs" role="tablist">
									<li className="nav-item" role="presentation">
										<a className="nav-link active" id="about-tab" data-bs-toggle="tab" data-bs-target="#about-tab-pane" type="button" role="tab" aria-controls="about-tab-pane" aria-selected="true">About</a>
									</li>
									<li className="nav-item" role="presentation">
										<a className="nav-link" id="photo-tab" data-bs-toggle="tab" data-bs-target="#photo-tab-pane" type="button" role="tab" aria-controls="photo-tab-pane" aria-selected="false">Photo Gallery</a>
									</li>
									<li className="nav-item" role="presentation">
										<a className="nav-link" id="videos-tab" data-bs-toggle="tab" data-bs-target="#videos-tab-pane" type="button" role="tab" aria-controls="videos-tab-pane" aria-selected="false">Videos</a>
									</li>
									
									<li className="nav-item" role="presentation">
										<a className="nav-link" id="menu-tab" data-bs-toggle="tab" data-bs-target="#menu-tab-pane" type="button" role="tab" aria-controls="menu-tab-pane" aria-selected="false">Menu</a>
									</li>
									<li className="nav-item" role="presentation">
										<a className="nav-link" id="events-tab" data-bs-toggle="tab" data-bs-target="#events-tab-pane" type="button" role="tab" aria-controls="events-tab-pane" aria-selected="false">Events</a> 
									</li>
                                    <li className="nav-item" role="presentation">
										<a className="nav-link" id="reviews-tab" data-bs-toggle="tab" data-bs-target="#reviews-tab-pane" type="button" role="tab" aria-controls="reviews-tab-pane" aria-selected="false">Reviews</a>
									</li>
                                    
								</ul>
								<div className="tab-content" id="prod_tabsContent">
									<div className="tab-pane fade show active" id="about-tab-pane" role="tabpanel" aria-labelledby="about-tab" tabIndex="0">
										<div className="inner_single_content">
											<p dangerouslySetInnerHTML={{ __html: blog.detail.description }}></p>
										</div>
									</div>
									<div className="tab-pane fade" id="photo-tab-pane" role="tabpanel" aria-labelledby="photo-tab" tabIndex="0">
                                        <GalleryImages galleryImages={blog.detail.images} urlLink={blog.urllink} />
                                    </div>
									<div className="tab-pane fade" id="videos-tab-pane" role="tabpanel" aria-labelledby="videos-tab" tabIndex="0">
                                    
                                    </div>
									
									<div className="tab-pane fade" id="menu-tab-pane" role="tabpanel" aria-labelledby="menu-tab" tabIndex="0">
                                        <MenuFiles menuFiles={blog.detail.menu_file} urlLink={blog.urllink} />
                                    </div>
									<div className="tab-pane fade" id="events-tab-pane" role="tabpanel" aria-labelledby="events-tab" tabIndex="0">...</div>
                                    <div className="tab-pane fade" id="reviews-tab-pane" role="tabpanel" aria-labelledby="reviews-tab" tabIndex="0">
                                        <ReviewList postId={blog.detail.id} newReview={newReview} />
                                        <ReviewForm postId={blog.detail.id} onReviewSubmitted={handleReviewSubmitted} />
                                    </div>
								</div>
							</div>
						</div>
					</div>
					<div className="prod_content_right">
						<div className="business_owner_btn claim_btn cus_box_block">
							<a href="#" data-bs-toggle="modal" data-bs-target="#claimlisting">Business Owner? Claim This Listing</a>
						</div>
						<div className="deal_info cus_box_block">
							<h4>{blog.detail.daily_deal_title}</h4>
							<p dangerouslySetInnerHTML={{ __html: blog.detail.daily_deal_desc }}></p>
						</div>
						<div className="prod_maps cus_box_block">
							<h5>Category: <span>{blog.detail.category.name}</span></h5>
							<div className="google_map">
								<GoogleMap
                                                mapContainerStyle={containerStyle}
                                                center={coordinates}
                                                zoom={14}
                                              >
                                                <Marker position={coordinates}   />
                                               </GoogleMap>
							</div>
						</div>
						<div className="company_info cus_box_block">
							<p><span>Address:</span><br/>{blog.detail.address} <br/>{blog.detail.region} {blog.detail.city} {blog.detail.country} {blog.detail.zipcode}</p>
							<p><span>Phone:</span>{blog.detail.phone}</p>
							<p><span>Hours:</span></p>
							<div className="hours_list">
								<p dangerouslySetInnerHTML={{ __html: blog.detail.business_time }}></p>
							</div>
						</div>
					</div>
				</div>
				<div className="clearfix"></div>
			</div>
		</div>
        
        <div className="modal fade cus_modal calim_modal" id="claimlisting" tabIndex="-1" aria-labelledby="claimlistingLabel" aria-hidden="true">
		<div className="modal-dialog modal-dialog-centered">
			<div className="modal-content"> 
				<div className="modal-header">
					<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"><img src="/img/modal-close-icon.svg" alt="" /></button>
				</div>
				<div className="modal-body">
					<div className="claim_form cus_modal_form">
						<h3>Claim Listing</h3>
						<h5>What is the claim process?</h5>
						<form onSubmit={handleSubmit} >
							<div className="form_field">
								<div className="form-group">
									<input type="text" className="form-control" name="fullname" placeholder="Full Name" />
									<input type="hidden" className="form-control" name="listing_name" value={blog.detail.name} />
									<input type="hidden" className="form-control" name="listing_id" value={blog.detail.id} />
								</div>
								<div className="form-group">
									<input type="text" className="form-control" name="phone" placeholder="Contact Number" />
								</div>
								<div className="form-group">
									<input type="text" className="form-control" name="business_position" placeholder="Position in Business" />
								</div>
								<div className="form-group">
									<textarea className="form-control" name="message" placeholder="Hi I am the owner of this  business and I would like to claim it...."></textarea>
								</div>
								<div className="form-btn">
                                 {successMessage && (
                                            <div className="alert alert-success mainerror">{successMessage}</div> )}
                                  {errorMessage && (
                                            <div className="alert alert-danger mainerror" >{errorMessage}</div>
                                         )}       
                                         
									<input type="submit" disabled={processing} className="submit_btn" name="submit" value={processing ? 'Processing...' : 'Send'} />
								</div>
							</div>
						</form>
					</div>
				</div>
			</div>
		</div>
	</div> 
    {alertMessage.visible && (
            <AlertModal
              title={alertMessage.title}
              message={alertMessage.message}
              success={alertMessage.success}
              onClose={() => setAlertMessage({ visible: false })}
            />
          )}
	</div> 
    );
};

const AlertModal = ({ title,message, success, onClose }) => {
  return (
    <div className={`modal fade show cust_modal_overlay`} style={{ display: 'block' }} id="myModalalertmessage" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className={`modal-dialog modal-confirm ${success ? 'success' : 'danger'}`}>
        <div className="modal-content">
          <div className="modal-header">
            <div className="icon-box">
						<i className="fa fa-check"></i>
					</div>
            <h4 className="modal-title">{title}</h4>
           
          </div>
          <div className="modal-body">
            <p className="text-center viewmessage">{message}</p>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-success btn-block" onClick={onClose}>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

function ReviewForm({ postId, onReviewSubmitted }) {
    const [rating, setRating] = useState(0);
    const [hover, setHover] = useState(0);
    const [comment, setComment] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
     const { isAuthenticated} = useContext(AuthContext);
     const [successMessage, setSuccessMessage] = useState('');
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await axios.post(`${apiUrl}/listings/${postId}/shop-local/reviews`, {
                rating,
                comment,
            },
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`
                }
            }
            
            );
            onReviewSubmitted(response.data.review);
            setRating(0);
            setComment('');
            setLoading(false);
            setSuccessMessage('Review submitted successfully!');
             setError(null);
        } catch (error) {
            console.error('Error submitting review', error);
            setLoading(false);
            setError('Error submitting review');
            setSuccessMessage(null);
        }
    };
    if (!isAuthenticated) {
        return (
            <div className="reviewform">
                <p>Please <Link to="/login">log in</Link> to submit a review.</p>
            </div>
        );
    }
    return (
        <form onSubmit={handleSubmit}>
            <div className="row reviewform">
                
                {error && (
                    <div className="alert alert-danger mainerror" id="errorresponse">{error}</div>
                )}
                {successMessage && (
                    <div className="alert alert-success mainerror" id="successresponse">{successMessage}</div>
                 )}
                  <h5>Share You Review here</h5>
                <div className="col-md-12 text-center">
                        <div className="form-group">
                            {[...Array(5)].map((star, index) => {
                                const currentRating = index + 1;
                                return (
                                    <Star
                                        key={index}
                                        filled={currentRating <= (hover || rating)}
                                        onClick={() => setRating(currentRating)}
                                        onMouseEnter={() => setHover(currentRating)}
                                        onMouseLeave={() => setHover(rating)}
                                    />
                                );
                            })}
                        </div>
                </div>
                <div className="col-md-12">
                    <div className="form-group">
                        <div className="textarea_field cus_field">
                             <textarea
                                value={comment}
                                className="form-control"
                                placeholder="Share details of your own experience at this place"
                                onChange={(e) => setComment(e.target.value)}
                            />
                            
                        </div>
                    </div>
                    <div className="form-btn">
                         <button type="submit" className="btn btn-primary" disabled={loading}>{loading ? 'Processing...' : 'Submit Review'}</button>
                    </div>
                </div>
            </div>
        </form>
    );
}

function Star({ filled, onClick }) {
    return (
        <span onClick={onClick} style={{ cursor: 'pointer', color: filled ? '#ffd700' : '#e4e5e9', fontSize: '34px' }}>
            ★
        </span>
    );
}

function ReviewList({ postId, newReview  }) {
    const [reviews, setReviews] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        fetchReviews(currentPage);
    }, [currentPage]);
    
     useEffect(() => {
        if (newReview) {
            setReviews((prevReviews) => [newReview, ...prevReviews]); 
        }
    }, [newReview]);
    const fetchReviews = async (page) => {
        try {
            const response = await axios.get(`${apiUrl}/listings/${postId}/shop-local/reviews?page=${page}`);
            setReviews(response.data.data);
            setTotalPages(response.data.last_page);
        } catch (error) {
            console.error('Error fetching reviews', error);
        }
    };

    return (
        <div>
           
            <ul className="review-list">
                {reviews.map((review) => (
                    <li key={review.id} className="review-item">
                        <div className="review-user">{review.user.name}</div>
                        <div className="review-rating">
                            {Array(review.rating).fill().map((_, index) => (
                                <span key={index}>★</span>
                            ))}
                            {Array(5 - review.rating).fill().map((_, index) => (
                                <span key={index} style={{ color: '#e0e0e0' }}>★</span>
                            ))}
                        </div>
                        <div className="review-comment">{review.comment}</div>
                    </li>
                ))}
            </ul>
            <div className="review-pagination">
               <div className="pagination">
                <button
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                >
                    Previous
                </button>
                <button
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                >
                    Next
                </button>
            </div>
            </div>
        </div>
    );
}

export default ShopLocalDetailPage;