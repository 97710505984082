import React from 'react';
import { useContext,useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;
const Dashboard = () => {
     const navigate = useNavigate();
    // const [premiumPackage, setPremiumPackage] = useState({ products:[]});
     const { usertoken} = useContext(AuthContext);
     const [posts, setPosts] = useState({ products:[] });
   // const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
   //  const [showPackages, setShowPackages] = useState(false);
     useEffect(() => {
        const fetchPosts = async () => {
             try {
                
                const response = await axios.get(`${apiUrl}/free-package`, {
                    headers: {
                        'Authorization': `Bearer ${usertoken}`
                    }
                });
                setPosts(response.data);
               
            } catch (error) {
               // setError('Error fetching packages');
               
            } finally {
          
            }
            };
            if (usertoken) {
                    fetchPosts();
            }
           
        }, [usertoken]);
    
     const handleChoosePlan = async (event) => {
        const planType = event.currentTarget.getAttribute('data-type');
            setLoading(true);
        if (planType === 'premium') {
            navigate('/upgrade-package');
        } else {
           navigate('/dashboard');
        }
    };
    return (
        <div className="main_content">
         {loading ? (
                        <p>Loading...</p>
                    ) : (
                    <>
            <div className="main_title">
                    <div className="container-fluid">
                        <div className="inner_title">
                            <h2>Choose Plan</h2>
                        </div>
                    </div>
                </div>
                <div className="container">
                   <div className="choose_plan_sec">
                        <div className="choose_plan_title">
                            <img src="/img/plan-tick-icon.svg" alt="" />
                            <h3>You’ve been successfully registered!</h3>
                        </div>	
                        <div className="choose_plan_option">
                            <h5>Please choose your plan</h5>
                            <div className="choose_plan_block">
                                <div className="choose_plan_col free_plan_choose chooseplan" data-type="free" onClick={handleChoosePlan}>
                                    <div className="plan_title">
                                        <img src="/img/free-plan-icon.svg" alt="" /> <span>{posts.products.name}</span>
                                    </div>
                                    <div className="plan_list">
                                        <ul>
                                            <li><img src="/img/free-plan-list-icon.svg" alt="" /> <span>Up to 5 listings per month</span></li>
                                            <li><img src="/img/free-plan-list-icon.svg" alt="" /> <span>1GB data storage</span></li>
                                            <li><img src="/img/free-plan-list-icon.svg" alt="" /> <span>Max. 50Mb per file</span></li>
                                            <li><img src="/img/free-plan-list-icon.svg" alt="" /> <span>Limited access</span></li>
                                        </ul>
                                    </div>
                                </div>
                                <div className="choose_plan_col premium_plan_choose chooseplan" data-type="premium" onClick={handleChoosePlan}>
                                    <div className="premium_plan_title">
                                        <img src="/img/premium-plan-icon.svg" alt="" />
                                        <h4>Premium</h4>
                                    </div>
                                </div>
                             </div>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                  </div>
               
                </>
             )}
         </div>
       
         
    );
};

export default Dashboard;