// src/components/HomePage.js
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;
const BlogDetailPage = () => {
    const { slug } = useParams();
    const [blog, setBlog] = useState(null);
    const [loading, setLoading] = useState(true);
   // const [error, setError] = useState(null);
     useEffect(() => {
        const fetchPost = async () => {
             try {
                setLoading(true);
                const response = await axios.get(`${apiUrl}/blogdetail/${encodeURIComponent(slug)}`);
                setBlog(response.data);
            } catch (error) {
              //  setError('Error fetching blog');
                setLoading(false);
                console.error('Error fetching blogs:', error);
            } finally {
            setLoading(false);
        }
        };
        fetchPost();
    }, [slug]);
    

    if (loading) {
        return <p>Loading...</p>;
    }

    if (!blog) {
        return <p>Blog not found.</p>;
    }
    return (
       <div className="main_content">
            <div className="main_title">
                    <div className="container-fluid">
                        <div className="inner_title">
                            <h2>{blog.name}</h2>
                        </div>
                    </div>
                </div>
                <div className="product_shop">
                    <div className="container-fluid">
                        <div className="breadcrumb">
                            <ul>
                                <li><a href="/">Home</a></li>
                                <li><span><i className="fa fa-angle-right"></i></span></li>
                                <li><a href="/blogs">Blogs</a></li>
                                <li><span><i className="fa fa-angle-right"></i></span></li>
                                <li>{blog.name}</li>
                            </ul>
                        </div>
                        <div className="clearfix"></div>
                        <div className="single_blog">
                            <h2>{blog.name}</h2>
                            <div className="blog_image"> 
                                <img src={`${blog.image}`} alt="" />
                            </div> 
                            <div className="single_content" dangerouslySetInnerHTML={{ __html: blog.content }} />
                            
                        </div>
                        <div className="clearfix"></div>
                    </div>
                </div>
		</div>
    );
};

export default BlogDetailPage;