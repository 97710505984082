// src/components/Header.js
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import { format } from 'date-fns';
const Sidebar = () => {
    const { user} = useContext(AuthContext);
    const formattedDate = user.created_at ? format(new Date(user.created_at), 'dd/MM/yyyy') : 'Invalid date';
    return (
       <div className="sidebar_left">
            <div className="profile_block">
                <div className="profile_image">
                    <img src="/img/user-profile-img.png" alt="" />
                </div>
                <div className="profile_info">
                    {user && (
                        <div className="profile_title">
                            <h5>{user.name}<br/>Joined: {formattedDate}</h5>
                         </div>
                     )}
                     <div className="profile_link">
                        <ul>
                            <li><a href="/my-invoice-history">My Invoice History</a></li>
                            <li><a href="/my-purchases">My Purchases</a></li>
                            <li><a href="/edit-profile">Edit My Profile</a></li>
                            <li><a href="/billing/index">Billing</a></li>
                        </ul>
                    </div>
               </div>
             </div>
                <div className="sidebar_menu">
                    <h3>My Dashboard</h3>
                    <div className="menu_list">
                        <ul>
                            <li className="dropdown nav_item"><a className="dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">MANAGE MY FAVORITES</a>
                                <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" href="/dashboard">My Favorite Businesses</a></li>
                                    <li><a className="dropdown-item" href="/dashboard/local">My Favorite Local Temples</a></li>
                                    <li><a className="dropdown-item" href="/dashboard/claimed-deals">My Claimed Door-Buster Deals</a></li>
                                </ul>
                            </li>
                        
                            <li className="dropdown nav_item"><a className="dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">ADD A NEW LISITNG</a>
                                <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" href="/business/create">Add a New Business</a></li>
                                    <li><a className="dropdown-item" href="/business/list">My Listing</a></li>
                                </ul>
                            </li>
                            <li className="dropdown nav_item"><a className="dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">ADD EVENTS</a>
                                <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" href="/event/create">Add an Event</a></li>
                                    <li><a className="dropdown-item" href="/event/list">Event Lists</a></li>
                                </ul>
                            </li>
                            <li className="dropdown nav_item"><a className="dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">ADD BUSINESS VIDEO</a>
                                <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" href="/business/video/create">Add a new video</a></li>
                                </ul>
                            </li>
                            <li className="dropdown nav_item"><a className="dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">ADD PRODUCTS</a>
                                <ul className="dropdown-menu">
                                    <li><a className="dropdown-item" href="/product/list">My Listed Products</a></li>
                                    <li><a className="dropdown-item" href="/product/create">Add a New Product</a></li>
                                </ul>
                            </li>
                            <li className="nav_item"><a href="/social-media-links">SOCIAL MEDIA LINKS</a></li>
                        </ul>
                    </div>
                </div>
            </div>
    );
};

export default Sidebar;