// src/components/HomePage.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { format } from 'date-fns';
const apiUrl = process.env.REACT_APP_API_URL;

const ShopLocalPage = () => {
    const [posts, setPosts] = useState({ allcategories:[], alldeals: {
        data: []
    } });
    const [error, setError] = useState(null);
     const [viewType, setViewType] = useState('list');
    const [loading, setLoading] = useState(true);
     const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const toggleView = (type) => {
        setViewType(type);
    };
    useEffect(() => {
        const fetchPosts = async (page) => {
             try {
                setLoading(true);
                const response = await axios.get(`${apiUrl}/shoplocal?page=${page}`);
                setPosts(response.data);
                setCurrentPage(response.data.alldeals.current_page);
                setTotalPages(response.data.alldeals.last_page);
           } catch (error) {
                setError('Error fetching blogs');
                setLoading(false);
                console.error('Error fetching blogs:', error);
            } finally {
             setLoading(false);
            }
            };
            fetchPosts(currentPage);
        }, [currentPage]);
    
    const generateKey = (section, id) => `${section}-${id}`;
    const StarRating = ({ rating }) => {
        const totalStars = 5;
        const filledStars = Math.floor(rating);
        const halfStar = rating % 1 !== 0;
        const emptyStars = totalStars - filledStars - (halfStar ? 1 : 0);

        return (
            <div className="stars">
                {Array(filledStars).fill().map((_, index) => (
                    <img key={`filled-${index}`} src="/img/full-rating-star.png" alt="Star" />
                ))}
                {halfStar && <img src="/img/half-rating-star.png" alt="Half Star" />}
                {Array(emptyStars).fill().map((_, index) => (
                    <img key={`empty-${index}`} src="/img/empty-rating-star.png" alt="Empty Star" />
                ))}
            </div>
        );
    };
    return (
       <div className="main_content">
			<div className="main_title">
				<div className="container-fluid">
					<div className="inner_title">
						<h2>Shop Local</h2>
					</div>
				</div>
			</div>
			<div className="container-fluid">
				<div className="filter_deal_list">
					<div className="sidebar_filter">
						<div className="category_list">
							<div className="filter_title">
								<h4>I’m looking for:</h4>
							</div>
							<div className="sidebar_list">
								<ul>
									<li ><span>All Categories</span></li>
									{posts.allcategories.length > 0 ? posts.allcategories.map((cate,index) => (
									<li key={generateKey('cats', index)} ><a href={`/shop-local/${cate.slug}`}>{cate.name}</a><span className="cate_count">{cate.shoplocaldeals_count}</span></li>
									)) : !loading && <p>No posts available.</p>}
								</ul>
							</div>
							<div className="filter_title">
								<h4>Locations :</h4>
							</div>
							<div className="sidebar_list">
								<ul>
									<li><span>All Locations</span></li>
									<li><a href="#">San Antonio</a><span className="cate_count">0</span></li>
									<li><a href="#">Fremont</a><span className="cate_count">0</span></li>
									<li><a href="#">Fort Lauderdale</a><span className="cate_count">0</span></li>
									<li><a href="#">Marina del Rey</a><span className="cate_count">0</span></li>
									<li><a href="#">Los Angeles</a><span className="cate_count">0</span></li>
									<li><a href="#">Teaneck</a><span className="cate_count">1</span></li>
								</ul>
								<div className="show_all">
									<a href="#">- Show All Locations -</a>
								</div>
							</div>
						</div>
						<div className="deal_cards">
							<div className="cards_box blue_card">
								<div className="card_img">
									<img src="/img/deal-card-img1.jpg" alt=""/>
								</div>
								<div className="card_content">
									<h3>7P-Star Card Users</h3>
									<p>Press HERE to get started!</p>
									<div className="card_btn">
										<a href="#">Get Started</a>
									</div>
								</div>
							</div>
							<div className="cards_box orange_card">
								<div className="card_img">
									<img src="/img/deal-card-img2.jpg" alt=""/>
								</div>
								<div className="card_content">
									<h3>Priests</h3>
									<p>Grow your temple and re-energize your congregation</p>
									<div className="card_btn">
										<a href="#">Get Started</a>
									</div>
								</div>
							</div>
							<div className="cards_box green_card">
								<div className="card_img">
									<img src="/img/deal-card-img3.jpg" alt=""/>
								</div>
								<div className="card_content">
									<h3>Businesses</h3>
									<p>Connect your business with upto 20,000+ local chuchgoers</p>
									<div className="card_btn">
										<a href="#">Get Started</a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="filter_content_list">
						<div className="breadcrumb">
							<ul>
								<li><a href="/">Home</a></li>
								<li><span><i className="fa fa-angle-right"></i></span></li>
								<li><span>Shop Local</span></li>
							</ul>
						</div>
						<div className="filter_tabs">
							<div className="filter_left filter_col">
								<ul>
									<li><a href="#">View</a></li>
									<li><a href="#" onClick={(e) => {
                                                            e.preventDefault();
                                                            toggleView('list');
                                                        }}><img src="/img/fltr_grid-icon.svg" alt=""/></a></li>
									<li><a href="#" onClick={(e) => {
                                                            e.preventDefault();
                                                            toggleView('grid');
                                                        }}><img src="/img/fltr_bars-icon.svg" alt=""/></a></li>
								</ul>
							</div> 
							<div className="filter_right filter_col">
								<ul>
									<li><a href="#"><img src="/img/fltr_clock-icon.svg" alt=""/> <span>Time Left</span></a></li>
									<li><a href="#"><img src="/img/fltr_star-icon.svg" alt=""/> <span>Ratings</span></a></li>
									<li><a href="#"><img src="/img/fltr_calendar-icon.svg" alt=""/> <span>Date</span></a></li>
								</ul>
							</div>
						</div>
                        {viewType === 'grid' ? (
                            
                            <div className="shop_list custom_prod_list cus_listview">
                                {posts.alldeals.data.length > 0 ? posts.alldeals.data.map(deal => (
							<div key={generateKey('deals', deal.id)} className="shop_col prod_col">
								<div className="shop_img prod_img">
									<img src={`${posts.urllink}/${deal.images}`} alt="" />
									<div className="shop_btn prod_btn">
										<div className="view_btn">
											<a href={`/shop-local/${deal.category.slug}/${deal.slug}`}>View</a>
										</div>
									</div>
								</div>
								<div className="shop_content prod_content">
									<div className="prod_rating">
										<div className="stars">
                                            <StarRating rating={deal.averageRating || 0} /> 
											
										</div>
										<span>(0 Rate)</span>
									</div>
									<h4>{deal.name}</h4>
									<div className="shop_loc prod_loc">
										<p><i className="fa fa-map-marker-alt"></i> <span>{deal.address}</span></p>
									</div>
								</div>
							</div>
							 )) : !loading && <p>No posts available.</p>}
                             </div>
                        ) : (
                      
                            <div className="shop_list custom_prod_list cus_gridview">
                                 {posts.alldeals.data.length > 0 ? posts.alldeals.data.map(deal => (
							<div key={generateKey('deals', deal.id)} className="shop_col prod_col">
								<div className="shop_img prod_img">
									<img src={`${posts.urllink}/${deal.images}`} alt="" />
									<div className="shop_btn prod_btn">
										<div className="view_btn">
											<a href={`/shop-local/${deal.category.slug}/${deal.slug}`}>View</a>
										</div>
									</div>
								</div>
								<div className="shop_content prod_content">
									<div className="prod_rating">
										<div className="stars">
											<StarRating rating={deal.averageRating || 0} /> 
										</div>
										<span>(0 Rate)</span>
									</div>
									<h4>{deal.name}</h4>
									<div className="shop_loc prod_loc">
										<p><i className="fa fa-map-marker-alt"></i> <span>{deal.address}</span></p>
									</div>
								</div>
							</div>
							 )) : !loading && <p>No posts available.</p>}
                             </div>
                        )}
						
						 <div className="clearfix"></div>
                              <div className="cus_navigation">
                                    <Pagination
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    onPageChange={page => setCurrentPage(page)}
                                />
                                
                            </div>	
					</div>
				</div>
				<div className="clearfix"></div>
			</div>
		</div>
    );
};
const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const handlePrevPage = () => {
        if (currentPage > 1) {
            onPageChange(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            onPageChange(currentPage + 1);
        }
    };

    const handlePageClick = (page) => {
        onPageChange(page);
    };

    return (
        <ul >
            <li className={`first ${currentPage === 1 ? 'disabled' : ''}`}>
                <a onClick={handlePrevPage}><i className="fa fa-angle-left"></i></a>
            </li>
            {[...Array(totalPages).keys()].map(i => (
                <li
                    key={i + 1}
                    className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}
                >
                    <a onClick={() => handlePageClick(i + 1)}>
                       <span> {i + 1} </span>
                    </a>
                </li>
            ))}
            <li className={`last ${currentPage === totalPages ? 'disabled' : ''}`}>
                <a onClick={handleNextPage}><i className="fa fa-angle-right"></i></a>
            </li>
        </ul>
    );
};
export default ShopLocalPage;