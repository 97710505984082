import React from 'react';
const PackageCancelPage = () => {
   return (
        <div className="main_content">
            <div className="main_title">
                <div className="container-fluid">
                    <div className="inner_title">
                        <h2>Payment Canceled</h2>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="breadcrumb">
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><span><i className="fa fa-angle-right"></i></span></li>
                        <li><span>Payment Canceled</span></li>
                    </ul>
                </div>
            </div>
            <div className="container">
                <div className="premium_plan_sec">
                    <div className="premium_plan_title">
                        <img src="/img/premium-plan-icon.svg" alt="" />
                        <h3>Payment Canceled</h3>
                    </div>	
                    <div className="premium_plan_option">
                        <h5>Payment Canceled</h5>
                         
                    </div>
                    
                 </div>
                <div className="clearfix"></div>
            </div>
        </div>
    );
};


export default PackageCancelPage;