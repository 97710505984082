// src/components/Footer.js
import React from 'react';

const Footer = () => {
    return (
        <footer className="footer">
			<div className="container-fluid">
				<div className="inner_footer">
					<div className="row footer_row">
						<div className="col-md-9">
							<div className="footer_link">
								<ul>
									<li><a href="/">Home</a></li>
									<li><a href="/door-buster-deals">Door-Buster Deals !</a></li>
									<li><a href="/shop-local">Shop Local</a></li>
									<li><a href="/local-temples">Local Temples</a></li>
									<li><a href="/shop">Shop</a></li>
									<li><a href="/careers">Careers</a></li>
									<li><a href="/personal-training">Personal Training</a></li>
									<li><a href="/businesses">Businesses</a></li>
									<li><a href="/blogs">Blog</a></li>
									<li><a href="/privacy-policy">Privacy Policy</a></li>
									<li><a href="/claim-listing">Claim Listing</a></li>
									<li><a href="/term-services">Terms of Services (Users)</a></li>
									<li><a href="term-services-merchants">Terms of Services (Merchants)</a></li>
								</ul>
							</div>
						</div>
						<div className="col-md-3">
							<div className="social_link">
								<ul>
									<li><a href="#"><i className="fab fa-facebook-f"></i></a></li>
									<li><a href="#"><i className="fab fa-instagram"></i></a></li>
									<li><a href="#"><i className="fab fa-twitter"></i></a></li>
									<li><a href="#"><i className="fab fa-linkedin-in"></i></a></li>
								</ul>
							</div>
							<div className="copyright_p">
								<p>© 7pstar.com All rights reserved.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</footer>
    );
};

export default Footer;