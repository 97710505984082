// src/App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AuthProvider } from './context/AuthContext';
import { StripeProvider } from './context/StripeProvider';
import PrivateRoute from './components/PrivateRoute';
import HomePage from './components/HomePage';
import LoginPage from './components/Auth/LoginPage';
import PasswordReset from './components/Auth/PasswordReset';
import RegisterForm from './components/Auth/RegisterPage';
import Dashboard from './components/Dashboard';
import ChoosePlan from './components/ChoosePlan';
import Header from './components/layout/Header';
import Footer from './components/layout/Footer';
import ShopPage from './components/ShopPage';
import BlogsPage from './components/BlogsPage';
import BlogDetailPage from './components/BlogDetailPage';
import LocalTemplesPage from './components/LocalTemplesPage';
import LocalTempleDetailPage from './components/LocalTempleDetailPage';
import DoorBusterDealsPage from './components/DoorBusterDealsPage';
import DoorBusterDealDetailPage from './components/DoorBusterDealDetailPage';
import ShopLocalPage from './components/ShopLocalPage';
import ShopLocalDetailPage from './components/ShopLocalDetailPage';
import InvoiceHistoryPage from './components/billing/InvoiceHistoryPage';
import MyPurchasePage from './components/billing/MyPurchasePage';
import BillingPage from './components/billing/BillingPage';
import CardPage from './components/billing/CardPage';
import EditProfilePage from './components/EditProfilePage';
import SocialPage from './components/SocialPage';
import ProductListPage from './components/product/ProductListPage';
import ProductCreatePage from './components/product/ProductCreatePage';
import ProductEditPage from './components/product/ProductEditPage';
import ProductPreviewPage from './components/product/ProductPreviewPage';


import EventListPage from './components/event/EventListPage';
import EventCreatePage from './components/event/EventCreatePage';
import EventEditPage from './components/event/EventEditPage';

import BusinessListPage from './components/business/BusinessListPage';
import BusinessCreatePage from './components/business/BusinessCreatePage';
import BusinessEditPage from './components/business/BusinessEditPage';
import BusinessPreviewPage from './components/business/BusinessPreviewPage';

import PackageSuccessPage from './components/PackageSuccessPage';
import PackageCancelPage from './components/PackageCancelPage';

import UpgradePackage from './components/UpgradePackage';
import withStripeProvider from './components/withStripeProvider';
const UpgradePackageWithStripe = withStripeProvider(UpgradePackage);

function App() {
    return (
        <Router>
            <AuthProvider>
                <div className="main_wrapper">
                        <Header />
                            <Routes>
                                <Route path="/" element={<HomePage />} />
                                <Route path="/login" element={<LoginPage />} />
                                <Route path="/forgot-password" element={<PasswordReset />} />
                                <Route path="/signup" element={<RegisterForm />} />
                                
                                 <Route path="/shop" element={<ShopPage />} />
                                 <Route path="/blogs" element={<BlogsPage />} />
                                 <Route path="/blog/:slug" element={<BlogDetailPage />} />
                                 <Route path="/local-temples" element={<LocalTemplesPage />} />
                                 <Route path="/local-temples/:slug" element={<LocalTempleDetailPage />} />
                                 <Route path="/door-buster-deals" element={<DoorBusterDealsPage />} />
                                 <Route path="/door-buster-deals/:cslug/:slug" element={<DoorBusterDealDetailPage />} />
                                 <Route path="/shop-local" element={<ShopLocalPage />} />
                                 <Route path="/shop-local/:cslug/:slug" element={<ShopLocalDetailPage />} />
                                 <Route path="/my-invoice-history" element={<PrivateRoute element={InvoiceHistoryPage} />} />
                                 <Route path="/my-purchases" element={<PrivateRoute element={MyPurchasePage} />} />
                                 <Route path="/billing/index" element={<PrivateRoute element={BillingPage} />} />
                                 <Route path="/update-card" element={<PrivateRoute element={CardPage} />} />
                                 <Route path="/edit-profile" element={<PrivateRoute element={EditProfilePage} />} />
                                 <Route path="/social-media-links" element={<PrivateRoute element={SocialPage} />} />
                                 <Route path="/product/list" element={<PrivateRoute element={ProductListPage} />} />
                                 <Route path="/product/create" element={<PrivateRoute element={ProductCreatePage} />} />
                                 <Route path="/product/edit/:productid" element={<PrivateRoute element={ProductEditPage} />} />
                                 
                                 <Route path="/event/list" element={<PrivateRoute element={EventListPage} />} />
                                 <Route path="/event/create" element={<PrivateRoute element={EventCreatePage} />} />
                                 <Route path="/event/edit/:productid" element={<PrivateRoute element={EventEditPage} />} />
                                 
                                 
                                 <Route path="/business/list" element={<PrivateRoute element={BusinessListPage} />} />
                                 <Route path="/business/create" element={<PrivateRoute element={BusinessCreatePage} />} />
                                 <Route path="/business/edit/:productid" element={<PrivateRoute element={BusinessEditPage} />} />
                                 <Route path="/business/preview/:productid" element={<PrivateRoute element={BusinessPreviewPage} />} />
                                 
                                 
                                  <Route path="/dashboard" element={<PrivateRoute element={Dashboard} />} />
                                  <Route path="/choose-plan" element={<PrivateRoute element={ChoosePlan} />} />
                                  <Route path="/dashboard/:slug" element={<PrivateRoute element={Dashboard} />} />
                                  <Route path="/upgrade-package" element={<PrivateRoute element={UpgradePackageWithStripe} />} />
                                  
                                  
                                  <Route path="/package/success" element={<PrivateRoute element={PackageSuccessPage} />} />
                                  <Route path="/package/cancel" element={<PrivateRoute element={PackageCancelPage} />} />
                                  
                            </Routes>
                        <Footer />
                    </div>
             </AuthProvider>
         </Router>
    );
}

export default App;