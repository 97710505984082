import React from 'react';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const handlePrevPage = () => {
        if (currentPage > 1) {
            onPageChange(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            onPageChange(currentPage + 1);
        }
    };

    const handlePageClick = (page) => {
        onPageChange(page);
    };

    return (
        <ul >
            <li className={`first ${currentPage === 1 ? 'disabled' : ''}`}>
                <a onClick={handlePrevPage}><i className="fa fa-angle-left"></i></a>
            </li>
            {[...Array(totalPages).keys()].map(i => (
                <li
                    key={i + 1}
                    className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}
                >
                    <a onClick={() => handlePageClick(i + 1)}>
                        {i + 1}
                    </a>
                </li>
            ))}
            <li className={`last ${currentPage === totalPages ? 'disabled' : ''}`}>
                <a onClick={handleNextPage}><i className="fa fa-angle-right"></i></a>
            </li>
        </ul>
    );
};

export default Pagination;