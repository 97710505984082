import React from 'react';
import { useState, useEffect, useContext, useCallback } from 'react';
import { AuthContext } from '../../context/AuthContext';
import Sidebar from '../layout/Sidebar';
import axios from 'axios';
import { format } from 'date-fns';
const apiUrl = process.env.REACT_APP_API_URL;
const BillingPage = () => {
     const { usertoken} = useContext(AuthContext);
     const [posts, setPosts] = useState();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const fetchPosts = useCallback(async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${apiUrl}/billing/index`, {
                headers: {
                    'Authorization': `Bearer ${usertoken}`
                }
            });
            setPosts(response.data);
            
        } catch (error) {
            setError('Error fetching invoices');
            console.error('Error fetching invoices:', error);
        } finally {
            setLoading(false);
        }
    }, [usertoken]);

    useEffect(() => {
        if (usertoken) {
            fetchPosts();
        }
    }, [fetchPosts, usertoken]);
     
    
    const generateKey = (section, id) => `${section}-${id}`;
    
    
    return (
        <div className="main_content">
            <div className="main_title">
                <div className="container-fluid">
                    <div className="inner_title">
                        <h2>Dashboard</h2>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="breadcrumb">
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><span><i className="fa fa-angle-right"></i></span></li>
                        <li><span>Dashboard</span></li>
                    </ul>
                </div>
            </div>
            <div className="dashboard_page">
                <div className="container-fluid">
                    <div className="inner_dashboard">
                        <Sidebar />
                            <div className="content_right">
                                <div className="product_listing_sec"> 
                                    {loading ? (
                                            <p>Loading...</p>
                                        ) : (
                                            <>
                                    <div className="cus_title  d-flex justify-content-between">
                                        <h4>Support Plan</h4>
                                        <div className=""><a style={{ fontFamily: 'Montserrat',fontWeight: '500',color: '#fff'}} href="/upgrade-package" className="btn btn-warning btn-sm">Upgrade Plan</a></div>
                                    </div>
                                    <div className="product_listing"> 
                                        <div className="d-flex justify-content-between w-100">
                                            <div className="d-flex flex-column"><span className="font-weight-bold">Current Plan</span> <span className="text-muted">{posts.package.name}</span> <span className="text-muted">{posts.package.description}</span></div>
                                    
                                            <div className="d-flex flex-column"><span className="font-weight-bold">Plan Cost</span> <span className="text-muted">{posts.package.amount} CAD</span></div>
                                           {posts.hasSubscribed ? (
                                            <>
                                                <div className="d-flex flex-column"><span className="font-weight-bold">Plan Period</span> <span className="text-muted">{format(new Date(posts.nextBilling.start_date), 'MMMM dd, yyyy')}</span> <span  className="text-muted">{format(new Date(posts.nextBilling.end_date), 'MMMM dd, yyyy')} </span></div>
                                                </>
                                            ) : (
                                            <>
                                                <div className="d-flex flex-column"><span className="font-weight-bold">Plan Period</span> <span className="text-muted">-</span> <span  className="text-muted"> -</span></div>
                                                 </>
                                            )}
                                    </div>
                                </div>
                              </>
                            )}
                        </div>
                                <div className="product_listing_sec"> 
                                     {loading ? (
                                                   <></>
                                                ) : (
                                                    <>
                                    <div className="cus_title d-flex justify-content-between" style={{ marginTop: '20px'}}>
                                        <h4>Payment Method</h4>
                                        <div className=""><a style={{ fontFamily: 'Montserrat', fontWeight:'500',color:'#fff'}} href="/update-card" className="btn btn-warning btn-sm">Edit Payment</a></div>
                                    </div>
                                    <div className="product_listing"> 
                                        <div className="d-flex flex-column">
                                            <div className="row ml-1 w-100 bg-light rounded p-4 mt-2 border">
                                                <div className="d-flex w-100 justify-content-between">
                                                    <div className="d-flex flex-column">
                                                        <span className="font-weight-bold">Existing card number</span>
                                                        <span className="text-muted">
                                                        {posts.hasSubscribed ? (
                                                            <>
                                                            **** **** **** {posts.cardInfo.last4}
                                                             </>
                                                        ) : (
                                                            <>
                                                            -
                                                            </>
                                                        )}
                                                        </span>
                                                    </div> 
                                                    <div className="d-flex flex-column">
                                                        <span className="font-weight-bold">Expiring date</span>
                                                        <span className="text-muted">
                                                        {posts.hasSubscribed ? (
                                                        <>
                                                            {posts.cardInfo.exp_month} / {posts.cardInfo.exp_year}
                                                            </>
                                                        ) : (
                                                            <>
                                                            -
                                                            </>
                                                        )}
                                                        </span>
                                                    </div> 
                                                    <div className="d-flex flex-column">
                                                        <span className="font-weight-bold">Brand</span>
                                                        <span className="text-muted">
                                                            {posts.hasSubscribed ? (
                                                            <>
                                                                {posts.cardInfo.brand}
                                                                 </>
                                                            ) : (
                                                                <>
                                                                -
                                                                </>
                                                            )}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                     </>
                        )}
					</div>
                        </div>
                        </div>
                        <div className="clearfix"></div>
                    </div>
                    
                </div>
            </div>

    );
};
export default BillingPage;