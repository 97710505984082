import React from 'react';
import { useState, useEffect, useContext, useCallback } from 'react';
import { AuthContext } from '../../context/AuthContext';
import Sidebar from '../layout/Sidebar';
import axios from 'axios';
import { format } from 'date-fns';
const apiUrl = process.env.REACT_APP_API_URL;
const InvoiceHistoryPage = () => {
     const { usertoken} = useContext(AuthContext);
     const [posts, setPosts] = useState({ favdata:{data:[]} });
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
     const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    
    const fetchPosts = useCallback(async (page) => {
        try {
            setLoading(true);
            const response = await axios.get(`${apiUrl}/billing/invoices?page=${page}`, {
                headers: {
                    'Authorization': `Bearer ${usertoken}`
                }
            });
            setPosts(response.data);
            setCurrentPage(response.data.current_page);
            setTotalPages(response.data.last_page);
        } catch (error) {
            setError('Error fetching invoices');
            console.error('Error fetching invoices:', error);
        } finally {
            setLoading(false);
        }
    }, [usertoken]);

    useEffect(() => {
        if (usertoken) {
            fetchPosts(currentPage);
        }
    }, [currentPage, fetchPosts, usertoken]);
     
     const handlePageChange = (page) => {
        setCurrentPage(page);
    };
     const InvoiceComponent = ({ invoice }) => {
    const firstInvoice = invoice.invoices[0];

    return (
        <div>
            {firstInvoice.description ? (
                <p>
                    <br />
                    {firstInvoice.description}
                </p>
            ) : null}
        </div>
    );
};  
    const generateKey = (section, id) => `${section}-${id}`;
    
    
    return (
        <div className="main_content">
            <div className="main_title">
                <div className="container-fluid">
                    <div className="inner_title">
                        <h2>Dashboard</h2>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="breadcrumb">
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><span><i className="fa fa-angle-right"></i></span></li>
                        <li><span>Dashboard</span></li>
                    </ul>
                </div>
            </div>
            <div className="dashboard_page">
                <div className="container-fluid">
                    <div className="inner_dashboard">
                        <Sidebar />
                        <div className="content_right">
                            <div className="table_data invoice_data"> 
								<div className="cus_title">
									<h4>My Invoices</h4>
								</div>
								<div className="table_list_data">
									<div className="table-responsive">
                                    {loading ? (
                                        <p>Loading...</p>
                                    ) : (
                                        <>
										<table id="invoice_item" className="table">
											<thead>
												<tr>
													<th>#</th>
													<th>Item</th>
													<th>Amount</th>
													<th>Status</th>
													<th>Date</th>
												</tr>
											</thead>
                                            <tbody>
                                            {posts.data.length > 0 ? posts.data.map((invoice,index) => (
                                                <tr key={generateKey('inv', invoice.id)}>
													<td>{invoice.id}</td>
													<td>{invoice.invoices[0].name} <InvoiceComponent invoice={invoice} /><br/><a href="#">View Invoice</a></td>
													<td className="price">${invoice.total}</td>
													<td>{invoice.status === 'paid' ? 'Paid' : 'Unpaid'}</td>
													<td>{format(new Date(invoice.generated_date), 'MMMM dd, yyyy')}</td>
												</tr>
										     )) : ''}
											</tbody>
										</table>
                                        <div className="cus_navigation">
                                            <Pagination 
                                                currentPage={currentPage} 
                                                totalPages={totalPages} 
                                                onPageChange={handlePageChange} 
                                            />
                                        </div>
                                    </>
                                )}
								</div>
								</div>
							</div>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                </div>
            </div>
        </div>
    );
};
const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const handlePrevPage = () => {
        if (currentPage > 1) {
            onPageChange(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            onPageChange(currentPage + 1);
        }
    };

    const handlePageClick = (page) => {
        onPageChange(page);
    };

    return (
        <ul >
            <li className={`first ${currentPage === 1 ? 'disabled' : ''}`}>
                <a onClick={handlePrevPage}><i className="fa fa-angle-left"></i></a>
            </li>
            {[...Array(totalPages).keys()].map(i => (
                <li
                    key={i + 1}
                    className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}
                >
                    <a onClick={() => handlePageClick(i + 1)}>
                       <span> {i + 1} </span>
                    </a>
                </li>
            ))}
            <li className={`last ${currentPage === totalPages ? 'disabled' : ''}`}>
                <a onClick={handleNextPage}><i className="fa fa-angle-right"></i></a>
            </li>
        </ul>
    );
};
export default InvoiceHistoryPage;