import React from 'react';
import { useContext,useState,useEffect } from 'react';
import { AuthContext } from '../../context/AuthContext';
import Sidebar from '../layout/Sidebar';
import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;
const EventListPage = () => {
     const { usertoken} = useContext(AuthContext);
     const [posts, setPosts] = useState([]);
     const [urllink, setImageLink] = useState();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [successMessage, setSuccessMessage] = useState('');
    const [deleting, setDeleting] = useState(null);
    const [updating, setUpdating] = useState(null);
    useEffect(() => {
        const message = sessionStorage.getItem('successMessage');
        if (message) {
            setSuccessMessage(message);
            sessionStorage.removeItem('successMessage');
        }
    }, []);
     useEffect(() => {
        const fetchPosts = async () => {
             try {
                 setLoading(true);
                
                const response = await axios.get(`${apiUrl}/event/list`, {
                    headers: {
                        'Authorization': `Bearer ${usertoken}`
                    }
                });
                setPosts(response.data.events || []);
                setImageLink(response.data.urllink || '');
           } catch (error) {
                setError('Error fetching products');
                setLoading(false);
                console.error('Error fetching products:', error);
            } finally {
             setLoading(false);
            }
            };
            
             if (usertoken) {
                    fetchPosts();
                }
           
        }, [usertoken]);
    
    const handleDelete = async (productId) => {
            if (window.confirm('Are you sure you want to delete this event?')) {
                try {
                     setDeleting(productId);
                    await axios.delete(`${apiUrl}/event/delete/${productId}`, {
                        headers: {
                            'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`, 
                        },
                    });
                    setPosts(posts.filter(product => product.id !== productId));
                } catch (err) {
                    setError('Error deleting event');
                    
                }finally {
                    setDeleting(null);
                }
            }
        };
        
        const handleStatusUpdate = async (productId, newStatus) => {
            
                try {
                     setUpdating(productId);
                    const response = await axios.put(`${apiUrl}/event/updatestatus/${productId}`, { status: newStatus }, {
                        headers: {
                            'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`, // Assuming you have a token
                        },
                    });
                
                    setPosts(posts.map(product => 
                        product.id === productId ? { ...product, status: newStatus } : product
                    ));
                } catch (err) {
                    setError('Error deleting event');
                }finally {
                    setUpdating(null);
                }
            
        };
    const generateKey = (section, id) => `${section}-${id}`;
    
    return (
      <div className="main_content">
	<div className="main_title">
		<div className="container-fluid">
			<div className="inner_title">
				<h2>Dashboard</h2>
			</div>
		</div>
	</div>
	<div className="container-fluid">
		<div className="breadcrumb">
			<ul>
				<li><a href="/">Home</a></li>
				<li><span><i className="fa fa-angle-right"></i></span></li>
				<li><span>Dashboard</span></li>
			</ul>
		</div>
	</div>
	<div className="dashboard_page">
		<div className="container-fluid">
			<div className="inner_dashboard">
				<Sidebar />
				<div className="content_right">
							<div className="product_listing_sec"> 
                                {error && (
                                            <div className="alert alert-danger">{error}</div>
                                        )}
                                {successMessage && (
                                    <div className="alert alert-success">
                                        {successMessage}
                                    </div>
                                )}
								<div className="cus_title">
									<h4>My Event Listings</h4>
								</div>
                                  {loading ? (
                                        <p>Loading...</p>
                                    ) : (
                                        <div className="product_listing"> 
                                        {posts.length > 0 ? posts.map(product => (	
                                        
                                            <div key={generateKey('product', product.id)}  className="product_column">
                                                <div className="product_img">
                                                    <img style={{width:"200px",height:"200px"}} src={`${urllink}/${product.image}`} alt={`${product.name}`} />
                                                </div>
                                                <div className="product_detail">
                                                    <div className="product_content">
                                                        <h4>{product.name}</h4>
                                                        <ProductDescription description={product.description} />
                                                        <p><b>Start Date: </b>{product.start_date}</p>
                                                        <p><b>End Date: </b>{product.end_date}</p>
                                                        <p><b>Payment URL: </b>{product.payment_url}</p>
                                                    </div>
                                                    <div className="product_action">
                                                        <ul>
                                                            <li><img src="/img/listing-edit-icon.svg" alt="" /><a href={`/event/edit/${product.uid}`}>Edit</a></li>
                                                            <li><img src="/img/listing-trash-icon.svg" alt="" /><a href="#" onClick={(e) => {e.preventDefault(); handleDelete(product.id)}} disabled={deleting === product.id}>{deleting === product.id ? 'Deleting...' : 'Delete'}</a></li>
                                                            <li><img src="/img/listing-status-icon.svg" alt="" />
                                                             {updating === product.id ? (
                                                                    <span> Updating...</span>
                                                                ) : (
                                                                <a
                                                                    href="#"
                                                                    onClick={(e) => {
                                                                        e.preventDefault();
                                                                        handleStatusUpdate(product.id, !product.status);
                                                                    }}
                                                                    disabled={updating === product.id}
                                                                >
                                                                    {product.status ? 'Published' : 'Unpublished'}
                                                                </a>
                                                                )}
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                            )) : ''}
                                        </div>
                                  )}
							</div>
						</div>
			</div>
			<div className="clearfix"></div>
		</div>
	</div>
</div>
    );
};
const ProductDescription = ({ description }) => {
    const [isExpanded, setIsExpanded] = useState(false);
    
    const toggleDescription = () => {
        setIsExpanded(!isExpanded);
    };

    const limitedString = description.length > 300 ? description.substring(0, 300) + '...' : description;

    return (
        <div>
            {!isExpanded ? (
                <p className="shortdiscription">
                    {limitedString} 
                    {description.length > 300 && <a href="javascript:;" onClick={toggleDescription}>More...</a>}
                </p>
            ) : (
                <p className="longdiscription">
                    {description} 
                    <a href="javascript:;" onClick={toggleDescription}>Less...</a>
                </p>
            )}
        </div>
    );
};
export default EventListPage;