import React from 'react';
import { useContext,useState,useEffect,useRef } from 'react';
import { useJsApiLoader, GoogleMap, Marker } from '@react-google-maps/api';
import { AuthContext } from '../../context/AuthContext';
import Sidebar from '../layout/Sidebar';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
const apiUrl = process.env.REACT_APP_API_URL;
const googleMapApi = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
const containerStyle = {
  width: '100%',
  height: '292px'
};

const center = {
  lat: -3.745,
  lng: -38.523
};
const BusinessPreviewPage = () => {
    const [coordinates, setCoordinates] = useState(center);
     const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: googleMapApi,
    libraries: ['places'],
  });
    const navigate = useNavigate();
    const { productid} = useParams();
     const [blog, setBlog] = useState(null);
     const [imagelink, setimagelink] = useState('');
    const { usertoken} = useContext(AuthContext);
    const [error, setError] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [loading, setLoading] = useState(true);
     const [updating, setUpdating] = useState(null);
     
   const handleStatusUpdate = async (Id) => {
            
                try {
                      setUpdating(Id);
                    const response = await axios.post(`${apiUrl}/business/businesspublish`, { id: Id } , {
                        headers: {
                            'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`, // Assuming you have a token
                        },
                    });
                    if(response.data.success){
                        sessionStorage.setItem('successMessage', 'Listing Published Successfully');
                        navigate('/business/list');
                    }else{
                        setUpdating(null);
                        setError(response.data.message);
                    } 
                   
                } catch (err) {
                    setError(err);
                     setUpdating(null);
                }finally {
                    setUpdating(null);
                }
            
        };
    useEffect(() => {
        const fetchDealRecord = async () => {
            try {
              
                setLoading(true);
                const response = await axios.get(`${apiUrl}/business/preview/${encodeURIComponent(productid)}`, {
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`
                    }
                });
                const lat = parseFloat(response.data.detail.address1_latitude);
                const lng = parseFloat(response.data.detail.address1_longitude);
                setCoordinates({
                  lat: lat || center.lat,
                  lng: lng || center.lng,
                });
                setBlog(response.data.detail);
                setimagelink(response.data.urllink);
            } catch (error) {
                setError('Error fetching user record');
                setLoading(false);
                console.error('Error fetching user record:', error);
            }finally {
                setLoading(false);
            }
        };

        fetchDealRecord();
    }, []);
    if (loading) {
        return <p>Loading...</p>;
    }
     if (!isLoaded) {
    return <div>Loading...</div>;
  }
    return (
        <div className="main_content">
            <div className="main_title">
                <div className="container-fluid">
                    <div className="inner_title">
                        <h2>Listing Preview</h2>
                    </div>
                </div>
            </div>
            <div className="listing_preview">
                <div className="container-fluid">
                   {error && (
                                <div className="alert alert-danger">{error}</div>
                            )}
                    {successMessage && (
                        <div className="alert alert-success">
                            {successMessage}
                        </div>
                    )}
                    <div className="listing_title">
                        <h2>This is a preview of how your listing will look once you complete the process.</h2>
                    </div>
                    <div className="listing_btn">
                        
                        <a
                            className="cus_btn"
                            href="#"
                            onClick={(e) => {
                                e.preventDefault();
                                handleStatusUpdate(productid);
                            }}
                            disabled={updating === productid}
                        >
                       {updating ? 'Updating...' : 'Publish'}
                        </a>
                        
                        
                    </div>
                    <div className="listing_back_btn">
                        <a href={`/business/edit/${blog.uid}`} className="back_btn">« GO BACK & EDIT LISITING</a>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="breadcrumb">
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><span><i className="fa fa-angle-right"></i></span></li>
                        <li><span>{blog.name}</span></li>
                    </ul>
                </div>
            </div>
            <div className="container-fluid">
                <div className="single_prod_detail">
                    <div className="prod_content_left">
                        <div className="single_prod_img">
                            <img src={`${imagelink}/${blog.image}`} alt="" />
                        </div>
                        <div className="inner_prod_content">
                            {blog.type === 'door-buster-deal' ?(
                                <>
                                    <div className="single_prod_loc_rate">
                                        <div className="single_location">
                                            <img src="/img/map-marker.svg" alt="" />
                                            
                                            <span>{blog.address}</span>
                                        </div>
                                        <div className="single_rating">	
                                            <div className="stars">	
                                                <img src="/img/single-rating-star.svg" alt="" />
                                                <img src="/img/single-rating-star.svg" alt="" />
                                                <img src="/img/single-rating-star.svg" alt="" />
                                                <img src="/img/single-rating-star.svg" alt="" />
                                                <img src="/img/single-rating-star.svg" alt="" />
                                            </div>
                                            <span>(0 Rate)</span>
                                        </div>
                                    </div>
                                </>
                             ) : (
                                <>
                                <div className="single_prod_cate_fav">
                                    <div className="single_category">
                                        <span>Business Listing Category: {blog.category.name}</span>
                                    </div>
                                    <div className="si/ngle_favorite">	
                                        <img src="img/prod_fav_grey.svg" alt="" />
                                        <span>Favorite</span>
                                    </div>
                                </div> 
                                </>
                              )}
                            
                            <div className="single_prod_title">
                                <h2>{blog.name}</h2>
                            </div>
                            <div className="single_prod_content">
                                <ul className="nav nav-tabs" id="prod_tabs" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link active" id="about-tab" data-bs-toggle="tab" data-bs-target="#about-tab-pane" type="button" role="tab" aria-controls="about-tab-pane" aria-selected="true">About</a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link" id="photo-tab" data-bs-toggle="tab" data-bs-target="#photo-tab-pane" type="button" role="tab" aria-controls="photo-tab-pane" aria-selected="false">Photo Gallery</a>
                                    </li>
                                    {blog.type === 'shop-local' &&(
                                    <li className="nav-item" role="presentation">
										<a className="nav-link" id="videos-tab" data-bs-toggle="tab" data-bs-target="#videos-tab-pane" type="button" role="tab" aria-controls="videos-tab-pane" aria-selected="false">Videos</a>
									</li>
                                    )}
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link" id="map-tab" data-bs-toggle="tab" data-bs-target="#map-tab-pane" type="button" role="tab" aria-controls="map-tab-pane" aria-selected="false">Menu</a>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <a className="nav-link" id="reviews-tab" data-bs-toggle="tab" data-bs-target="#reviews-tab-pane" type="button" role="tab" aria-controls="reviews-tab-pane" aria-selected="false">Reviews</a>
                                    </li>
                                    {blog.type === 'shop-local' &&(
                                        <li className="nav-item" role="presentation">
                                            <a className="nav-link" id="events-tab" data-bs-toggle="tab" data-bs-target="#events-tab-pane" type="button" role="tab" aria-controls="events-tab-pane" aria-selected="false">Events</a> 
                                        </li>
                                     )}
                                </ul>
                                <div className="tab-content" id="prod_tabsContent">
                                    <div className="tab-pane fade show active" id="about-tab-pane" role="tabpanel" aria-labelledby="about-tab" tabIndex="0">
                                        <div className="inner_single_content">
                                            <p dangerouslySetInnerHTML={{ __html: blog.description }}></p>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="photo-tab-pane" role="tabpanel" aria-labelledby="photo-tab" tabIndex="0">
                                        <GalleryImages galleryImages={blog.images} urlLink={imagelink} />
                                    </div>
                                     {blog.type === 'shop-local' &&(
                                        <div className="tab-pane fade" id="videos-tab-pane" role="tabpanel" aria-labelledby="videos-tab" tabIndex="0"></div>
                                      )}
                                    <div className="tab-pane fade" id="map-tab-pane" role="tabpanel" aria-labelledby="map-tab" tabIndex="0">
                                        <MenuFiles menuFiles={blog.menu_file} urlLink={imagelink} />
                                    </div>
                                    <div className="tab-pane fade" id="reviews-tab-pane" role="tabpanel" aria-labelledby="reviews-tab" tabIndex="0">...</div>
                                     {blog.type === 'shop-local' &&(
                                        <div className="tab-pane fade" id="events-tab-pane" role="tabpanel" aria-labelledby="events-tab" tabIndex="0">...</div>
                                      )}
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="prod_content_right">
                    {blog.type === 'door-buster-deal' ?(
                            <DealComponent deal={blog} urllink={imagelink} />
                     ) : (
                        <>
                            <div className="business_owner_btn claim_btn cus_box_block">
                                <a href="#" data-bs-toggle="modal" data-bs-target="#claimlisting">Business Owner? Claim This Listing</a>
                            </div>
                            <div className="deal_info cus_box_block">
                                <h4>{blog.daily_deal_title}</h4>
                                <p dangerouslySetInnerHTML={{ __html: blog.daily_deal_desc }}></p>
                            </div>
                        </>
                    )}
                        <div className="prod_maps cus_box_block">
                            <h5>Category: <span>{blog.category.name}</span></h5>
                            <div className="google_map">
                                <GoogleMap
                                                mapContainerStyle={containerStyle}
                                                center={coordinates}
                                                zoom={14}
                                              >
                                                <Marker position={coordinates}   />
                                               </GoogleMap>
                            </div>
                        </div>
                        <div className="company_info cus_box_block">
                            <p><span>Address:</span><br/>{blog.address} <br/>{blog.region} {blog.city} {blog.country} {blog.zipcode}</p>
                            <p><span>Phone:</span>{blog.phone}</p>
                            <p><span>Hours:</span></p>
                            <div className="hours_list">
                                <p dangerouslySetInnerHTML={{ __html: blog.business_time }}></p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="clearfix"></div>
            </div>
        </div>
    );
};

const MenuFiles = ({ menuFiles, urlLink }) => {
    if (!menuFiles || menuFiles.length === 0) {
        return null;
    }

    return (
        <div>
            {menuFiles.map((file, index) => (
                <iframe
                    key={index}
                    src={`${urlLink}/${file.image}`}
                    width="800"
                    height="600"
                    title={`Menu File ${index}`}
                ></iframe>
            ))}
        </div>
    );
};
const generateKey = (section, id) => `${section}-${id}`;
const GalleryImages = ({ galleryImages, urlLink }) => {
    if (!galleryImages || galleryImages.length === 0) {
        return null;
    }

    return (
        <div>
            {galleryImages.map((pimg, index) => (
                <a key={generateKey('imgs', index)}  href={`${urlLink}/${pimg.image}`} data-lightbox="gallery-images">
                   <img src={`${urlLink}/${pimg.image}`} alt="" style={{ maxWidth: '100px' }} />
              </a>
            ))}
        </div>
    );
};

const DealComponent = ({ deal, urllink }) => {
    const price = deal.price;
    const discount = deal.discount;
    const calculatedDiscount = (price * discount) / 100;
    const total = price - calculatedDiscount;
    const savePrice = price - total;

    return (
        <div>
            <div className="claim_deal_btn claim_btn cus_box_block">
                <div className="prod_price">
                    <span>${Math.round(total)}</span>
                </div>
                <a href="#" >Claim This Deal</a>
            </div>
            <div className="deal_time_left">
                <div className="time_left_top time_left_col">
                    <div className="deal_icon">
                        <img src="/img/time-left-icon.svg" alt="" />
                    </div>
                    <Countdown startDate={deal.start_date} endDate={deal.expire_date} />
                </div>
                <div className="time_left_btm time_left_col">
                    <div className="deal_icon">
                        <img src="/img/users-icon.svg" alt="" />
                    </div>
                    <div className="box_content">
                        <h3>{deal.claimdetail_count} Bought!</h3>
                    </div>
                </div>
                <div className="deal_price_save">
                    <div className="price_col">
                        <div className="price">Value <span>${price}</span></div>
                        <div className="price">Discount <span>{discount}%</span></div>
                        <div className="price">You Save <span>${savePrice}</span></div>
                    </div>
                </div>
                <div className="social_share_icon">
                    <ul>
                        <li><a href="#"><img src="/img/fb-share-icon.svg" alt="" /></a></li>
                        <li><a href="#"><img src="/img/tw-share-icon.svg" alt="" /></a></li>
                        <li><a href="#"><img src="/img/gplus-share-icon.svg" alt="" /></a></li>
                        <li><a href="#"><img src="/img/email-share-icon.svg" alt="" /></a></li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

const Countdown = ({ startDate, endDate }) => {
    const [timeLeft, setTimeLeft] = useState('');

    useEffect(() => {
        const calculateTimeLeft = () => {
            const currentDate = new Date();
            const start = new Date(startDate);
            const end = new Date(endDate);
            
            if (currentDate < start) {
                setTimeLeft('not started yet');
                return;
            } 
            if (currentDate > end) {
                setTimeLeft('ended.');
                return;
            }

            const timeDifference = end - currentDate;
            const daysLeft = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
            const hoursLeft = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutesLeft = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
            const secondsLeft = Math.floor((timeDifference % (1000 * 60)) / 1000);

            setTimeLeft(`${daysLeft} Days ${hoursLeft}:${minutesLeft}:${secondsLeft}`);
        };

        const timer = setInterval(calculateTimeLeft, 1000);

        // Initial call to display the time immediately
        calculateTimeLeft();

        // Cleanup interval on component unmount
        return () => clearInterval(timer);
    }, [startDate, endDate]);

    return (
        <div className="box_content">
            <span>Time Left - Limited Offer!</span> 
            <h3 className="timeleft">{timeLeft}</h3>
        </div>
    );
};
export default BusinessPreviewPage;