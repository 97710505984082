import React from 'react';
import { useContext,useState,useEffect } from 'react';
import { AuthContext } from '../../context/AuthContext';
import Sidebar from '../layout/Sidebar';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ImageUpload from '../ImageUpload';
import { format } from 'date-fns';
const apiUrl = process.env.REACT_APP_API_URL;
const EventEditPage = () => {
    const { productid } = useParams();
    const [formData, setFormData] = useState({
         start_date: null,
        expire_date: null,
        event_title: '',
        event_description: '',
        payment_url: ''
    });
        const { usertoken} = useContext(AuthContext);
        const navigate = useNavigate();
        const [imagelink, setImageLink] = useState();
        const [error, setError] = useState(null);
        const [loading, setLoading] = useState(false);
        const [successMessage, setSuccessMessage] = useState('');
        const [images, setImages] = useState([]);
        const [startDate, setStartDate] = useState(new Date());
    const [existingImages, setExistingImages] = useState([]);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleDateChange = (date, name) => {
        setFormData({
            ...formData,
            [name]: date
        });
    };
    
    useEffect(() => {
        // Fetch user data when the component mounts
        const fetchProductData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/event/detail/${productid}`, {
                    headers: {
                        'Authorization': `Bearer ${usertoken}`
                    }
                });
                const productData = response.data.detail;
               setImageLink(response.data.urllink);
                setFormData({
                   start_date: productData.start_date ? new Date(productData.start_date) : null,
                    expire_date: productData.end_date ? new Date(productData.end_date) : null,
                    event_title: productData.name,
                    event_description: productData.description,
                    payment_url: productData.payment_url
                });
                setExistingImages(productData.images);
            } catch (error) {
                setError('Error fetching user data');
                console.error('Error fetching user data:', error);
            }
        };

        fetchProductData();
    }, [usertoken]);
    
     const handleImageDrop = (acceptedFiles) => {
        setImages(acceptedFiles);
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
         setLoading(true);   
        const data = new FormData();
        const formattedStartDate = formData.start_date ? format(formData.start_date, 'yyyy-MM-dd HH:mm') : '';
        const formattedExpireDate = formData.expire_date ? format(formData.expire_date, 'yyyy-MM-dd HH:mm') : '';
        data.append('event_title', formData.event_title);
        data.append('id', productid);
        data.append('expire_date', formattedExpireDate);
        data.append('start_date', formattedStartDate);
        data.append('event_description', formData.event_description);
        data.append('payment_url', formData.payment_url);
        existingImages.forEach(file => data.append('existing_images[]', file));
        images.forEach(file => data.append('images[]', file.file));

        try {
            const response = await axios.post(`${apiUrl}/event/update`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`
                }
            });
            if(response.data.success){
                setSuccessMessage('Form submitted successfully!');
                setError(null);
                setLoading(false);
                 sessionStorage.setItem('successMessage', 'Form submitted successfully!');
                 navigate('/event/list');
            }else{
                setError(response.data.error);
                 setSuccessMessage(null);
                 setLoading(false);
            }
        } catch (err) {
            setError('Error submitting the form');
            setSuccessMessage(null);
            console.error('Error:', err);
             setLoading(false);
        }finally {
            setLoading(false);
        }
    };
    return (
        <div className="main_content">
            <div className="main_title">
                <div className="container-fluid">
                    <div className="inner_title">
                        <h2>Dashboard</h2>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="breadcrumb">
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><span><i className="fa fa-angle-right"></i></span></li>
                        <li><span>Dashboard</span></li>
                    </ul>
                </div>
            </div>
            <div className="dashboard_page">
                <div className="container-fluid">
                    <div className="inner_dashboard">
                        <Sidebar />
                        <div className="content_right">
                            <div className="listing_form_area">
                                <form className="listing_form" onSubmit={handleSubmit}>
                                    <div className="listing_field">
                                        <div className="listing_title">
                                            <h3>Add Event</h3>
                                            <span>*  Denotes Required fields</span>
                                        </div>
                                        <div className="listing_form_field">
                                            {error && (
                                                <div className="alert alert-danger mainerror" id="errorresponse">{error}</div>
                                            )}
                                            {successMessage && (
                                                <div className="alert alert-success mainerror" id="successresponse">{successMessage}</div>
                                             )}
                                            <h5>ENTER EVENT DETAILS</h5>
                                            <div className="form-group">
                                                <label className="cus_label">Start Date</label>
                                                <div className="input_field cus_field">
                                                <DatePicker
                                                      selected={formData.start_date}
                                                    onChange={(date) => handleDateChange(date, 'start_date')}
                                                    showTimeSelect
                                                    dateFormat="Pp"
                                                    name="start_date"
                                                    className="form-control"
                                                    timeFormat="HH:mm"
                                                    timeIntervals={15}
                                                    required
                                                />
                                                     
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="cus_label">Expire Date</label>
                                                <div className="input_field cus_field">
                                                    <DatePicker
                                                        selected={formData.expire_date}
                                                        onChange={(date) => handleDateChange(date, 'expire_date')}
                                                        showTimeSelect
                                                        dateFormat="Pp"
                                                        name="expire_date"
                                                        className="form-control"
                                                        timeFormat="HH:mm"
                                                        timeIntervals={15}
                                                        required
                                                />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="cus_label">Event Title <span className="req_field">*</span></label>
                                                <div className="input_field cus_field">
                                                    <input required id="event_title" type="text" className="form-control" placeholder="" name="event_title" value={formData.event_title} onChange={handleInputChange} />
                                                   
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="cus_label">Event Description <span className="req_field">*</span></label>
                                                <div className="textarea_field cus_field">
                                                    <textarea required id="event_description" className="form-control" name="event_description" value={formData.event_description} onChange={handleInputChange}></textarea>
                                               </div>
                                            </div>
                                            <div className="form-group">
                                                <label className="cus_label">Payment URL <span className="req_field">*</span></label>
                                                <div className="input_field cus_field">
                                                    <input required id="payment_url" type="text" className="form-control" placeholder="" name="payment_url" value={formData.payment_url} onChange={handleInputChange} />
                                                    <span className="note">Use the convenient payment method</span>
                                                </div>
                                            </div>
                                            <ImageUpload imagelink={imagelink} initialImages={existingImages} onDrop={handleImageDrop} />
                                            <div className="form-group">
                                                <div className="form-check terms_condition">
                                                    <input required className="form-check-input" type="checkbox" value="" id="accept_terms_cond" />
                                                    <label className="form-check-label" htmlFor="accept_terms_cond">Please accept our terms and conditions</label>
                                                </div>
                                                <span className="text-danger error" id="error-accept_terms_cond"></span> 
                                            </div>  
                                            {error && (
                                                <div className="alert alert-danger mainerror" id="errorresponse">{error}</div>
                                            )}
                                            {successMessage && (
                                                <div className="alert alert-success mainerror" id="successresponse">{successMessage}</div>
                                             )}
                                            <div className="form-btn review_list_btn">
                                                <button type="submit" disabled={loading} className="reviewbtn" id="submitBtn">{loading ? 'Processing...' : 'Publish'}</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                </div>
            </div>
        </div>
    );
};

export default EventEditPage;