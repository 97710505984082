// src/components/HomePage.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Pagination from './Pagination';
const apiUrl = process.env.REACT_APP_API_URL;
const BlogsPage = () => {
    const [posts, setPosts] = useState({
    Blogs: {
        data: []
    }
    });
   // const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
  
        const fetchPosts = async (page) => {
             try {
                 setLoading(true);
                const response = await axios.get(`${apiUrl}/blogs?page=${page}`);
                
                setPosts(response.data);
                setCurrentPage(response.data.Blogs.current_page);
                setTotalPages(response.data.Blogs.last_page);
           } catch (error) {
              //  setError('Error fetching blogs');
                setLoading(false);
                console.error('Error fetching blogs:', error);
            } finally {
            setLoading(false);
        }
        };

    
    useEffect(() => {
        fetchPosts(currentPage);
    }, [currentPage]);
    const generateKey = (section, id) => `${section}-${id}`;
    
    return (
       <div className="main_content">
			<div className="main_title">
				<div className="container-fluid">
					<div className="inner_title">
						<h2>Blogs</h2>
					</div>
				</div>
			</div>
			<div className="product_shop">
				<div className="container-fluid">
					<div className="breadcrumb">
						<ul>
							<li><a href="#">Home</a></li>
							<li><span><i className="fa fa-angle-right"></i></span></li>
							<li>Blogs</li>
						</ul>
					</div>
					<div className="clearfix"></div>
					<div className="blog_search">
						<div className="select_field cus_search_field">
							<select className="form-control">
								<option>Categories</option> 
								<option value="Blog">Blog</option>
								<option value="Education">Education</option>
								<option value="Sports">Sports</option>
							</select>
							<img src="/img/blog-dropdown-arrow.svg" alt="" />
						</div>
						<div className="input_field cus_search_field">
							<input type="text" className="form-control" placeholder="Search" />
							<button type="button" className="search_btn"><img src="/img/search-icon.svg" alt="" /></button>
						</div>
						<div className="blog_navigation">
							<Pagination
                                currentPage={currentPage}
                                totalPages={totalPages}
                                onPageChange={page => setCurrentPage(page)}
                            />
						</div>
					</div>
                    {loading ? (
                        <p>Loading...</p>
                    ) : (
					<div className="blog_list">
                        {posts.Blogs.data.length > 0 ? posts.Blogs.data.map(blog => (
                            <div key={generateKey('blogs', blog.id)} className="blog_column">
                                <div className="blog_image">
                                    <img src={`${blog.image}`} alt={`${blog.name}`} />
                                </div>
                                <div className="blog_detail">
                                    <h4>{blog.name}</h4>
                                    <div className="blog_btn">
                                        <a href={`/blog/${blog.slug}`} className="read_btn">Read</a>
                                    </div>
                                </div>
                            </div>
						)) : ''}
                        
                       
					</div>
                    )}
					<div className="clearfix"></div>
				</div>
			</div>
		</div>
    );
};

export default BlogsPage;