import React from 'react';
import { useContext,useState,useEffect } from 'react';
import Sidebar from './layout/Sidebar';
import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;
const SocialPage = () => {
    const [userData, setUserData] = useState(null);
    const [error, setError] = useState(null);
    
    const [successMessage, setSuccessMessage] = useState('');
      // Fetch the user profile on component mount
    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const response = await axios.get(`${apiUrl}/user`, {
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`
                    }
                });
                setUserData(response.data);
            } catch (error) {
                setError('Error fetching user profile');
                console.error('Error fetching user profile:', error);
            }
        };

        fetchUserProfile();
    }, []);
    
    return (
        <div className="main_content">
            <div className="main_title">
                <div className="container-fluid">
                    <div className="inner_title">
                        <h2>Dashboard</h2>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="breadcrumb">
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><span><i className="fa fa-angle-right"></i></span></li>
                        <li><span>Dashboard</span></li>
                    </ul>
                </div>
            </div>
            <div className="dashboard_page">
                <div className="container-fluid">
                    <div className="inner_dashboard">
                        <Sidebar />
                        <div className="content_right">
                            {userData && <SocialProfiles userData={userData} />}
                        </div>
                    </div>
                    <div className="clearfix"></div>
                </div>
            </div>
        </div>
    );
};

const SocialProfiles = ({ userData }) => {
     const [socialProfiles, setSocialProfiles] = useState(userData.social_profiles || {});
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);
      const handleChange = (e) => {
        const { name, value } = e.target;
        const key = name.replace('social_profiles[', '').replace(']', '');
        setSocialProfiles((prevProfiles) => ({
            ...prevProfiles,
            [key]: value,
        }));
    };

    // Handle form submission
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await axios.post(`${apiUrl}/update-social-media`, { social_profiles: socialProfiles }, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`
                }
            });
            setSuccessMessage('Social Media Links updated successfully');
            setError(null);
        } catch (error) {
            setError('Error updating Social Media Links.');
            console.error('Error:', error);
            setLoading(false);
        }finally {
            setLoading(false);
        }
    };
   
    return (
        <div className="listing_form_area">
            <form className="listing_form" onSubmit={handleSubmit}>
							<div className="listing_field social_media_field"> 
								<div className="listing_title">
									<h3>Social Media Links</h3>
								</div>
								<div className="listing_form_field">
									{error && (
                                            <div className="form_row">
                                                <div className="form_column column_full">
                                                    <div className="form-group">
                                                        <span className="alert alert-danger">{error}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {successMessage && (
                                            <div className="form_row">
                                                <div className="form_column column_full">
                                                    <div className="form-group">
                                                        <span className="alert alert-success">{successMessage}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
									<div className="listing_border"></div>
									{['facebook_url', 'instagram_url', 'linkedin_url', 'twitter_url', 'tiktok_url'].map((key) => (
                                          <div className="form-group" key={key}>
                                              <label className="cus_label">{key.replace('_url', '').replace(/^\w/, (c) => c.toUpperCase())}</label>
                                            <div className="input_field cus_field">
                                                <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder=""
                                                    name={`social_profiles[${key}]`}
                                                    value={socialProfiles[key] || ''}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                        </div>
                                    ))}
									<div className="form-btn review_list_btn">
										<button type="submit" disabled={loading} className="reviewbtn">{loading ? 'Processing...' : 'Save'}</button>
									</div>
								</div>
							</div>
						</form>
        </div>
    );
};
export default SocialPage;