import React, { useState } from 'react';
import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;
const EnquiryModal = ({ showModal, handleClose, productId, productQuantity }) => {
    const [loading, setLoading] = useState(false);
    const [formData, setFormData] = useState({
        firstname: '',
        lastname: '',
        phone: '',
        individual_purchase: '',
        email: '',
        message: '',
    });

    const [errors, setErrors] = useState({});
    const [responseMessage, setResponseMessage] = useState('');

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const validateForm = () => {
        let formErrors = {};
        if (!formData.firstname) formErrors.firstname = 'First name is required';
        if (!formData.lastname) formErrors.lastname = 'Last name is required';
        if (!formData.phone) formErrors.phone = 'Phone number is required';
        if (!formData.email) formErrors.email = 'Email is required';
        if (!formData.email.includes('@')) formErrors.email = 'Invalid email address';
        if (!formData.message) formErrors.message = 'Message is required';

        setErrors(formErrors);
        return Object.keys(formErrors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (validateForm()) {
            setLoading(true);
            try {
                const response = await axios.post(`${apiUrl}/submit-enquiry`, {
                    ...formData,
                    productid:productId,
                    qty:productQuantity
                });
                 setLoading(false);
                setResponseMessage('Enquiry submitted successfully!');
                handleClose(); // Close the modal on success
            } catch (error) {
                 setLoading(false);
                setResponseMessage('An error occurred. Please try again.');
                console.error('Error submitting enquiry:', error);
                
            }finally {
                setLoading(false);
            }
        }
    };
    return (
       <div className={`modal fade cus_modal enquiry_modal ${showModal ? 'show' : ''}`} tabIndex="-1" style={{ display: showModal ? 'block' : 'none' }} aria-labelledby="enquiryModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content"> 
                    <div className="modal-header">
                        <button type="button" className="btn-close" onClick={handleClose} aria-label="Close"><img src="/img/modal-close-icon.svg" alt="" /></button>
                    </div>
                    <div className="modal-body">
                        <div className="enquiry_form cus_modal_form">
                            <h3>Enquiry</h3>
                            <form onSubmit={handleSubmit}>
                                <div className="form_field">
                                    <div className="form_row">
                                        <div className="form_col col_half">
                                            <div className="form-group">
                                                <input type="text" id="firstname" className="form-control" name="firstname" placeholder="First Name" value={formData.firstname} onChange={handleChange} />
                                              
                                                 {errors.firstname && <div className="text-danger">{errors.firstname}</div>}
                                                 </div>
                                        </div>
                                        <div className="form_col col_half">
                                            <div className="form-group">
                                                <input type="text" id="lastname" className="form-control" name="lastname" placeholder="Last Name" value={formData.lastname} onChange={handleChange}  />
                                                {errors.lastname && <div className="text-danger">{errors.lastname}</div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form_row">
                                        <div className="form_col col_half">
                                            <div className="form-group">
                                                <input type="text" id="phone" className="form-control" name="phone" placeholder="Phone Number" value={formData.phone} onChange={handleChange} />
                                                {errors.phone && <div className="text-danger">{errors.phone}</div>}
                                            </div>
                                        </div>
                                        <div className="form_col col_half">
                                            <div className="form-group">
                                                <input type="text" id="individual_purchase" className="form-control" name="individual_purchase" placeholder="Individual Purchase" value={formData.individual_purchase} onChange={handleChange} />
                                                
                                                {errors.phone && <div className="text-danger">{errors.individual_purchase}</div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form_row">
                                        <div className="form_col col_full">
                                            <div className="form-group">
                                                <input type="email" id="email" className="form-control" name="email" placeholder="Email Address" value={formData.email} onChange={handleChange} />
                                                {errors.email && <div className="text-danger">{errors.email}</div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form_row">
                                        <div className="form_col col_full">
                                            <div className="form-group">
                                                <textarea className="form-control" id="message" name="message" placeholder="Hi, I’m interested in buying 2kgs of lentils from your shop. How do I place an order for this? Thanks." value={formData.message} onChange={handleChange}></textarea>
                                                {errors.message && <div className="text-danger">{errors.message}</div>}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="form-btn">
                                        <input type="submit" disabled={loading} className="submit_btn" name="submit" value={loading ? 'Loading...' : 'Submit'} />
                                        {responseMessage && <div className="text-success">{responseMessage}</div>}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EnquiryModal;