import React from 'react';
import { useContext,useState,useEffect,useRef, useCallback } from 'react';
import { useJsApiLoader, GoogleMap, Autocomplete, Marker } from '@react-google-maps/api';
import { AuthContext } from '../../context/AuthContext';
import Sidebar from '../layout/Sidebar';
import axios from 'axios';
import $ from 'jquery';
import 'select2/dist/css/select2.min.css';
import 'select2';
import { useNavigate,useParams } from 'react-router-dom';
import { useDropzone } from 'react-dropzone';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ImageUpload from '../ImageUpload';
import { format } from 'date-fns';
const apiUrl = process.env.REACT_APP_API_URL;
const googleMapApi = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
const containerStyle = {
  width: '100%',
  height: '292px'
};

const center = {
  lat: -3.745,
  lng: -38.523
};
const BusinessEditPage = () => {
     const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: googleMapApi,
    libraries: ['places'],
  });
  const [autocomplete, setAutocomplete] = useState(null);
        const [coordinates, setCoordinates] = useState(center);
    const { productid } = useParams();
    const [formData, setFormData] = useState({
         start_date: null,
        expire_date: null,
        business_listing_title: '',
        business_listing_desc: '',
        tagkeywords: '',
        category: '',
        address: '',
        country: '',
        region: '',
        city: '',
        zipcode: '',
        business_time: '',
        phone: '',
        email: '',
        price: '',
        discount: '',
        website: '',
        daily_deal_title: '',
        daily_deal_desc: '',
        address1_latitude: '',
        address1_longitude: '',
         pdfFiles: []
    });
    const navigate = useNavigate();
    const [imagelink, setImageLink] = useState();
    const [images, setImages] = useState([]);
    const [existingImages, setExistingImages] = useState([]);
    const [existingPDF, setExistingPDF] = useState([]);
    const { usertoken, user} = useContext(AuthContext);
    const [posts, setCategories] = useState([]);
   
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    
    const [selectedValue, setSelectedValue] = useState('');
    const handleFilesAdded = (files) => {
        setFormData({
            ...formData,
            pdfFiles: files
        });
    };
     const handleChange = (value) => {
        setSelectedValue(value);
        setFormData({
            ...formData,
            category: value // Update category in form data
        });
    };
    
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleDateChange = (date, name) => {
        setFormData({
            ...formData,
            [name]: date
        });
    };
    
     const handleImageDrop = (acceptedFiles) => {
        setImages(acceptedFiles);
    };
    
    
    useEffect(() => {
        // Fetch user data when the component mounts
        const fetchProductData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/business/preview/${productid}`, {
                    headers: {
                        'Authorization': `Bearer ${usertoken}`
                    }
                });
                const productData = response.data.detail;
               setImageLink(response.data.urllink);
               
               const lat = parseFloat(productData.address1_latitude);
                const lng = parseFloat(productData.address1_longitude);

                setFormData({
                    start_date: productData.start_date ? new Date(productData.start_date) : null,
                    expire_date: productData.expire_date ? new Date(productData.expire_date) : null,
                    business_listing_title: productData.name,
                    business_listing_desc: productData.description,
                    tagkeywords: productData.tagkeywords,
                    address: productData.address,
                    country: productData.country,
                    region: productData.region,
                    city: productData.city,
                    zipcode: productData.zipcode,
                    business_time: productData.business_time,
                    phone: productData.phone,
                    email: productData.email,
                    price: productData.price,
                    discount: productData.discount,
                    website: productData.website,
                    daily_deal_title: productData.daily_deal_title,
                    daily_deal_desc: productData.daily_deal_desc,
                    type: productData.type,
                    pdfFiles: productData.menu_file,
                    address1_latitude: lat,
                    address1_longitude: lng,
                });
                setCoordinates({
                  lat: lat || center.lat,
                  lng: lng || center.lng,
                });
                setExistingImages(productData.images);
                setExistingPDF(productData.menu_file);
                setSelectedValue(productData.category_id);
                setCategories(response.data.allcategories || []);
            } catch (error) {
                setError('Error fetching user data');
                console.error('Error fetching user data:', error);
            }
        };

        fetchProductData();
    }, [usertoken]);
    
   const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);   
        const data = new FormData();
        const formattedStartDate = formData.start_date ? format(formData.start_date, 'yyyy-MM-dd HH:mm') : '';
        const formattedExpireDate = formData.expire_date ? format(formData.expire_date, 'yyyy-MM-dd HH:mm') : '';
        data.append('business_listing_title', formData.business_listing_title);
        data.append('id', productid);
        data.append('expire_date', formattedExpireDate);
        data.append('start_date', formattedStartDate);
        data.append('business_listing_desc', formData.business_listing_desc);
        data.append('tagkeywords', formData.tagkeywords);
        data.append('category_id', formData.category);
        data.append('address', formData.address);
        data.append('country', formData.country);
        data.append('region', formData.region);
        data.append('city', formData.city);
        data.append('zipcode', formData.zipcode);
        data.append('business_time', formData.business_time);
        data.append('phone', formData.phone);
        data.append('email', formData.email);
        data.append('website', formData.website);
        data.append('address1_latitude', formData.address1_latitude);
        data.append('address1_longitude', formData.address1_longitude);
         data.append('price', formData.price);
        data.append('discount', formData.discount);
        data.append('daily_deal_title', formData.daily_deal_title);
        data.append('daily_deal_desc', formData.daily_deal_desc);
        const eimages = existingImages || [];
        eimages.forEach(imageObj => {
            const jsonString = JSON.stringify(imageObj);
            data.append('existing_images[]', jsonString);
        });
        images.forEach(file => data.append('images[]', file.file));
        const pdfFiles = formData.pdfFiles || [];
        pdfFiles.forEach((file) => {
            data.append('pdfFiles[]', file);
        });
        try {
            const response = await axios.post(`${apiUrl}/business/update`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`
                }
            });
            if(response.data.success){
                setSuccessMessage('Form submitted successfully!');
                setError(null);
                setLoading(false);
                 sessionStorage.setItem('successMessage', 'Form submitted successfully!');
                 navigate(`/business/preview/${response.data.id}`);
            }else{
                setError(response.data.error);
                 setSuccessMessage(null);
                 setLoading(false);
            }
        } catch (err) {
            setError('Error submitting the form');
            setSuccessMessage(null);
            console.error('Error:', err);
             setLoading(false);
        }finally {
            setLoading(false);
        } 
    };
    
    /*Start Google Map*/
    
   const onLoad = useCallback((autoC) => {
    setAutocomplete(autoC);
  }, []);

  const onPlaceChanged = () => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace();
      const addressComponents = place.address_components;
       let address = '';
      let country = '';
      let region = '';
      let city = '';
      let zipcode = ''; 
      let address1_latitude = ''; 
      let address1_longitude = ''; 
       
        address = place.formatted_address;
        address1_latitude = place.geometry.location.lat();
        address1_longitude = place.geometry.location.lng();
      setCoordinates({
        lat: place.geometry.location.lat(),
        lng: place.geometry.location.lng(),
      }); 

      addressComponents.forEach(component => {
        const types = component.types;
            
                if (types.includes('locality')) {
                  city = component.long_name;
                }
                if (types.includes('administrative_area_level_1')) {
                  region = component.long_name;
                }
                if (types.includes('country')) {
                  country = component.long_name;
                }
                if (types.includes('postal_code')) {
                  zipcode = component.long_name;
                }
      });
      setFormData(prevFormData => ({
        ...prevFormData,
        address,
        country,
        region,
        city,
        zipcode,
        address1_latitude,
        address1_longitude,
      }));
    } else {
      console.log('Autocomplete is not loaded yet!');
    }
  };
  
  
    /*End Google Map*/
    if (!isLoaded) {
        return <div>Loading...</div>;
      }
    
    return (
        <div className="main_content">
            <div className="main_title">
                <div className="container-fluid">
                    <div className="inner_title">
                        <h2>Dashboard</h2>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="breadcrumb">
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><span><i className="fa fa-angle-right"></i></span></li>
                        <li><span>Dashboard</span></li>
                    </ul>
                </div>
            </div>
        <div className="dashboard_page">
            <div className="container-fluid">
                <div className="inner_dashboard">
                    <Sidebar />
                    <div className="content_right">
                        <div className="listing_form_area">
                            <form className="listing_form" id="myForm" onSubmit={handleSubmit}>
                                <div className="listing_field" >
                                    <div className="listing_title">
                                        <h3>Edit Business Listing</h3>
                                        <span>*  Denotes Required fields</span>
                                    </div>
                                    <div className="listing_form_field">
                                        {error && (
                                            <div className="alert alert-danger mainerror" id="errorresponse">{error}</div>
                                        )}
                                        {successMessage && (
                                            <div className="alert alert-success mainerror" id="successresponse">{successMessage}</div>
                                         )}
                                     {formData.type === 'door-buster-deal' && (
                                        <>
                                        <div className="form-group">
                                            <label className="cus_label">Start Date</label>
                                            <div className="input_field cus_field">
                                                <DatePicker
                                                          selected={formData.start_date}
                                                        onChange={(date) => handleDateChange(date, 'start_date')}
                                                        showTimeSelect
                                                        dateFormat="Pp"
                                                        name="start_date"
                                                        className="form-control"
                                                        timeFormat="HH:mm"
                                                        timeIntervals={15}
                                                        required
                                                    />
                                            </div>
                                        </div>
                                        
                                        <div className="form-group">
                                            <label className="cus_label">Expire Date</label>
                                            <div className="input_field cus_field">
                                                <DatePicker
                                                    selected={formData.expire_date}
                                                    onChange={(date) => handleDateChange(date, 'expire_date')}
                                                    showTimeSelect
                                                    dateFormat="Pp"
                                                    name="expire_date"
                                                    className="form-control"
                                                    timeFormat="HH:mm"
                                                    timeIntervals={15}
                                                    required
                                            />
                                            </div>
                                        </div>
                                        </>
                                        )}
                                        <h5>ENTER LISITING DETAILS</h5>
                                        <div className="form-group">
                                            <label className="cus_label">Business Listing Title <span className="req_field">*</span></label>
                                            <div className="input_field cus_field">
                                                <input required type="text" id="business_listing_title" className="form-control" placeholder="" name="business_listing_title" value={formData.business_listing_title} onChange={handleInputChange}  />
                                                
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="cus_label">Business Listing Description <span className="req_field">*</span></label>
                                            <div className="textarea_field cus_field">
                                                <textarea required id="business_listing_desc" className="form-control" name="business_listing_desc" value={formData.business_listing_desc} onChange={handleInputChange}></textarea>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="cus_label">Tag Keywords</label>
                                            <div className="input_field cus_field">
                                                <input id="tagkeywords" type="text" className="form-control" placeholder="" name="tagkeywords" value={formData.tagkeywords} onChange={handleInputChange} />
                                                <span className="note">Tags are short keywords, with no space within.(eg: tag1, tag2, tag3) Up to 40 characters only for this package.</span>
                                                <span className="text-danger error" id="error-tagkeywords"></span>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="cus_label">Category <span className="req_field">*</span></label>
                                            <div className="select_field cus_field">
                                                <Select2Component
                                                    options={posts}
                                                    selectedValue={selectedValue}
                                                    onChange={handleChange}
                                                />
                                                
                                                <span className="note">SELECT listing category FROM here. SELECT at least one CATEGORY</span>
                                               
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="cus_label">Address <span className="req_field">*</span></label>
                                            <div className="input_field cus_field">
                                                <Autocomplete onLoad={onLoad} onPlaceChanged={onPlaceChanged}>
                                                    <input
                                                        name="address"
                                                       className="form-control"
                                                      type="text"
                                                      value={formData.address} 
                                                      onChange={handleInputChange}
                                                      placeholder="Enter your address"
                                                    />
                                                  </Autocomplete>
                                                <span className="note">Please enter listing address. eg.: 230 Vine Street</span>
                                                
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="cus_label">Country <span className="req_field">*</span></label>
                                            <div className="input_field cus_field">
                                                 <input id="country" type="text" className="form-control" placeholder="" name="country" value={formData.country} onChange={handleInputChange} />
                                                <span className="note">Click on above filed and type to filter list</span>
                                               
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="cus_label">Region <span className="req_field">*</span></label>
                                            <div className="input_field cus_field">
                                                <input id="region" type="text" className="form-control" placeholder="" name="region" value={formData.region} onChange={handleInputChange} />
                                                <span className="note">Click on above filed and type to filter list or add a new region</span>
                                               
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="cus_label">City <span className="req_field">*</span></label>
                                            <div className="input_field cus_field">
                                                <input id="city" type="text" className="form-control" placeholder="" name="city" value={formData.city} onChange={handleInputChange} />
                                                <span className="note">Click on above filed and type to filter list or add a new city</span>
                                                
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="cus_label">Zip/Post Code</label>
                                            <div className="input_field cus_field">
                                                 <input id="zipcode" type="text" className="form-control" placeholder="" name="zipcode" value={formData.zipcode} onChange={handleInputChange} />
                                                <span className="text-danger error" id="error-zipcode"></span>
                                            </div>
                                        </div>
                                        
                                        <div className="google_map">
                                            <GoogleMap
                                                mapContainerStyle={containerStyle}
                                                center={coordinates}
                                                zoom={14}
                                              >
                                                <Marker position={coordinates}   />
                                               </GoogleMap>
                                            <span className="map_note">Click on "Set Address on Map" and then you can also drag pinpoint to locate the correct address.</span>
                                           <input type="hidden" value={formData.address1_latitude} name="address1_latitude" onChange={handleInputChange}  />
                                             <input type="hidden" value={formData.address1_longitude} name="address1_longitude" onChange={handleInputChange} />
                                        </div>
                                        <div className="form-group">
                                            <label className="cus_label">Time</label>
                                            <div className="textarea_field cus_field">
                                                <textarea className="form-control" name="business_time" value={formData.business_time} onChange={handleInputChange}></textarea>
                                                <span className="note">Enter Business or Listing Timing Information. eg.: 10.00 am to 6 pm Mon - Fri, Closed on weekends</span>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="cus_label">Phone</label>
                                            <div className="input_field cus_field">
                                                <input type="text" className="form-control" placeholder="" name="phone" value={formData.phone} onChange={handleInputChange} />
                                                <span className="note">You can enter phone number, call phone number.</span>
                                            </div>
                                        </div>
                                        <div className="form-group"> 
                                            <label className="cus_label">Email</label>
                                            <div className="input_field cus_field">
                                                <input type="email" className="form-control" placeholder="" name="email"value={formData.email} onChange={handleInputChange} />
                                                <span className="note">You can enter your business or listing email.</span>
                                           </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="cus_label">Website</label>
                                            <div className="input_field cus_field">
                                                <input type="text" className="form-control" placeholder="" name="website"  value={formData.website} onChange={handleInputChange} />
                                                <span className="note">You can enter your business or website.</span>
                                            </div>
                                        </div>
                                         {formData.type === 'door-buster-deal' && (
                                         <>
                                        <div className="form-group">
                                            <label className="cus_label">Price</label>
                                            <div className="input_field cus_field">
                                                <input type="number" className="form-control" placeholder="" name="price" value={formData.price} onChange={handleInputChange} />
                                                <span className="note">Enter the price in $(no currency symbol)</span>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="cus_label">Discount</label>
                                            <div className="input_field cus_field">
                                                <input type="number" className="form-control" placeholder="" name="discount" value={formData.discount} onChange={handleInputChange} />
                                                <span className="note">Enter the discount in %(no percentage symbol)</span>
                                            </div>
                                        </div>
                                        </>
                                        )}
                                         {formData.type === 'shop-local' && (
                                         <>
                                        <div className="form-group">
                                          <input type="hidden" name="price" value={formData.price} onChange={handleInputChange} />
                                          <input type="hidden" name="discount" value={formData.discount} onChange={handleInputChange} />
                                            <label className="cus_label">Daily Deal Title</label>
                                            <div className="input_field cus_field">
                                                <input type="text" className="form-control" placeholder="" name="daily_deal_title" value={formData.daily_deal_title} onChange={handleInputChange}  />
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="cus_label">Daily Deal</label>
                                            <div className="textarea_field cus_field">
                                                <textarea className="form-control" name="daily_deal_desc" value={formData.daily_deal_desc} onChange={handleInputChange}></textarea>
                                                <span className="note">Enter Daily for 7P-Star Card Holders eg.: Free Upgrade to a Large Soda When you Show your 7P-Star Card</span>
                                            </div>
                                        </div>
                                        </>
                                        )}
                                         <DropzoneComponent imagelink={imagelink} onFilesAdded={handleFilesAdded} initialFile={existingPDF} />
                                        <div className="multiple_image_upload" >
                                            <div className="upload_title">
                                                <span>(YOU CAN UPLOAD MORE THAN ONE IMAGES TO CREATE IMAGE GALLERY ON DETAIL PAGE) (YOU CAN UPLOAD UNLIMITED IMAGES WITH THIS PACKAGE)</span>
                                            </div> 
                                             <ImageUpload imagelink={imagelink} initialImages={existingImages} onDrop={handleImageDrop} />
                                        </div>
                                        <div className="form-group">
                                            <div className="form-check terms_condition">
                                                <input required className="form-check-input" type="checkbox" value="" id="accept_terms_cond" />
                                                <label className="form-check-label" htmlFor="accept_terms_cond">Please accept our terms and conditions</label>
                                            </div>
                                        </div>  
                                        <div className="form-btn review_list_btn">
                                            {error && (
                                            <div className="alert alert-danger mainerror" id="errorresponse">{error}</div>
                                        )}
                                        {successMessage && (
                                            <div className="alert alert-success mainerror" id="successresponse">{successMessage}</div>
                                         )}
                                            <button disabled={loading} type="submit" className="reviewbtn" id="submitBtn">{loading ? 'Processing...' : 'Review Your Listing'}</button>
                                            <span className="note">Note: You will be able to see a preview in the next page</span>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="clearfix"></div>
            </div>
        </div>
    </div>
    );
};
const Select2Component = ({ options, selectedValue, onChange }) => {
    const selectRef = useRef(null);
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        // Initialize Select2 with custom width
        $(selectRef.current).select2({
            width: '100%', // Adjust width as needed
            dropdownCssClass: 'custom-dropdown'
        });

        // Handle change event
        $(selectRef.current).on('change', (e) => {
            onChange(e.target.value);
        });

        // Mark as initialized
        setInitialized(true);

        // Cleanup Select2 when component unmounts
        return () => {
            $(selectRef.current).select2('destroy');
        };
    }, [onChange]);

    useEffect(() => {
        if (initialized) {
            // Update Select2 when selectedValue changes
            $(selectRef.current).val(selectedValue || '').trigger('change');
        }
    }, [selectedValue, initialized]);

    return (
       <select
            ref={selectRef}
            id="category"
            className="form-control"
            name="category"
            value={selectedValue || ''} // Ensure value is not null
            onChange={(e) => onChange(e.target.value)} // Provide onChange for controlled component warning
        >
            <option value="">--Select Category--</option>
            {options.map(cat => (
                <option key={cat.id} value={`${cat.id}`}>{cat.name}</option>
            ))}
        </select>
        
    );
};


const DropzoneComponent = ({ imagelink = '', initialFile = [], onFilesAdded }) => {
    const [filePreviews, setFilePreviews] = useState([]);
    const [fileList, setFileList] = useState([]);

    // Set initial file previews
    useEffect(() => {
        if ((initialFile ?? []).length > 0) {
            setFilePreviews(
                (initialFile ?? []).map((item, index) => {
                    const imageUrl = typeof item === 'object' && item.image ? item.image : item;
                    const imageName = typeof item === 'object' && item.image_name ? item.image_name : item;

                    return {
                        src: `${imagelink}/${imageUrl}`,
                        id: index,
                        name: imageName
                    };
                })
            );
        }
    }, [initialFile, imagelink]);

    const { getRootProps, getInputProps } = useDropzone({
        accept: '.pdf',
        multiple: false,  
        onDrop: (acceptedFiles) => {
           
            if (acceptedFiles.length > 0) {
                const newFile = acceptedFiles[0];
                setFileList([newFile]); 
                onFilesAdded([newFile]);
            }
        }
    });

    return (
        <div {...getRootProps({ className: 'form-group dropzone' })}>
            <label className="cus_label">Upload PDF</label>
            <input {...getInputProps()} />
            <div className="input_field cus_field">
                <div className="file_upload">
                    <span>Select File</span>
                </div>
                <span className="note">Allowed file types: .pdf</span>
            </div>
            <div className="dropzone-previews" id="pdfPreviewsContainer">
                {/* Display newly added files */}
                {fileList.length > 0 && (
                    <ul>
                        {fileList.map((file, index) => (
                            <li key={index}>
                                <a href={URL.createObjectURL(file)} target="_blank" rel="noopener noreferrer">
                                    {file.name}
                                </a>
                            </li>
                        ))}
                    </ul>
                )}
                {/* Display initial files */}
                {filePreviews.length > 0 && (
                    <ul>
                        {filePreviews.map((file, index) => (
                            <li key={index}>
                                {file.src ? (
                                    <a href={file.src} target="_blank" rel="noopener noreferrer">
                                        {file.name}
                                    </a>
                                ) : (
                                    <span>No valid URL</span>
                                )}
                            </li>
                        ))}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default BusinessEditPage;