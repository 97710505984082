import React, { useState, useCallback, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

const ImageUpload = ({ imagelink = '',initialImages = [], onDrop }) => {
     const [files, setFiles] = useState([]);
 const [imagePreviews, setImagePreviews] = useState([]);
 
  useEffect(() => {
        if ((initialImages ?? []).length > 0) {
                setImagePreviews(
            (initialImages ?? []).map((item, index) => {
                // Check if item is an object with an `image` key or a string URL
                const imageUrl = typeof item === 'object' && item.image ? item.image : item;
                
                return {
                    src: `${imagelink}/${imageUrl}`,
                    id: index
                };
            })
        );
       }
    }, [initialImages]);
   
     const { getRootProps, getInputProps } = useDropzone({
        onDrop: (acceptedFiles) => {
            const newFiles = acceptedFiles.map((file) => ({
                file,
                preview: URL.createObjectURL(file),
            }));

            setFiles((prevFiles) => [...prevFiles, ...newFiles]);
            if (onDrop) onDrop(newFiles); // Call the onDrop callback with the new files
        },
        accept: 'image/*',
        multiple: true,
    });

    const removeFile = (fileToRemove) => {
        setFiles((prevFiles) => prevFiles.filter(file => file.file !== fileToRemove.file));
    };

    const removeImage = (imageId) => {
        setImagePreviews((prevImages) => prevImages.filter(image => image.id !== imageId));
    };
    return (
          <div className="multiple_image_upload">
            <div {...getRootProps({ className: 'dropzone' })}>
                <div className="multi_upload_image">
                    <h4>Drop files to upload</h4>
                    <div className="drop_files">
                        <button type="button" className="file_upload_btn">Select File</button>
                    </div>
                </div>
                <input {...getInputProps()} />
                <div className="rearrange_image dropzone-previews">
                    {files.map((file, index) => (
                        <div key={index} className="preview-item">
                            <img
                                src={file.preview}
                                alt={file.file.name}
                                style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                            />
                            <button type="button" onClick={() => removeFile(file)}>
                                Remove
                            </button>
                        </div>
                    ))}
                    {imagePreviews.map((image) => (
                        <div key={image.id} className="preview-item">
                            <img
                                src={image.src}
                                alt={`Existing ${image.id}`}
                                style={{ width: '100px', height: '100px', objectFit: 'cover' }}
                            />
                            <button type="button" onClick={() => removeImage(image.id)}>
                                Remove
                            </button>
                        </div>
                    ))}
                </div>
            </div>
           
        </div>
    );
};

export default ImageUpload;