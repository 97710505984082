// src/components/HomePage.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { format } from 'date-fns';

const apiUrl = process.env.REACT_APP_API_URL;
const HomePage = () => {
    const [posts, setPosts] = useState({ deals: [],maincategories: [],allevents: [],allcategories: [] });
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isVisible, setIsVisible] = useState(true);
    
     const toggleVisibility = (event) => {
         event.preventDefault(); 
        setIsVisible(prevState => !prevState);
    };
    useEffect(() => {
        const fetchPosts = async () => {
             try {
                const response = await axios.get(`${apiUrl}/homedata`);
                setPosts(response.data);
                setLoading(false);
           } catch (error) {
                setError('Error fetching posts');
                setLoading(false);
                console.error('Error fetching posts:', error);
            }
        };

        fetchPosts();
    }, []);
   
    useEffect(() => {
    // Initialize Owl Carousel
    if (posts.deals.length > 0) {
        //$(document).ready(function() {
         window.$('.latest_deal_carousel').owlCarousel({
          items: 3,
          loop: true,
          lazyLoad: true,
          autoplay: true,
          nav: true,
          dots: false,
          navText: [
            '<img src="/img/carousel-prev-arrow.svg" alt="" />',
            '<img src="/img/carousel-next-arrow.svg" alt="" />'
          ],
          autoplayTimeout: 3000,
          autoplayHoverPause: true,
          responsive: {
            0: {
              items: 1,
              margin: 0,
            },
            768: {
              items: 2,
              margin: 20,
            },
            1000: {
              items: 3,
              margin: 28,
            },
          },
        });

         window.$(".latest_deal_carousel").on("initialized.owl.carousel changed.owl.carousel", function(e) {
          if (!e.namespace) {
            return;
          }
           window.$(".slider_counter").text(
            e.relatedTarget.relative(e.item.index) + 1 + "/" + e.item.count
          );
        });
      //});
    }

    if (posts.allevents.length > 0) {
    
         window.$('.event_deal_carousel').owlCarousel({
          items: 3,
          loop: true,
          lazyLoad: true,
          autoplay: true,
          nav: true,
          dots: false,
          navText: [
            '<img src="/img/carousel-prev-arrow.svg" alt="" />',
            '<img src="/img/carousel-next-arrow.svg" alt="" />'
          ],
          autoplayTimeout: 3000,
          autoplayHoverPause: true,
          responsive: {
            0: {
              items: 1,
              margin: 0,
            },
            768: {
              items: 2,
              margin: 20,
            },
            1000: {
              items: 3,
              margin: 28,
            },
          },
        });

         window.$(".event_deal_carousel").on("initialized.owl.carousel changed.owl.carousel", function(e) {
          if (!e.namespace) {
            return;
          }
           window.$(".slider_counter").text(
            e.relatedTarget.relative(e.item.index) + 1 + "/" + e.item.count
          );
        });
     
    }
  }, [posts.deals.length, posts.allevents.length]);
    
    const generateKey = (section, id) => `${section}-${id}`;
    
    return (
        <div className="main_content">
                <div className="slider_area">
                    <div id="myCarousel" className="carousel slide" data-bs-ride="carousel">
					<div className="carousel-indicators">
						<button type="button" data-bs-target="#myCarousel" data-bs-slide-to="0" className="active" aria-current="true" aria-label="Slide 1"></button>
						<button type="button" data-bs-target="#myCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
						<button type="button" data-bs-target="#myCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
					</div>
					<div className="carousel-inner">
						<div className="carousel-item active">
							<img src="/img/slider-img.jpg" alt="" />
							<div className="container-fluid">
								<div className="carousel-caption text-start">
								
								</div>
							</div>
						</div>
						<div className="carousel-item">
							<img src="/img/slider-img.jpg" alt="" />
							<div className="container-fluid">
								<div className="carousel-caption text-start">
								
								</div>
							</div>
						</div>
						<div className="carousel-item">
							<img src="/img/slider-img.jpg" alt="" />
							<div className="container-fluid">
								<div className="carousel-caption text-start">
								
								</div>
							</div>
						</div>
					</div>
				</div>
            </div>
            <div className="business_category">
                <div className="container-fluid">
                    <div className="row">
						<div className="col-md-12">
							<div className="category_title custom_title">
								<h2>Show Your 7P-Star Card and Save up to 50% OFF at These Local Businesses!</h2>
							</div>
						</div>
					</div>
                    <div className="row">
						<div className="col-md-12">
							<div className="category_list">
								<ul className="main_cate_list">
									{posts.maincategories.length > 0 ? posts.maincategories.map(mcat => (
										<li key={generateKey('maincat', mcat.id)}>
                                        <a href={`/category/${mcat.slug}`} >
                                            <img src={`${posts.urllink}/${mcat.image}`} alt="" /><span>{mcat.name}</span>
                                            </a></li>
									)) : !loading && <p>No posts available.</p>}
									<li key={generateKey('morecat', 1)} className="morecategory" ><a href="#" onClick={toggleVisibility}><img src="/img/more-choice.png" alt="More Choices" /><span>More Choices</span><div className="cate_angledown"><img src="/img/category-angle-down.svg" alt="" /></div></a></li>
                                   
								</ul>
								<div className="clearfix"></div>
                                <div className={`more_category ${!isVisible ? '' : 'hidden'}`}>
									<ul>
                                        {posts.allcategories.length > 0 ? posts.allcategories.map((acat,index) => (
                                            <li key={generateKey('morecat', index)}><a href={`/category/${acat.slug}`}>{acat.name}</a></li>
                                        )) : !loading && <p>No posts available.</p>}
									</ul>
								</div>
							</div>	
						</div>
					</div>
                </div>
            </div>
            <div className="latest_listing cus_listing_sec">
                <div className="container-fluid">
                    <div className="row">
						<div className="col-md-12">
							<div className="listing_title">
								<h3>Lastest Listings</h3> 
							</div>
						</div>
					</div>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="owl-carousel owl-theme latest_deal_carousel common_deal_carousel">  {posts.deals.length > 0 ? posts.deals.map(post => (
                                  <div key={generateKey('deals', post.id)} className="item">
                                        <div className="deal_box">
                                            <div className="deal_img">
                                                <img src={`${posts.urllink}/${post.images}`} alt="" />
                                                <div className="deal_view_btn">
                                                    <a href="">View Deal</a>
                                                </div>
                                            </div>
                                            <div className="deal_content">
                                                <h4>{post.name}</h4>
                                                <div className="deal_loc">
                                                    <p><i className="fa fa-map-marker-alt"></i> <span>{post.address}</span></p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>  
                                )) : !loading && <p>No posts available.</p>}
                            </div>
                            <div className="slider_counter"></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="cus_deal_btns">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-12">
							<div className="deal_btns">
								<a href="/door-buster-deals">Door-Buster Deals!</a>
								<a href="/shop-local">Shop Local</a>
							</div>
						</div>
					</div>
				</div>
			</div>
            <div className="event_listing cus_listing_sec">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-12">
							<div className="listing_title">
								<h3>Find events, buy tickets</h3>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12">
							<div className="owl-carousel owl-theme event_deal_carousel common_deal_carousel"> 	
                                {posts.allevents.length > 0 ? posts.allevents.map(event => (
									<div key={generateKey('events', event.id)} className="item">
										<div className="event_box">
											<div className="event_img">
												<img src={`${posts.urllink}/${event.image}`} />
											</div>
											<div className="event_content">
												<div className="event_date"> 
													<p><img src="/img/calendar-icon.svg" alt="" /> <span>{format(new Date(event.start_date), 'do MMMM, yyyy / h:mma')}</span></p>
												</div>
												<h4>{event.name}</h4>
												<div className="event_btn">
													<a href={event.payment_url}>Buy Tickets</a>
												</div>
											</div>
										</div>
									</div>
								 )) : !loading && <p>No posts available.</p>}
								
							</div>
							<div className="slider_counter"></div>
						</div>
					</div>
				</div>
			</div>
        </div>
    );
};

export default HomePage;