import React, { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [user, setUser] = useState(null);
    const [usertoken, setUserToken] = useState(null);
    
    useEffect(() => {
        const token = localStorage.getItem('authToken') || sessionStorage.getItem('authToken');
        const user = localStorage.getItem('user') || sessionStorage.getItem('user');
       
        if (token && user) {
            setIsAuthenticated(true);
            setUserToken(token);
             setUser(JSON.parse(user));
        }else{
            setIsAuthenticated(false);
             setUser(null);
        }
        
        setLoading(false);
    }, []);

    const login = (token,userData) => {
        sessionStorage.setItem('authToken', token);
        sessionStorage.setItem('user', JSON.stringify(userData));
        setUser(userData);
        setUserToken(token);
        setIsAuthenticated(true);
    };

    const logout = () => {
        sessionStorage.removeItem('authToken');
        setIsAuthenticated(false);
        sessionStorage.removeItem('user');
        localStorage.removeItem('authToken');
        localStorage.removeItem('user');
        setUser(null);
        setUserToken(null);
        navigate('/login');
    };

    return (
        <AuthContext.Provider value={{ isAuthenticated,user,usertoken, login, logout, loading }}>
            {children}
        </AuthContext.Provider>
    );
};