// src/components/HomePage.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { format } from 'date-fns';
const apiUrl = process.env.REACT_APP_API_URL;
const Countdown = ({ startDate, endDate, id }) => {
    const [timeLeft, setTimeLeft] = useState('');

    useEffect(() => {
        const calculateTimeLeft = () => {
            const currentDate = new Date();
            const start = new Date(startDate);
            const end = new Date(endDate);
            
            if (currentDate < start) {
                setTimeLeft('not started yet');
                return;
            } 
            if (currentDate > end) {
                setTimeLeft('ended.');
                return;
            }

            const timeDifference = end - currentDate;
            const daysLeft = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
            const hoursLeft = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutesLeft = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
            const secondsLeft = Math.floor((timeDifference % (1000 * 60)) / 1000);

            setTimeLeft(`${daysLeft} Days ${hoursLeft}:${minutesLeft}:${secondsLeft}`);
        };

        const timer = setInterval(calculateTimeLeft, 1000);

        // Initial call to display the time immediately
        calculateTimeLeft();

        // Cleanup interval on component unmount
        return () => clearInterval(timer);
    }, [startDate, endDate]);

    return (
        <div className="deal_time prod_time">
            <img src="/img/deal-time-icon.svg" alt=""/> <span className={`timeleft${id}`}>Ends in {timeLeft}</span>
        </div>
    );
};
const DealComponent = ({ deal, urllink }) => {
    const price = deal.price;
    const discount = deal.discount;
    const calculatedDiscount = (price * discount) / 100;
    const total = price - calculatedDiscount;
    const savePrice = price - total;

    return (
        <div  className="deal_col prod_col">
            <div className="deal_img prod_img">
                <img src={`${urllink}/${deal.images}`} alt="" />
                <div className="deal_btn prod_btn">
                    <div className="view_btn">
                        <a href={`/door-buster-deals/${deal.category.slug}/${deal.slug}`}>View Deal</a>
                    </div>
                    <Countdown startDate={deal.start_date} endDate={deal.expire_date} id={deal.id} />
                  
                 </div>
            </div>
            
            <div className="deal_content prod_content">
                <h4>{deal.name}</h4>
                <div className="deal_loc_price prod_loc_price">
                    <div className="deal_loc prod_loc">
                        <p><i className="fa fa-map-marker-alt"></i> <span>{deal.address}</span></p>
                    </div>
                    <div className="deal_price prod_price">
                       <p>${Math.round(total)}<br /><span>${price}</span></p>
                    </div>
                </div>
            </div>
        </div>
    );
};

const DoorBusterDealsPage = () => {
    const [posts, setPosts] = useState({ allcategories:[],  alldeals: {
        data: []
    } });
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [viewType, setViewType] = useState('list');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const toggleView = (type) => {
        setViewType(type);
    };
    useEffect(() => {
        const fetchPosts = async (page) => {
             try {
                 setLoading(true);
                const response = await axios.get(`${apiUrl}/door-buster-deals/lists?page=${page}`);
                setPosts(response.data);
                setCurrentPage(response.data.alldeals.current_page);
                setTotalPages(response.data.alldeals.last_page);
           } catch (error) {
                setError('Error fetching blogs');
                setLoading(false);
                console.error('Error fetching blogs:', error);
            } finally {
             setLoading(false);
            }
            };
            fetchPosts(currentPage);
        }, [currentPage]);
    
    const generateKey = (section, id) => `${section}-${id}`;
    
    return (
       <div className="main_content">
			<div className="main_title">
				<div className="container-fluid">
					<div className="inner_title">
						<h2>Door-Buster Deals!</h2>
					</div>
				</div>
			</div>
			<div className="container-fluid">
				<div className="filter_deal_list">
					<div className="sidebar_filter">
						<div className="google_map">
							<img src="/img/google-map-img.jpg" alt=""/>
						</div>
						<div className="category_list">
							<div className="filter_title">
								<h4>I’m looking for:</h4>
							</div>
							<div className="sidebar_list">
								<ul>
									<li ><span>All Categories</span></li>
                                    {posts.allcategories.length > 0 ? posts.allcategories.map(cate => (
                                        <li key={generateKey('cats', cate.id)} ><a href={`/door-buster-deals/${cate.slug}`}>{cate.name}</a><span className="cate_count">{cate.doordeals_count}</span></li>
									)) : !loading && <p>No posts available.</p>}
								</ul>
							</div>
							<div className="filter_title">
								<h4>Locations :</h4>
							</div>
							<div className="sidebar_list">
								<ul>
									<li><span>All Locations</span></li>
									<li><a href="#">San Antonio</a><span className="cate_count">0</span></li>
									<li><a href="#">Fremont</a><span className="cate_count">0</span></li>
									<li><a href="#">Fort Lauderdale</a><span className="cate_count">0</span></li>
									<li><a href="#">Marina del Rey</a><span className="cate_count">0</span></li>
									<li><a href="#">Los Angeles</a><span className="cate_count">0</span></li>
									<li><a href="#">Teaneck</a><span className="cate_count">1</span></li>
								</ul>
								<div className="show_all">
									<a href="#">- Show All Locations -</a>
								</div>
							</div>
						</div>
						<div className="deal_cards">
							<div className="cards_box blue_card">
								<div className="card_img">
									<img src="/img/deal-card-img1.jpg" alt=""/>
								</div>
								<div className="card_content">
									<h3>7P-Star Card Users</h3>
									<p>Press HERE to get started!</p>
									<div className="card_btn">
										<a href="#">Get Started</a>
									</div>
								</div>
							</div>
							<div className="cards_box orange_card">
								<div className="card_img">
									<img src="/img/deal-card-img2.jpg" alt=""/>
								</div>
								<div className="card_content">
									<h3>Priests</h3>
									<p>Grow your temple and re-energize your congregation</p>
									<div className="card_btn">
										<a href="#">Get Started</a>
									</div>
								</div>
							</div>
							<div className="cards_box green_card">
								<div className="card_img">
									<img src="/img/deal-card-img3.jpg" alt=""/>
								</div>
								<div className="card_content">
									<h3>Businesses</h3>
									<p>Connect your business with upto 20,000+ local chuchgoers</p>
									<div className="card_btn">
										<a href="#">Get Started</a>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="filter_content_list">
						<div className="breadcrumb">
							<ul>
								<li><a href="/">Home</a></li>
								<li><span><i className="fa fa-angle-right"></i></span></li>
								<li><span>Door-Buster Deals!</span></li>
							</ul>
						</div>
						<div className="filter_tabs">
							<div className="filter_left filter_col">
								<ul>
									<li><a href="#">View</a></li>
									<li><a href="#" onClick={(e) => {
                                                            e.preventDefault();
                                                            toggleView('list');
                                                        }} ><img src="img/fltr_grid-icon.svg" alt=""/></a></li>
									<li><a href="#" onClick={(e) => {
                                                            e.preventDefault();
                                                            toggleView('grid');
                                                        }}><img src="img/fltr_bars-icon.svg" alt=""/></a></li>
								</ul>
							</div> 
							<div className="filter_right filter_col">
								<ul>
									<li><a href="#"><img src="img/fltr_clock-icon.svg" alt=""/> <span>Time Left</span></a></li>
									<li><a href="#"><img src="img/fltr_star-icon.svg" alt=""/> <span>Ratings</span></a></li>
									<li><a href="#"><img src="img/fltr_calendar-icon.svg" alt=""/> <span>Date</span></a></li>
								</ul>
							</div>
						</div>
                        {viewType === 'grid' ? (
                             <div className="deal_list custom_prod_list cus_listview" id="demo">
                                {posts.alldeals.data.length > 0 ? posts.alldeals.data.map(deal => (
                                <DealComponent key={generateKey('deals', deal.id)} deal={deal} urllink={posts.urllink} />
                                    )) : !loading && <p>No posts available.</p>}
                              </div>
                             
                              
                        ) : (
                            <div className="deal_list custom_prod_list cus_gridview" id="demo">
                                {posts.alldeals.data.length > 0 ? posts.alldeals.data.map(deal => (
                                <DealComponent key={generateKey('deals', deal.id)} deal={deal} urllink={posts.urllink} />
                                    )) : !loading && <p>No posts available.</p>}
                           
                            </div>
                           
                        )}
                         <div className="clearfix"></div>
                              <div className="cus_navigation">
                                    <Pagination
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    onPageChange={page => setCurrentPage(page)}
                                />
                                
                            </div>
					</div>
				</div>
				<div className="clearfix"></div>
			</div>
		</div>
    );
};


const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const handlePrevPage = () => {
        if (currentPage > 1) {
            onPageChange(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            onPageChange(currentPage + 1);
        }
    };

    const handlePageClick = (page) => {
        onPageChange(page);
    };

    return (
        <ul >
            <li className={`first ${currentPage === 1 ? 'disabled' : ''}`}>
                <a onClick={handlePrevPage}><i className="fa fa-angle-left"></i></a>
            </li>
            {[...Array(totalPages).keys()].map(i => (
                <li
                    key={i + 1}
                    className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}
                >
                    <a onClick={() => handlePageClick(i + 1)}>
                       <span> {i + 1} </span>
                    </a>
                </li>
            ))}
            <li className={`last ${currentPage === totalPages ? 'disabled' : ''}`}>
                <a onClick={handleNextPage}><i className="fa fa-angle-right"></i></a>
            </li>
        </ul>
    );
};
export default DoorBusterDealsPage;