import React from 'react';
import { useContext,useState,useEffect } from 'react';
import { AuthContext } from '../context/AuthContext';
import { loadStripe } from '@stripe/stripe-js';

import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;
const stripePromise = loadStripe('pk_test_KnvoObwPwOaUBi0Hhz1nxAeO');
const UpgradePackage = () => {
   
     const { user} = useContext(AuthContext);
    const [UserPackage, setUserPackage] = useState({ products: [] });
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    
    const [processing, setProcessing] = useState(false);
    useEffect(() => {
        const fetchUserPackage = async () => {
            try {
                setLoading(true);
                const response = await axios.get(`${apiUrl}/user-packages`, {
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`
                    }
                });
                setUserPackage(response.data);
            } catch (error) {
                setError('Error fetching user profile');
                setLoading(false);
                console.error('Error fetching user profile:', error);
            }finally {
                setLoading(false);
            }
        };

        fetchUserPackage();
    }, []);
    
    const handleSelectPackage = async (productId) => {
    try {
      setProcessing(productId);
      
      const response = await fetch(`${apiUrl}/create-checkout-session`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          price: productId,
          email: user.email,
          user_id: user.id,
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const { id: sessionId } = await response.json();
      const stripe = await stripePromise;
      const result = await stripe.redirectToCheckout({ sessionId });

      if (result.error) {
        setError(result.error.message);
      }
    } catch (error) {
      setError('Error creating checkout session');
      console.error('Error:', error);
    } finally {
      setProcessing(false);
    }
  };
    
    return (
        <div className="main_content">
            <div className="main_title">
                <div className="container-fluid">
                    <div className="inner_title">
                        <h2>Premium Plan</h2>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="breadcrumb">
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><span><i className="fa fa-angle-right"></i></span></li>
                        <li><span>Premium Plan</span></li>
                    </ul>
                </div>
            </div>
            <div className="container">
                <div className="premium_plan_sec">
                    <div className="premium_plan_title">
                        <img src="/img/premium-plan-icon.svg" alt="" />
                        <h3>Premium</h3>
                    </div>	
                    <div className="premium_plan_option">
                        <h5>Please choose your premium package</h5>
                         {loading ? (
                                <p>Loading...</p>
                            ) : ( 
                                <div className="premium_plan_block">
                                    {UserPackage.products.length > 0 ? UserPackage.products.map(product => (
                                        <div key={product.id} className="premium_plan_col started_plan">
                                            <div className="plan_info">
                                                <div className="plan_title">
                                                    <img src="/img/starter-plan-icon.svg" alt="" /> <span>{product.name}</span>
                                                </div>
                                                <div className="plan_price">
                                                    <span>${product.amount}<small>/{ product.interval_time}</small></span>
                                                </div>
                                            </div>
                                            <div className="plan_list">
                                                <ul>
                                                    <li><img src="/img/premium-plan-list-icon.svg" alt="" /> <span>Up to 50 listings per month</span></li>
                                                    <li><img src="/img/premium-plan-list-icon.svg" alt="" /> <span>100GB data storage</span></li>
                                                    <li><img src="/img/premium-plan-list-icon.svg" alt="" /> <span>No Limit of file size</span></li>
                                                    <li><img src="/img/premium-plan-list-icon.svg" alt="" /> <span>Unlimited access</span></li>
                                                    <li><img src="/img/premium-plan-list-icon.svg" alt="" /> <span>24/7 Virtual Support</span></li>
                                                </ul>
                                            </div>
                                            <div className="plan_select_btn">
                                             {processing === product.strip_id ? (
                                                <span> Processing...</span>
                                            ) : (
                                                <button type="button" onClick={() => handleSelectPackage(product.strip_id)} disabled={processing === product.strip_id} className="cus_btn select_plan_btn">Select</button>
                                             )}
                                            </div>
                                        </div>
                                    )) : ''}
                        </div>
                         )}
                    </div>
                    {error && (
                        <div className="alert alert-danger mainerror" id="errorresponse">{error}</div>
                    )}
                 </div>
                <div className="clearfix"></div>
            </div>
        </div>        
    );
};


export default UpgradePackage;