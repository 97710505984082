// src/components/HomePage.js
import React, { useEffect, useState, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { format } from 'date-fns';
import { AuthContext } from '../context/AuthContext';
const apiUrl = process.env.REACT_APP_API_URL;
const LocalTempleDetailPage = () => {
    const { slug } = useParams();
    const [blog, setBlog] = useState({ allevents:[] });
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
     const [processing, setProcessing] = useState(false);
     const [newReview, setNewReview] = useState(null);
     const [averageRating, setAverageRating] = useState(0);
     const [alertMessage, setAlertMessage] = useState({ visible: false, success: false, title:'', message: '' });
     useEffect(() => {
        const fetchPost = async () => {
             try {
                setLoading(true);
                const response = await axios.get(`${apiUrl}/local-temples/${slug}`);
                setBlog(response.data);
            } catch (error) {
                setError('Error fetching blog');
                setLoading(false);
                console.error('Error fetching blogs:', error);
            } finally {
            setLoading(false);
        }
        };
        fetchPost();
    }, [slug]);
    
    useEffect(() => {
       if (blog.allevents.length > 0) {
          
                 window.$('.event_deal_carousel').owlCarousel({
                    items: 3,
                    loop: true,
                    lazyLoad: true,
                    autoplay: true,
                    nav: true,
                    dots: false,
                    navText: [
                        '<img src="/img/carousel-prev-arrow.svg" alt="" />',
                        '<img src="/img/carousel-next-arrow.svg" alt="" />'
                    ],
                    autoplayTimeout: 3000,
                    autoplayHoverPause: true,
                    responsive: {
                        0: {
                            items: 1,
                            margin: 0,
                        },
                        768: {
                            items: 2,
                            margin: 20,
                        },
                        1000: {
                            items: 3,
                            margin: 28,
                        },
                    },
                });

                 window.$(".event_deal_carousel").on("initialized.owl.carousel changed.owl.carousel", function(e) {
                    if (!e.namespace) {
                        return;
                    }
                     window.$(".slider_counter").text(
                        e.relatedTarget.relative(e.item.index) + 1 + "/" + e.item.count
                    );
                });
            
        }
    }, [blog.allevents.length]);
    
    const onFavDeal = async (productId,type) => {
        const token = sessionStorage.getItem('authToken');
         if (!token) {
            setAlertMessage({
                visible: true,
                success: false,
                message: 'Please log in to add this deal to your favorites.',
                title: 'Login Required!'
            });
            return;
        }

    try {
       setProcessing(true);   
        const data = new FormData();
         data.append('id', productId);
         data.append('type', type);
      const response = await axios.post(`${apiUrl}/addfavorite`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`
            }
        });
        if(response.data.success){
            setAlertMessage({ visible: true, success: true, message: response.data.message,title:'Awesome!' });
        }else{
           setAlertMessage({ visible: true, success: false, message: response.data.error,title:'Alert!' });
        }
    } catch (error) {
        setProcessing(false);   
        setAlertMessage({ visible: true, success: false, message: error,title:'Alert!' });
      
    } finally {
        setProcessing(false);  
            
    }
  };
  
   const handleReviewSubmitted = (newReview) => {
               setNewReview(newReview);
            };
    if (loading) {
        return <p>Loading...</p>;
    }

    if (!blog) {
        return <p>Blog not found.</p>;
    }
    const generateKey = (section, id) => `${section}-${id}`;
    return (
    <>
       <div className="main_content">
        <div className="main_title">
            <div className="container-fluid">
                <div className="inner_title">
                    <h2>{blog.templedetail.name}</h2>
                </div>
            </div>
        </div>
        <div className="container-fluid">
            <div className="breadcrumb">
                <ul>
                    <li><a href="/">Home</a></li>
                    <li><span><i className="fa fa-angle-right"></i></span></li>
                    <li><a href="/local-temples">Local Temples</a></li>
                    <li><span><i className="fa fa-angle-right"></i></span></li>
                    <li><span>{blog.templedetail.name}</span></li>
                </ul>
            </div>
        </div>
        <div className="container-fluid">
            <div className="single_prod_detail">
                <div className="prod_content_left">
                    <div className="single_prod_img">
                        <img src={`${blog.templedetail.image}`} alt="" />
                    </div>
                    <div className="inner_prod_content">
                        <div className="single_prod_loc_fav"> 
                            <div className="single_location">
                                <img src="/img/map-marker.svg" alt="" />
                                <span>{blog.templedetail.address}</span>
                            </div>
                            <div className="single_favorite" >	
                                <img src="/img/prod_fav_grey.svg" onClick={(e) => { e.preventDefault(); onFavDeal(blog.templedetail.id, 'local'); }} alt="" style={{ cursor: 'pointer' }} />
                                <span>{processing ? 'Processing...' : 'Favorite'}</span>
                            </div>
                        </div> 
                        <div className="single_prod_title">
                            <h2>{blog.templedetail.name}</h2>
                        </div>
                        <div className="single_prod_content">
                            <ul className="nav nav-tabs" id="prod_tabs" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link active" id="about-tab" data-bs-toggle="tab" data-bs-target="#about-tab-pane" type="button" role="tab" aria-controls="about-tab-pane" aria-selected="true">About</a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="photo-tab" data-bs-toggle="tab" data-bs-target="#photo-tab-pane" type="button" role="tab" aria-controls="photo-tab-pane" aria-selected="false">Photo Gallery</a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="timings-tab" data-bs-toggle="tab" data-bs-target="#timings-tab-pane" type="button" role="tab" aria-controls="timings-tab-pane" aria-selected="false">Timings</a>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <a className="nav-link" id="map-tab" data-bs-toggle="tab" data-bs-target="#map-tab-pane" type="button" role="tab" aria-controls="map-tab-pane" aria-selected="false">Map</a>
                                </li>
                                <li className="nav-item" role="presentation">
										<a className="nav-link" id="reviews-tab" data-bs-toggle="tab" data-bs-target="#reviews-tab-pane" type="button" role="tab" aria-controls="reviews-tab-pane" aria-selected="false">Reviews</a>
									</li>
                            </ul>
                            <div className="tab-content" id="prod_tabsContent">
                                <div className="tab-pane fade show active" id="about-tab-pane" role="tabpanel" aria-labelledby="about-tab" tabIndex="0">
                                    <div className="inner_single_content" dangerouslySetInnerHTML={{ __html: blog.templedetail.description }}>
                                    
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="photo-tab-pane" role="tabpanel" aria-labelledby="photo-tab" tabIndex="0">
                                    {blog.templedetail.photo_galleries.length > 0 ? blog.templedetail.photo_galleries.map((pimg, index) => (
                                        <a key={generateKey('photo', index)} href={`${pimg}`} data-lightbox="gallery-images">
                                            <img src={`${pimg}`} alt="" style={{ maxWidth: '200px' }} />
                                        </a>
                                    )) : !loading && <p>No posts available.</p>}
                                
                                </div>
                                <div className="tab-pane fade" id="timings-tab-pane" role="tabpanel" aria-labelledby="timings-tab" tabIndex="0">
                                    <ul>
                                        {blog.templedetail.timings.length > 0 ? blog.templedetail.timings.map((time, index) => (
                                        
                                        <li key={generateKey('time', index)} ><span>{time.day}:</span><span>{time.time}</span></li>
                                             )) : !loading && <p>No posts available.</p>}
                                        </ul> 
                                </div>
                                <div className="tab-pane fade" id="map-tab-pane" role="tabpanel" aria-labelledby="map-tab" tabIndex="0">...</div>
                                <div className="tab-pane fade" id="reviews-tab-pane" role="tabpanel" aria-labelledby="reviews-tab" tabIndex="0">
                                    <ReviewList postId={blog.templedetail.id} newReview={newReview} />
                                    <ReviewForm postId={blog.templedetail.id} onReviewSubmitted={handleReviewSubmitted} />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="prod_content_right">						
                    <div className="company_info cus_box_block">
                        <p><span>Hours:</span></p>
                        <div className="hours_list">
                            <ul>
                            {blog.templedetail.timings.length > 0 ? blog.templedetail.timings.map((time, index) => (
                            
                                <li key={generateKey('timea', index)}><span>{time.day}:</span><span>{time.time}</span></li>
                             )) : !loading && <p>No posts available.</p>}
                            </ul> 
                        </div>
                        <p><span>Phone:</span> {blog.templedetail.phone}</p>
                        <p><span>Address:</span><br/>{blog.templedetail.address} <br/>{blog.templedetail.location.name}</p>
                    </div>
                    <div className="prod_maps cus_box_block">
                        <div className="google_map">
                            
                            <img src="/img/prod-single-map.jpg" alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <div className="clearfix"></div>
        </div>
        <div className="event_listing cus_listing_sec upcoming_event">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="listing_title">
                            <h3>Upcoming Events</h3>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="owl-carousel owl-theme event_deal_carousel common_deal_carousel"> 	{blog.allevents.length > 0 ? blog.allevents.map(event => (
                                <div key={generateKey('event', event.id)} className="item">
                                    <div className="event_box">
                                        <div className="event_img">
                                            <img style={{ height: '296px' }} src={`${blog.urllink}/${event.image}`} alt={`${event.name}`} />
                                        </div>
                                    <div className="event_content">
                                        <div className="event_date_loc"> 
                                            <div className="event_date"> 
                                                <p><img src="/img/calendar-icon.svg" alt="" /> <span>{format(new Date(event.start_date), 'do MMMM, yyyy / h:mma')}</span></p>
                                            </div>
                                            
                                        </div>
                                        <h4>{event.name}</h4>
                                        <div className="event_btn">
                                            <a href={event.payment_url}>Visit</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            )) : !loading && <p>No posts available.</p>}
                            
                        </div>
                        <div className="slider_counter"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    {alertMessage.visible && (
            <AlertModal
              title={alertMessage.title}
              message={alertMessage.message}
              success={alertMessage.success}
              onClose={() => setAlertMessage({ visible: false })}
            />
          )}
          </>
    );
};

const AlertModal = ({ title,message, success, onClose }) => {
  return (
    <div className={`modal fade show cust_modal_overlay`} style={{ display: 'block' }} id="myModalalertmessage" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className={`modal-dialog modal-confirm ${success ? 'success' : 'danger'}`}>
        <div className="modal-content">
          <div className="modal-header">
            <div className="icon-box">
						<i className="fa fa-check"></i>
					</div>
            <h4 className="modal-title">{title}</h4>
           
          </div>
          <div className="modal-body">
            <p className="text-center viewmessage">{message}</p>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-success btn-block" onClick={onClose}>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

function ReviewForm({ postId, onReviewSubmitted }) {
    const [rating, setRating] = useState(0);
    const [hover, setHover] = useState(0);
    const [comment, setComment] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
     const { isAuthenticated} = useContext(AuthContext);
     const [successMessage, setSuccessMessage] = useState('');
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await axios.post(`${apiUrl}/listings/${postId}/local-temple/reviews`, {
                rating,
                comment,
            },
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`
                }
            }
            
            );
            onReviewSubmitted(response.data.review);
            setRating(0);
            setComment('');
            setLoading(false);
            setSuccessMessage('Review submitted successfully!');
             setError(null);
        } catch (error) {
            console.error('Error submitting review', error);
            setLoading(false);
            setError('Error submitting review');
            setSuccessMessage(null);
        }
    };
    if (!isAuthenticated) {
        return (
            <div className="reviewform">
                <p>Please <Link to="/login">log in</Link> to submit a review.</p>
            </div>
        );
    }
    return (
        <form onSubmit={handleSubmit}>
            <div className="row reviewform">
                
                {error && (
                    <div className="alert alert-danger mainerror" id="errorresponse">{error}</div>
                )}
                {successMessage && (
                    <div className="alert alert-success mainerror" id="successresponse">{successMessage}</div>
                 )}
                  <h5>Share You Review here</h5>
                <div className="col-md-12 text-center">
                        <div className="form-group">
                            {[...Array(5)].map((star, index) => {
                                const currentRating = index + 1;
                                return (
                                    <Star
                                        key={index}
                                        filled={currentRating <= (hover || rating)}
                                        onClick={() => setRating(currentRating)}
                                        onMouseEnter={() => setHover(currentRating)}
                                        onMouseLeave={() => setHover(rating)}
                                    />
                                );
                            })}
                        </div>
                </div>
                <div className="col-md-12">
                    <div className="form-group">
                        <div className="textarea_field cus_field">
                             <textarea
                                value={comment}
                                className="form-control"
                                placeholder="Share details of your own experience at this place"
                                onChange={(e) => setComment(e.target.value)}
                            />
                            
                        </div>
                    </div>
                    <div className="form-btn">
                         <button type="submit" className="btn btn-primary" disabled={loading}>{loading ? 'Processing...' : 'Submit Review'}</button>
                    </div>
                </div>
            </div>
        </form>
    );
}

function Star({ filled, onClick }) {
    return (
        <span onClick={onClick} style={{ cursor: 'pointer', color: filled ? '#ffd700' : '#e4e5e9', fontSize: '34px' }}>
            ★
        </span>
    );
}

function ReviewList({ postId, newReview  }) {
    const [reviews, setReviews] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        fetchReviews(currentPage);
    }, [currentPage]);
    
     useEffect(() => {
        if (newReview) {
            setReviews((prevReviews) => [newReview, ...prevReviews]); 
        }
    }, [newReview]);
    const fetchReviews = async (page) => {
        try {
            const response = await axios.get(`${apiUrl}/listings/${postId}/local-temple/reviews?page=${page}`);
            setReviews(response.data.data);
            setTotalPages(response.data.last_page);
        } catch (error) {
            console.error('Error fetching reviews', error);
        }
    };

    return (
        <div>
           
            <ul className="review-list">
                {reviews.map((review) => (
                    <li key={review.id} className="review-item">
                        <div className="review-user">{review.user.name}</div>
                        <div className="review-rating">
                            {Array(review.rating).fill().map((_, index) => (
                                <span key={index}>★</span>
                            ))}
                            {Array(5 - review.rating).fill().map((_, index) => (
                                <span key={index} style={{ color: '#e0e0e0' }}>★</span>
                            ))}
                        </div>
                        <div className="review-comment">{review.comment}</div>
                    </li>
                ))}
            </ul>
            <div className="review-pagination">
               <div className="pagination">
                <button
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                >
                    Previous
                </button>
                <button
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                >
                    Next
                </button>
            </div>
            </div>
        </div>
    );
}
export default LocalTempleDetailPage;