import React from 'react';
import { useContext,useState,useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;
const PackageSuccessPage = () => {
    const location  = useLocation();
     const navigate = useNavigate();
    
     const { user} = useContext(AuthContext);
   const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [successMessage, setSuccessMessage] = useState('');
    useEffect(() => {
        const fetchUserPackage = async () => {
            try {
                setLoading(true);
                
                const query = new URLSearchParams(location.search);
                const sessionId = query.get('session_id');
                console.log(sessionId);
                if (!sessionId) {
                    setError('Session ID not found in URL');
                    return;
                  }
                const response = await axios.post(`${apiUrl}/retrieve-session`, { session_id: sessionId }, {
                  headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`
                  }
                });
                if (response.data.success) {
                    const subscription = response.data.subscription;
                    subscription.session_id = sessionId; 
                    const saveResponse = await axios.post(`${apiUrl}/save-subscription`, subscription, {
                    headers: {
                            'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`
                        }
                    });
                    if(saveResponse.data.success){
                        navigate('/billing/index');
                    }else{
                       setError(saveResponse.data.error);
                    }
                }else {
                  setError(response.data.error);
                }
                
                
                
            } catch (error) {
                setError('There is a problem in our system please try again');
                setLoading(false);
            console.log(error);
            }finally {
                setLoading(false);
            }
        };

        fetchUserPackage();
    }, [location.search, navigate]);
    
    return (
        <div className="main_content">
            <div className="main_title">
                <div className="container-fluid">
                    <div className="inner_title">
                        <h2>Payment Processing</h2>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="breadcrumb">
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><span><i className="fa fa-angle-right"></i></span></li>
                        <li><span>Payment Processing</span></li>
                    </ul>
                </div>
            </div>
            <div className="container">
                <div className="premium_plan_sec">
                   
                    <div className="premium_plan_option">
                        {loading && (
                         <>
                                 <h5>Please Wait....</h5>
                                 <p>Do not close window. payment is processing</p>
                           </>
                         )}
                    </div>
                    {error && (
                        <div className="alert alert-danger mainerror" id="errorresponse">{error}</div>
                    )}
                 </div>
                <div className="clearfix"></div>
            </div>
        </div>
    );
};

export default PackageSuccessPage;