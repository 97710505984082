import React, { useState } from 'react';
import ProductFeatures from './ProductFeatures';
const ProductItem = ({ product, productimg, onShowModal  }) => {
    const [quantity, setQuantity] = useState(1);

    const increaseQuantity = (event) => {
        event.preventDefault();
        setQuantity(prevQuantity => prevQuantity + 1);
    };

    const decreaseQuantity = (event) => {
        event.preventDefault();
        setQuantity(prevQuantity => Math.max(prevQuantity - 1, 1)); // Prevent quantity from going below 1
    };
    const handleQuantityChange = (event) => {
        const value = parseInt(event.target.value, 10);
        if (value >= 1) { // Ensure the value is at least 1
            setQuantity(value);
        }
    };
    return (
       <div className="shop_product_column">
            <div className="product_image">
                <img src={`${productimg}/${product.image}`} alt="" />
            </div>
            <div className="product_detail">
                <h4>{product.name}</h4>
                <p>{product.description}</p>
                <ProductFeatures features={product.features} />
                <div className="prod_quantity">
                    <div className="counter_item">
                        <a onClick={decreaseQuantity} className="decrease-btn" data-field="quantity">-</a>
                        <input
                            step="1"
                            min="1"
                            className="input_qty quantity-field"
                            type="number"
                            value={quantity}
                            onChange={handleQuantityChange}
                        />
                        
                        <a onClick={increaseQuantity} data-field="quantity" className="increase-btn">+</a>
                    </div>
                </div>
                <div className="prod_enquire_btn">
                    <a onClick={() => onShowModal(product.id, 1)} className="enquire_btn prod_cus_btn">ENQUIRE NOW</a>
                </div>
            </div>
        </div>
    );
};

export default ProductItem;