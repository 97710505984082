// src/components/HomePage.js
import React, { useState, useContext, useEffect   } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
const apiUrl = process.env.REACT_APP_API_URL;
const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
     const navigate = useNavigate();
     const [rememberMe, setRememberMe] = useState(false);
    const { isAuthenticated,login } = useContext(AuthContext);
    
     useEffect(() => {
        if (isAuthenticated) {
            navigate('/dashboard');
        }
    }, [isAuthenticated, navigate]);
    
    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);
        setError('');

        try {
            const response = await axios.post(`${apiUrl}/login`, { email, password });
            const token = response.data.token;
            const userData = response.data.user;
            if (rememberMe) {
                localStorage.setItem('authToken', token);
                localStorage.setItem('user', JSON.stringify(userData));
            }else{
                sessionStorage.setItem('authToken', token);
                    sessionStorage.setItem('user', JSON.stringify(userData));
            }
            login(token, userData);
            navigate('/dashboard');
        } catch (err) {
            // Handle errors (e.g., display error message)
            console.error('Login error:', err.response ? err.response.data : err.message);
            setError('Login failed. Please check your email and password.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="main_content">
            <div className="main_title">
                <div className="container-fluid">
                    <div className="inner_title">
                        <h2>Sign in to your 7P-Star account</h2>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
				<div className="breadcrumb">
					<ul>
						<li><a href="/">Home</a></li>
						<li><span><i className="fa fa-angle-right"></i></span></li>
						<li><span>Sign in to your 7P-Star account</span></li>
					</ul>
				</div>
				<div className="common_account_sec">
					<h4>Sign In to your 7-P Star Card account</h4>
					<div className="cus_account_form login_form">
						<form onSubmit={handleSubmit}>
							<div className="form_field">
								<div className="form_row">
										<div className="form_col colfull">
                                             {error && <span className="alert alert-danger">{error}</span>}
										</div>
									</div>
                                <div className="form_row">
									<div className="form_col colfull">
										<div className="form-group">
											<div className="input_group input_field">
												<img src="/img/username-icon.svg" alt="" />
												<input autoComplete="off" required type="text" name="email" className="form-control" placeholder="Email" onChange={(e) => setEmail(e.target.value)} value={email} />
											</div>
										</div>
									</div>
									<div className="form_col colfull">
										<div className="form-group">
											<div className="input_group input_field">
												<img src="/img/password-icon.svg" alt="" />
												<input autoComplete="off" required type="password" name="password" value={password}  onChange={(e) => setPassword(e.target.value)} className="form-control" placeholder="Password" />
											</div> 
										</div>
									</div> 
									<div className="form_col colfull">
										<div className="form-group form_flex">
											<div className="form-check">
												<input className="form-check-input" checked={rememberMe} name="rememberme" type="checkbox" value="" id="rememberme" onChange={(e) => setRememberMe(e.target.checked)} />
                                                <label className="form-check-label" htmlFor="rememberme">Remember Me</label>
											</div>
											<div className="forgot_link">
												<a href="/forgot-password">Forgot password?</a>
											</div>
										</div>
									</div>
									<div className="form_col colfull">
										<div className="form-btn">
											<button type="submit"  disabled={loading} name="submit" className="cus_form_btn submit_btn">{loading ? 'Logging in...' : 'Login'}</button>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
				<div className="clearfix"></div>
			</div>
        </div>
    );
};

export default LoginPage;