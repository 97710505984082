// src/components/HomePage.js
import React, { useEffect, useState,useContext } from 'react';
import { useJsApiLoader, GoogleMap, Marker } from '@react-google-maps/api';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import { AuthContext } from '../context/AuthContext';
const apiUrl = process.env.REACT_APP_API_URL;
const googleMapApi = process.env.REACT_APP_GOOGLE_MAP_API_KEY;
const containerStyle = {
  width: '100%',
  height: '292px'
};

const center = {
  lat: -3.745,
  lng: -38.523
};
const Countdown = ({ startDate, endDate }) => {
    const [timeLeft, setTimeLeft] = useState('');
    useEffect(() => {
        const calculateTimeLeft = () => {
            const currentDate = new Date();
            const start = new Date(startDate);
            const end = new Date(endDate);
            
            if (currentDate < start) {
                setTimeLeft('not started yet');
                return;
            } 
            if (currentDate > end) {
                setTimeLeft('ended.');
                return;
            }

            const timeDifference = end - currentDate;
            const daysLeft = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
            const hoursLeft = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            const minutesLeft = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
            const secondsLeft = Math.floor((timeDifference % (1000 * 60)) / 1000);

            setTimeLeft(`${daysLeft} Days ${hoursLeft}:${minutesLeft}:${secondsLeft}`);
        };

        const timer = setInterval(calculateTimeLeft, 1000);

        // Initial call to display the time immediately
        calculateTimeLeft();

        // Cleanup interval on component unmount
        return () => clearInterval(timer);
    }, [startDate, endDate]);

    return (
        <div className="box_content">
            <span>Time Left - Limited Offer!</span> 
            <h3 className="timeleft">{timeLeft}</h3>
        </div>
    );
};

const DealComponent = ({ deal, urllink, onClaimDeal,onLoadingDeal }) => {
    const price = deal.price;
    const discount = deal.discount;
    const calculatedDiscount = (price * discount) / 100;
    const total = price - calculatedDiscount;
    const savePrice = price - total;

    return (
        <div>
            <div className="claim_deal_btn claim_btn cus_box_block">
                <div className="prod_price">
                    <span>${Math.round(total)}</span>
                </div>
                <a href="#" onClick={(e) => { e.preventDefault(); onClaimDeal(deal.id); }} disabled={onLoadingDeal} >{onLoadingDeal ? 'Processing...' : 'Claim This Deal'}</a>
            </div>
            <div className="deal_time_left">
                <div className="time_left_top time_left_col">
                    <div className="deal_icon">
                        <img src="/img/time-left-icon.svg" alt="" />
                    </div>
                    <Countdown startDate={deal.start_date} endDate={deal.expire_date} />
                </div>
                <div className="time_left_btm time_left_col">
                    <div className="deal_icon">
                        <img src="/img/users-icon.svg" alt="" />
                    </div>
                    <div className="box_content">
                        <h3>{deal.claimdetail_count} Bought!</h3>
                    </div>
                </div>
                <div className="deal_price_save">
                    <div className="price_col">
                        <div className="price">Value <span>${price}</span></div>
                        <div className="price">Discount <span>{discount}%</span></div>
                        <div className="price">You Save <span>${savePrice}</span></div>
                    </div>
                </div>
                <div className="social_share_icon">
                    <ul>
                        <li><a href="#"><img src="/img/fb-share-icon.svg" alt="" /></a></li>
                        <li><a href="#"><img src="/img/tw-share-icon.svg" alt="" /></a></li>
                        <li><a href="#"><img src="/img/gplus-share-icon.svg" alt="" /></a></li>
                        <li><a href="#"><img src="/img/email-share-icon.svg" alt="" /></a></li>
                    </ul>
                </div>
            </div>
        </div>
    );
};
const MenuFiles = ({ menuFiles, urlLink }) => {
    if (!menuFiles || menuFiles.length === 0) {
        return null;
    }

    return (
        <div>
            {menuFiles.map((file, index) => (
                <iframe
                    key={index}
                    src={`${urlLink}/${file.image}`}
                    width="800"
                    height="600"
                    title={`Menu File ${index}`}
                ></iframe>
            ))}
        </div>
    );
};

const GalleryImages = ({ galleryImages, urlLink }) => {
    if (!galleryImages || galleryImages.length === 0) {
        return null;
    }

    return (
        <div className="photogallery_list">
            <ul>
            {galleryImages.map((pimg, index) => (
                <li key={index}><a   href={`${urlLink}/${pimg.image}`} data-lightbox="gallery-images">
                   <img src={`${urlLink}/${pimg.image}`} alt="" style={{ maxWidth: '100px' }} />
              </a></li>
            ))}
            </ul>
        </div>
    );
};

const DoorBusterDealDetailPage = () => {
    const [coordinates, setCoordinates] = useState(center);
     const [newReview, setNewReview] = useState(null);
     const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: googleMapApi,
        libraries: ['places'],
      });
    const { cslug,slug } = useParams();
    const [blog, setBlog] = useState(null);
    const [loading, setLoading] = useState(true);
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState(null);
    const [dealDetail, setDealDetail] = useState(null);
    const [showClaimPreview, setShowClaimPreview] = useState(false);
    const [averageRating, setAverageRating] = useState(0);
    const [alertMessage, setAlertMessage] = useState({ visible: false, success: false, message: '' });
     useEffect(() => {
        const fetchPost = async () => {
             try {
                setLoading(true);
                
                const response = await axios.get(`${apiUrl}/doorbusterdeal/${encodeURIComponent(slug)}`);
                setBlog(response.data);
                const lat = parseFloat(response.data.detail.address1_latitude);
                const lng = parseFloat(response.data.detail.address1_longitude);
               
                setCoordinates({
                  lat: lat || center.lat,
                  lng: lng || center.lng,
                });
                setAverageRating(response.data.detail.averageRating || 0);
            } catch (error) {
                setError('Error fetching blog');
                setLoading(false);
                console.error('Error fetching blogs:', error);
            } finally {
            setLoading(false);
        }
        };
        fetchPost();
    }, [cslug,slug]);
    
    
    const handleClaimDeal = async (productId) => {
        const authToken = sessionStorage.getItem('authToken');
        if (!authToken) {
             setAlertMessage({ visible: true, success: false, message: 'You need to log in to claim this deal.' });
            return;
        }
    try {
        
       setProcessing(true);   
        const data = new FormData();
         data.append('id', productId);
        const response = await axios.post(`${apiUrl}/claimdeal`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`
            }
        });
        if(response.data.success){
            setDealDetail(response.data.detail);
            setShowClaimPreview(true);
        }else{
            setAlertMessage({ visible: true, success: false, message: response.data.error });
        }
    } catch (error) {
        console.log(error);
        setProcessing(false);   
        setAlertMessage({ visible: true, success: false, message: 'There is a problem in system'});
      
    } finally {
        setProcessing(false);  
            
    }
  };
       const handlePrint = (e) => {
            e.preventDefault();
            const printContents = document.getElementById('printableContent').innerHTML;
            const originalContents = document.body.innerHTML;

            document.body.innerHTML = printContents;
            window.print();
            document.body.innerHTML = originalContents;
            window.location.reload(); // Reload the page to restore the original content
        };
        
         const handleReviewSubmitted = (newReview) => {
               setNewReview(newReview);
            };
    if (loading) {
        return <p>Loading...</p>;
    }
 if (!isLoaded) {
    return <div>Loading...</div>;
  }
    if (!blog.detail) {
        return <p>Blog not found.</p>;
    }
    
     const renderStars = (rating) => {
                const totalStars = 5;
                const filledStars = Math.floor(rating);
                const halfStar = rating % 1 !== 0;
                const emptyStars = totalStars - filledStars - (halfStar ? 1 : 0);

                return (
                    <div className="stars">
                        {Array(filledStars).fill().map((_, index) => (
                            <img key={`filled-${index}`} src="/img/full-rating-star.png" alt="Star" />
                        ))}
                        {halfStar && <img src="/img/half-rating-star.png" alt="Half Star" />}
                        {Array(emptyStars).fill().map((_, index) => (
                            <img key={`empty-${index}`} src="/img/empty-rating-star.png" alt="Empty Star" />
                        ))}
                    </div>
                );
            };
    return (
    <div>
       <div className="main_content">
			<div className="main_title">
				<div className="container-fluid">
					<div className="inner_title">
						<h2>{blog.detail.name}</h2>
					</div>
				</div>
			</div>
			<div className="container-fluid">
				<div className="breadcrumb">
					<ul>
						<li><a href="/">Home</a></li>
						<li><span><i className="fa fa-angle-right"></i></span></li>
						<li><a href="/door-buster-deals">Door-Buster Deals</a></li>
						<li><span><i className="fa fa-angle-right"></i></span></li>
						<li><a href={`/${blog.detail.category.slug}`}>{blog.detail.category.name}</a></li>
						<li><span><i className="fa fa-angle-right"></i></span></li>
						<li><span>{blog.detail.name}</span></li>
					</ul>
				</div>
			</div>
			<div className="container-fluid">
				<div className="single_prod_detail">
					<div className="prod_content_left">
						<div className="single_prod_img">
							<img src={`${blog.urllink}/${blog.detail.image}`} alt={`${blog.detail.name}`} />
						</div>
						<div className="inner_prod_content">
							<div className="single_prod_loc_rate">
								<div className="single_location">
									<img src="/img/map-marker.svg" alt="" />
									<span>{blog.detail.address}</span>
								</div>
								<div className="single_rating">	
									<div className="stars">	
                                        {renderStars(averageRating)}
									</div>
									<span>({averageRating.toFixed(1)} Rate)</span>
								</div>
							</div>
							<div className="single_prod_title">
								<h2>{blog.detail.name}</h2>
							</div>
							<div className="single_prod_content">
								<ul className="nav nav-tabs" id="prod_tabs" role="tablist">
									<li className="nav-item" role="presentation">
										<a className="nav-link active" id="about-tab" data-bs-toggle="tab" data-bs-target="#about-tab-pane" type="button" role="tab" aria-controls="about-tab-pane" aria-selected="true">About</a>
									</li>
									<li className="nav-item" role="presentation">
										<a className="nav-link" id="photo-tab" data-bs-toggle="tab" data-bs-target="#photo-tab-pane" type="button" role="tab" aria-controls="photo-tab-pane" aria-selected="false">Photo Gallery</a>
									</li>
									<li className="nav-item" role="presentation">
										<a className="nav-link" id="map-tab" data-bs-toggle="tab" data-bs-target="#map-tab-pane" type="button" role="tab" aria-controls="map-tab-pane" aria-selected="false">Menu</a>
									</li>
									<li className="nav-item" role="presentation">
										<a className="nav-link" id="reviews-tab" data-bs-toggle="tab" data-bs-target="#reviews-tab-pane" type="button" role="tab" aria-controls="reviews-tab-pane" aria-selected="false">Reviews</a>
									</li>
								</ul>
								<div className="tab-content" id="prod_tabsContent">
									<div className="tab-pane fade show active" id="about-tab-pane" role="tabpanel" aria-labelledby="about-tab" tabIndex="0">
										<div className="inner_single_content">
											<p dangerouslySetInnerHTML={{ __html: blog.detail.description }}></p>
										</div>
									</div>
									<div className="tab-pane fade" id="photo-tab-pane" role="tabpanel" aria-labelledby="photo-tab" tabIndex="0">
                                    
                                        <GalleryImages galleryImages={blog.detail.images} urlLink={blog.urllink} />
									</div>
									<div className="tab-pane fade" id="map-tab-pane" role="tabpanel" aria-labelledby="map-tab" tabIndex="0">
										<MenuFiles menuFiles={blog.detail.menu_file} urlLink={blog.urllink} />
									
									</div>
									<div className="tab-pane fade" id="reviews-tab-pane" role="tabpanel" aria-labelledby="reviews-tab" tabIndex="0">
                                        <ReviewList postId={blog.detail.id} newReview={newReview} />
                                        <ReviewForm postId={blog.detail.id} onReviewSubmitted={handleReviewSubmitted} />
                                    </div>
								</div>
							</div>
						</div>
					</div>
                    
					
					<div className="prod_content_right">
                        <DealComponent deal={blog.detail} urllink={blog.urllink} onClaimDeal={handleClaimDeal} onLoadingDeal={processing} />
						
						
						<div className="prod_maps cus_box_block">
							<h5>Category: <span>{blog.detail.category.name}</span></h5>
							<div className="google_map">
								 <GoogleMap
                                                mapContainerStyle={containerStyle}
                                                center={coordinates}
                                                zoom={14}
                                              >
                                                <Marker position={coordinates}   />
                                               </GoogleMap>
							</div>
						</div>
						<div className="company_info cus_box_block">
							<p><span>Address:</span><br/>{blog.detail.address} <br/>{blog.detail.region} {blog.detail.city} {blog.detail.country} {blog.detail.zipcode}</p>
							<p><span>Phone:</span>{blog.detail.phone}</p>
							<p><span>Hours:</span></p>
							<div className="hours_list">
								<p dangerouslySetInnerHTML={{ __html: blog.detail.business_time }}></p>
							</div>
						</div>
					</div>
				</div>
				<div className="clearfix"></div>
			</div>
		</div>
      
        {showClaimPreview && dealDetail && (
            <ClaimPreviewModal
              dealDetail={dealDetail}
              onClose={() => setShowClaimPreview(false)}
              onPrint={handlePrint}
            />
          )}
          
          {alertMessage.visible && (
            <AlertModal
              message={alertMessage.message}
              success={alertMessage.success}
              onClose={() => setAlertMessage({ visible: false })}
            />
          )}
        </div>
    );
};

const ClaimPreviewModal = ({ dealDetail, onClose, onPrint }) => {
  return (
    <div className="modal fade show cust_modal_overlay cus_modal calim_modal" id="claimpreview"  tabIndex="-1" aria-labelledby="claimpreviewLabel" aria-hidden="true">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h3>{dealDetail.deal_name}</h3>
            <button type="button" className="btn-close" aria-label="Close" onClick={onClose}><img src="/img/modal-close-icon.svg" alt="" /></button>
          </div>
          <div className="modal-body">
            <div className="claim_preview" id="printableContent">
              <div className="claim_info">
                <h4>{dealDetail.ref_code}</h4>
                <p><b>Name:</b> {dealDetail.user_name}</p>
                <p><b>Redeemed at:</b> {dealDetail.redeemed_at}</p>
                <p><b>Valid until:</b> {dealDetail.valid_date}</p>
                <p><b>Original Value:</b> ${dealDetail.original_val}</p>
                <p><b>This Deal Value:</b> ${dealDetail.deal_val}</p>
              </div>
              <div className="claim_print no-print">
                <a href="#" className="print_btn" onClick={onPrint}>Print</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const AlertModal = ({ message, success, onClose }) => {
  return (
    <div className={`modal fade show cust_modal_overlay`} style={{ display: 'block' }} id="myModalalertmessage" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className={`modal-dialog modal-confirm ${success ? 'success' : 'danger'}`}>
        <div className="modal-content">
          <div className="modal-header">
             <div className="icon-box">
						<i className="fa fa-check"></i>
					</div>
            <h4 className="modal-title">Alert!</h4>
           
          </div>
          <div className="modal-body">
            <p className="text-center viewmessage">{message}</p>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-success btn-block" onClick={onClose}>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};


function ReviewForm({ postId, onReviewSubmitted }) {
    const [rating, setRating] = useState(0);
    const [hover, setHover] = useState(0);
    const [comment, setComment] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
     const { isAuthenticated} = useContext(AuthContext);
     const [successMessage, setSuccessMessage] = useState('');
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await axios.post(`${apiUrl}/listings/${postId}/door-buster/reviews`, {
                rating,
                comment,
            },
            {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`
                }
            }
            
            );
            onReviewSubmitted(response.data.review);
            setRating(0);
            setComment('');
            setLoading(false);
            setSuccessMessage('Review submitted successfully!');
             setError(null);
        } catch (error) {
            console.error('Error submitting review', error);
            setLoading(false);
            setError('Error submitting review');
            setSuccessMessage(null);
        }
    };
    if (!isAuthenticated) {
        return (
            <div className="reviewform">
                <p>Please <Link to="/login">log in</Link> to submit a review.</p>
            </div>
        );
    }
    return (
        <form onSubmit={handleSubmit}>
            <div className="row reviewform">
                
                {error && (
                    <div className="alert alert-danger mainerror" id="errorresponse">{error}</div>
                )}
                {successMessage && (
                    <div className="alert alert-success mainerror" id="successresponse">{successMessage}</div>
                 )}
                  <h5>Share You Review here</h5>
                <div className="col-md-12 text-center">
                        <div className="form-group">
                            {[...Array(5)].map((star, index) => {
                                const currentRating = index + 1;
                                return (
                                    <Star
                                        key={index}
                                        filled={currentRating <= (hover || rating)}
                                        onClick={() => setRating(currentRating)}
                                        onMouseEnter={() => setHover(currentRating)}
                                        onMouseLeave={() => setHover(rating)}
                                    />
                                );
                            })}
                        </div>
                </div>
                <div className="col-md-12">
                    <div className="form-group">
                        <div className="textarea_field cus_field">
                             <textarea
                                value={comment}
                                className="form-control"
                                placeholder="Share details of your own experience at this place"
                                onChange={(e) => setComment(e.target.value)}
                            />
                            
                        </div>
                    </div>
                    <div className="form-btn">
                         <button type="submit" className="btn btn-primary" disabled={loading}>{loading ? 'Processing...' : 'Submit Review'}</button>
                    </div>
                </div>
            </div>
        </form>
    );
}

function Star({ filled, onClick }) {
    return (
        <span onClick={onClick} style={{ cursor: 'pointer', color: filled ? '#ffd700' : '#e4e5e9', fontSize: '34px' }}>
            ★
        </span>
    );
}

function ReviewList({ postId, newReview  }) {
    const [reviews, setReviews] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        fetchReviews(currentPage);
    }, [currentPage]);
    
     useEffect(() => {
        if (newReview) {
            setReviews((prevReviews) => [newReview, ...prevReviews]); 
        }
    }, [newReview]);
    const fetchReviews = async (page) => {
        try {
            const response = await axios.get(`${apiUrl}/listings/${postId}/door-buster/reviews?page=${page}`);
            setReviews(response.data.data);
            setTotalPages(response.data.last_page);
        } catch (error) {
            console.error('Error fetching reviews', error);
        }
    };

    return (
        <div>
           
            <ul className="review-list">
                {reviews.map((review) => (
                    <li key={review.id} className="review-item">
                        <div className="review-user">{review.user.name}</div>
                        <div className="review-rating">
                            {Array(review.rating).fill().map((_, index) => (
                                <span key={index}>★</span>
                            ))}
                            {Array(5 - review.rating).fill().map((_, index) => (
                                <span key={index} style={{ color: '#e0e0e0' }}>★</span>
                            ))}
                        </div>
                        <div className="review-comment">{review.comment}</div>
                    </li>
                ))}
            </ul>
            <div className="review-pagination">
               <div className="pagination">
                <button
                    onClick={() => setCurrentPage(currentPage - 1)}
                    disabled={currentPage === 1}
                >
                    Previous
                </button>
                <button
                    onClick={() => setCurrentPage(currentPage + 1)}
                    disabled={currentPage === totalPages}
                >
                    Next
                </button>
            </div>
            </div>
        </div>
    );
}
export default DoorBusterDealDetailPage;