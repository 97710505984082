import React from 'react';
//import { useState } from 'react';
//import { AuthContext } from '../../context/AuthContext';
import Sidebar from '../layout/Sidebar';

const MyPurchasePage = () => {
     //const { usertoken} = useContext(AuthContext);
     //const [posts, setPosts] = useState({ favdata:{data:[]} });
    //const [error, setError] = useState(null);
    //const [loading, setLoading] = useState(true);
     //const [currentPage, setCurrentPage] = useState(1);
    //const [totalPages, setTotalPages] = useState(1);
    
    return (
        <div className="main_content">
            <div className="main_title">
                <div className="container-fluid">
                    <div className="inner_title">
                        <h2>Dashboard</h2>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="breadcrumb">
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><span><i className="fa fa-angle-right"></i></span></li>
                        <li><span>Dashboard</span></li>
                    </ul>
                </div>
            </div>
            <div className="dashboard_page">
                <div className="container-fluid">
                    <div className="inner_dashboard">
                        <Sidebar />
                        <div className="content_right">
                            <div class="table_data purchase_data"> 
								<div class="cus_title">
									<h4>My Purchases</h4>
								</div>
								<div class="table_list_data">
									<div class="table-responsive">
                                        <table id="invoice_item" class="table">
											<thead>
												<tr>
													<th>Order</th>
													<th>Date</th>
													<th>Status</th>
													<th>Total</th>
													<th>Actions</th>
												</tr>
											</thead>
                                            
											<tbody>
                                           
                                            
											</tbody>
										</table>
                                       
								</div>
								</div>
							</div>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                </div>
            </div>
        </div>
    );
};

export default MyPurchasePage;