import React from 'react';
import { useContext,useState,useEffect } from 'react';
import { AuthContext } from '../../context/AuthContext';
import Sidebar from '../layout/Sidebar';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import ImageUpload from '../ImageUpload';
const apiUrl = process.env.REACT_APP_API_URL;
const ProductEditPage = () => {
    const { productid } = useParams();
    const [formData, setFormData] = useState({
        product_title: '',
        product_desc: '',
        keyfeatures: ''
    });
     const navigate = useNavigate();
     const [imagelink, setImageLink] = useState();
     const { usertoken} = useContext(AuthContext);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
    const [images, setImages] = useState([]);
     const [existingImages, setExistingImages] = useState([]);
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };
    
     useEffect(() => {
        // Fetch user data when the component mounts
        const fetchProductData = async () => {
            try {
                const response = await axios.get(`${apiUrl}/product/detail/${productid}`, {
                    headers: {
                        'Authorization': `Bearer ${usertoken}`
                    }
                });
                const productData = response.data.detail;
               setImageLink(response.data.urllink);
                setFormData({
                    product_title: productData.name,
                    product_desc: productData.description,
                    keyfeatures: productData.features
                });
                setExistingImages(productData.images);
            } catch (error) {
                setError('Error fetching user data');
                console.error('Error fetching user data:', error);
            }
        };

        fetchProductData();
    }, [usertoken]);
    
    const handleImageDrop = (acceptedFiles) => {
        setImages(acceptedFiles);
    };
    
    const handleSubmit = async (e) => {
        e.preventDefault();
         setLoading(true);   
        const data = new FormData();
            data.append('product_title', formData.product_title);
            data.append('id', productid);
            data.append('product_desc', formData.product_desc);
            data.append('keyfeatures', formData.keyfeatures);
            const eimages = existingImages || [];
            eimages.forEach(file => data.append('existing_images[]', file));
            images.forEach(file => data.append('images[]', file.file));

        try {
            const response = await axios.post(`${apiUrl}/product/update`, data, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`
                }
            });
            if(response.data.success){
                setSuccessMessage('Form submitted successfully!');
                setError(null);
                setLoading(false);
                 sessionStorage.setItem('successMessage', 'Form submitted successfully!');
                 navigate('/product/list');
            }else{
                setError(response.data.error);
                 setSuccessMessage(null);
                 setLoading(false);
            }
        } catch (err) {
            setError('Error submitting the form');
            setSuccessMessage(null);
            console.error('Error:', err);
             setLoading(false);
        }finally {
            setLoading(false);
        }
    };
    
    return (
        <div className="main_content">
            <div className="main_title">
                <div className="container-fluid">
                    <div className="inner_title">
                        <h2>Dashboard</h2>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="breadcrumb">
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><span><i className="fa fa-angle-right"></i></span></li>
                        <li><span>Dashboard</span></li>
                    </ul>
                </div>
            </div>
        <div className="dashboard_page">
            <div className="container-fluid">
                <div className="inner_dashboard">
                    <Sidebar />
                    <div className="content_right">
                        <div className="listing_form_area">
                        <form className="listing_form" onSubmit={handleSubmit}>
                            <div className="listing_field">
                                <div className="listing_title">
                                    <h3>Edit a Product</h3>
                                    <span>*  Denotes Required fields</span>
                                </div>
                                    <div className="listing_form_field">
                                        
                                        <h5>ENTER PRODUCT DETAILS</h5>
                                        <div className="form-group">
                                            <label className="cus_label">Product Title <span className="req_field">*</span></label>
                                            <div className="input_field cus_field">
                                                <input required id="product_title" type="text" className="form-control" placeholder="" name="product_title"  value={formData.product_title} onChange={handleInputChange} />
                                                <span className="text-danger error" id="error-product_title"></span> 
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="cus_label">Product Description <span className="req_field">*</span></label>
                                            <div className="textarea_field cus_field">
                                                <textarea required id="product_desc" className="form-control" name="product_desc" value={formData.product_desc} onChange={handleInputChange}></textarea>
                                                <span className="text-danger error" id="error-product_desc"></span> 
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <label className="cus_label">Key Features <span className="req_field">*</span></label>
                                            <div className="input_field cus_field">
                                                <input required id="keyfeatures" type="text" className="form-control" placeholder="" name="keyfeatures" value={formData.keyfeatures} onChange={handleInputChange} />
                                                <span className="note">Write using commas. e.g Easy Install, Cloud Storage,.</span>
                                                
                                            </div>
                                            <span className="text-danger error" id="error-keyfeatures"></span> 
                                        </div> 
                                        <ImageUpload imagelink={imagelink} initialImages={existingImages} onDrop={handleImageDrop} />
                                         
                                        <div className="form-group">
                                            <div className="form-check terms_condition">
                                                <input required className="form-check-input" type="checkbox" value="" id="accept_terms_cond" />
                                                <label className="form-check-label" htmlFor="accept_terms_cond">Please accept our terms and conditions</label>
                                            </div>
                                            <span className="text-danger error" id="error-accept_terms_cond"></span> 
                                        </div>  
                                         {error && (
                                            <div className="alert alert-danger mainerror" id="errorresponse">{error}</div>
                                        )}
                                        {successMessage && (
                                            <div className="alert alert-success mainerror" id="successresponse">{successMessage}</div>
                                         )}
                                        <div className="form-btn review_list_btn">
                                            <button type="submit" disabled={loading} className="reviewbtn" id="submitBtn" >{loading ? 'Processing...' : 'Publish'}</button>
                                        </div>
                                       
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="clearfix"></div>
            </div>
        </div> 
    </div>
    );
};

export default ProductEditPage;