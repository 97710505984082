import React from 'react';
import { useContext,useState,useEffect } from 'react';
import Sidebar from './layout/Sidebar';
import axios from 'axios';
const apiUrl = process.env.REACT_APP_API_URL;
const EditProfilePage = () => {
    const [user, setUser] = useState({ first_name: '', last_name: '', username: '', email: '', bio: '', password: '', password_confirmation: ''  });
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState('');
      // Fetch the user profile on component mount
    useEffect(() => {
        const fetchUserProfile = async () => {
            try {
                const response = await axios.get(`${apiUrl}/user`, {
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`
                    }
                });
                setUser(response.data);
            } catch (error) {
                setError('Error fetching user profile');
                console.error('Error fetching user profile:', error);
            }
        };

        fetchUserProfile();
    }, []);
    
    const handleChange = (e) => {
        const { name, value } = e.target;
        setUser((prevUser) => ({
            ...prevUser,
            [name]: value,
        }));
    };
    
     const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
       
        try {
            const response = await axios.post(`${apiUrl}/update-profile`, user, {
                headers: {
                    'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`,
                    'Content-Type': 'application/json'
                }
            });

            if (response.status === 200) {
                // Update session storage with the new user data
                sessionStorage.setItem('user', JSON.stringify(response.data.user));
                setSuccessMessage('Profile updated successfully!');
                setError(null);
            }
        } catch (error) {
            setError('Error updating profile');
            console.error('Error updating profile:', error);
            setLoading(false);
        }finally {
            setLoading(false);
        }
    };
    const generateKey = (section, id) => `${section}-${id}`;
   
    return (
        <div className="main_content">
            <div className="main_title">
                <div className="container-fluid">
                    <div className="inner_title">
                        <h2>Dashboard</h2>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="breadcrumb">
                    <ul>
                        <li><a href="/">Home</a></li>
                        <li><span><i className="fa fa-angle-right"></i></span></li>
                        <li><span>Dashboard</span></li>
                    </ul>
                </div>
            </div>
            <div className="dashboard_page">
                <div className="container-fluid">
                    <div className="inner_dashboard">
                        <Sidebar />
                        <div className="edit_account">
                            <div className="account_form_area">
                                <form className="account_form" onSubmit={handleSubmit}>
                                    <div className="account_field"> 
                                        <div className="account_title">
                                            <h3>Edit Account</h3>
                                        </div>
                                        <div className="account_form_field">
                                        {error && (
                                            <div className="form_row">
                                                <div className="form_column column_full">
                                                    <div className="form-group">
                                                        <span className="alert alert-danger">{error}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {successMessage && (
                                            <div className="form_row">
                                                <div className="form_column column_full">
                                                    <div className="form-group">
                                                        <span className="alert alert-success">{successMessage}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                           
                                            <div className="form_row">
                                                <div className="form_column column_half">
                                                    <div className="form-group">
                                                        <input required type="text" className="form-control" placeholder="First Name" name="first_name" value={user.first_name}  onChange={handleChange} />
                                                     </div>
                                                </div>
                                                <div className="form_column column_half">
                                                    <div className="form-group">
                                                        <input required type="text" className="form-control" placeholder="Last Name" name="last_name" value={user.last_name} onChange={handleChange} />
                                                       
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form_row">
                                                <div className="form_column column_full">
                                                    <div className="form-group">
                                                        <input disabled type="text" className="form-control" placeholder="Username" name="username" value={user.username}  />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form_row">
                                                <div className="form_column column_full">
                                                    <div className="form-group">
                                                        <div className="input_field cus_field">
                                                            <input disabled type="email" className="form-control" placeholder="Email" name="email" value={user.email} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form_row">
                                                <div className="form_column column_full">
                                                    <div className="form-group"> 
                                                        <textarea className="form-control" placeholder="Bio" name="bio"  onChange={handleChange} value={user.bio}></textarea>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form_row">
                                                <div className="form_column column_full">
                                                    <div className="form-group">
                                                        <input type="password" className="form-control" placeholder="Password" name="password" onChange={handleChange} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form_row">
                                                <div className="form_column column_full">
                                                    <div className="form-group">
                                                        <input type="password" className="form-control" placeholder="Confirm Password" name="password_confirmation" onChange={handleChange} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-btn profile_update_btn">
                                                <button type="submit" disabled={loading} className="submitbtn">{loading ? 'Processing...' : 'Submit'}</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className="clearfix"></div>
                </div>
            </div>
    </div>
    );
};

export default EditProfilePage;