// src/components/Header.js
import React, { useContext } from 'react';
import { Link,NavLink } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
const Header = () => {
    const { isAuthenticated, logout } = useContext(AuthContext);
    return (
        <header className="header">
            <div className="container-fluid">
                <div className="top_strip">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="strip_right">
                                <ul>
                                    {isAuthenticated ? (
                                        <>
                                            <li><Link to="/dashboard">My Dashboard</Link></li>
                                            <li><Link to="/business/list">My Listing</Link></li>
                                            <li><a className="btn btn-warning" onClick={logout}>Logout</a></li>
                                        </>
                                    ) : (
                                        <>
                                            <li><Link to="/login">Login</Link></li>
                                            <li><Link to="/signup">Register</Link></li>
                                        </>
                                    )}
                               </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cus_search_sec">
                    <form className="search_form">
                        <div className="inner_search_form">
                            <div className="cus_search_field select_field">
                                <select className="form-control" name="">
                                    <option value="">Shop Local</option>
                                    <option value="">Shop Local</option>
                                </select>
                                <img src="/img/search-dropdown-arrow.svg" alt="" />
                            </div>
                            <div className="cus_search_field input_field">
                                <input type="text" className="form-control" placeholder="Search For Pizza, Movies, etc." />
                            </div>
                            <div className="cus_search_field input_field">
                                <input type="text" className="form-control" placeholder="Mississauga, ON" />
                            </div>
                            <div className="cus_search_field mapmarker_field"> 
                                <img src="/img/map-marker.png" alt="" /> 
                            </div>
                            <div className="cus_search_field button_field">
                                <button type="button" className="search_btn">Search</button>
                            </div>
                        </div> 
                    </form>
                </div>
            </div>
           <nav className="navbar navbar-expand-lg cus_navbar">
			<div className="container-fluid">
				<a className="navbar-brand" href="/"><img src="/img/logo.png" alt="7P Star" /></a>
				<button className="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarstoggle" aria-controls="navbarstoggle" aria-expanded="false" aria-label="Toggle navigation">
					<span className="navbar-toggler-icon"></span>
				</button>
				<div className="navbar-collapse collapse" id="navbarstoggle">
					<ul className="navbar-nav ms-auto">
						<li className="nav-item">
                            <NavLink
                            to="/door-buster-deals"
                            className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
                            aria-current="page"
                          >
                            Door-Buster Deals!
                          </NavLink>
                       </li>
						<li className="nav-item">
                            <NavLink
                            to="/shop-local"
                            className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
                           >
                            Shop Local
                          </NavLink></li>
						<li className="nav-item">
                            <NavLink
                            to="/local-temples"
                            className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
                           >
                            Local Temples
                          </NavLink>
                        </li>
						<li className="nav-item">
                             <NavLink
                            to="/businesses"
                            className={({ isActive }) => `nav-link ${isActive ? 'active' : ''}`}
                           >
                            Businesses
                          </NavLink>
                        </li>
					</ul>
				</div> 
			</div>
		</nav>
        </header>
    );
};

export default Header;