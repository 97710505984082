import React from 'react';
import { useState, useEffect, useContext, useCallback } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { AuthContext } from '../../context/AuthContext';
import Sidebar from '../layout/Sidebar';
import axios from 'axios';
import { format } from 'date-fns';
const apiUrl = process.env.REACT_APP_API_URL;
const stripePromise = loadStripe('pk_test_KnvoObwPwOaUBi0Hhz1nxAeO');
const CardPage = () => {
    const stripe = useStripe();
    const elements = useElements();
     const { usertoken} = useContext(AuthContext);
     const [posts, setPosts] = useState();
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [processing, setProcessing] = useState(false);
    
    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            // Stripe.js has not loaded yet
            return;
        }

        setProcessing(true);

        const {error, token} = await stripe.createToken(elements.getElement(CardElement));

        if (error) {
            setError(error.message);
            setProcessing(false);
        } else {
            
            console.log('PaymentMethod:', token);
            try {
                // Send payment method ID to your backend
                const response = await fetch(`${apiUrl}/billing/card/update`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${usertoken}`, // Add your auth token here if required
                    },
                    body: JSON.stringify({ token: token.id }),
                });

                const result = await response.json();

                if (response.ok) {
                    console.log('Card updated successfully:', result);
                    // Handle success (e.g., show a confirmation message)
                } else {
                    setError(result.error || 'Failed to update card');
                }
            } catch (error) {
                setError('Error updating card');
            } finally {
                setProcessing(false);
            }
        }
    };
    const fetchPosts = useCallback(async () => {
        try {
            setLoading(true);
            const response = await axios.get(`${apiUrl}/billing/card`, {
                headers: {
                    'Authorization': `Bearer ${usertoken}`
                }
            });
            setPosts(response.data);
            
        } catch (error) {
            setError('Error fetching invoices');
            console.error('Error fetching invoices:', error);
        } finally {
            setLoading(false);
        }
    }, [usertoken]);

    useEffect(() => {
        if (usertoken) {
            fetchPosts();
        }
    }, [fetchPosts, usertoken]);
     
    
    const generateKey = (section, id) => `${section}-${id}`;
    
    
    return (
      <div className="main_content">
	<div className="main_title">
		<div className="container-fluid">
			<div className="inner_title">
				<h2>Dashboard</h2>
			</div>
		</div>
	</div>
	<div className="container-fluid">
		<div className="breadcrumb">
			<ul>
				<li><a href="/">Home</a></li>
				<li><span><i className="fa fa-angle-right"></i></span></li>
				<li><span>Dashboard</span></li>
			</ul>
		</div>
	</div>
	<div className="dashboard_page">
		<div className="container-fluid">
			<div className="inner_dashboard">
				<Sidebar />
				<div className="edit_account">
                {loading ? (
                                <p>Loading...</p>
                            ) : (
                                <>
					<div className="product_listing_sec"> 
                        
                                {posts.hasSubscribed ? (
                                    <>
                                        <div className="account_form_area">
                                            <div className="cus_title" style={{marginTop: '20px'}}>
                                            <h4>Payment Method</h4>
                                        </div>
                                        <div className="product_listing"> 
										<div className="d-flex flex-column">
											<div className="row ml-1 w-100 bg-light rounded p-4 mt-2 border">
												<div className="d-flex w-100 justify-content-between">
													<div className="d-flex flex-column">
														<span className="font-weight-bold">Existing card number</span>
														<span className="text-muted">
														{posts.hasSubscribed ? (
                                                            <>
                                                                **** **** **** {posts.cardInfo.last4}
                                                                </>
														 ) : (
                                                            <>
															-
                                                            </>
                                                         )}
														</span>
													</div> 
													<div className="d-flex flex-column">
														<span className="font-weight-bold">Expiring date</span>
														<span className="text-muted">
														{posts.hasSubscribed ? (
                                                        <>
															{posts.cardInfo.exp_month} / {posts.cardInfo.exp_year}
                                                            </>
														) : (
                                                            <>
															-
                                                            </>
														)}
														</span>
													</div> 
													<div className="d-flex flex-column">
														<span className="font-weight-bold">Brand</span>
														<span className="text-muted">
														{posts.hasSubscribed ? (
                                                        <>
                                                        {posts.cardInfo.brand}
                                                            </>
														) : (
                                                        <>
															-
                                                            </>
														)}
														</span>
													</div>
												</div>
											</div>
										</div>
									</div>
									<form onSubmit={handleSubmit}>
										<div className="account_field"> 
											<div className="account_form_field">
												<div className="form_row">
													<div className="form_column column_full">
														<div className="form-group">
															<label>Card Holder Name*</label>
															<input type="text" name="name" id="name" className="form-control" required="required" />
														</div>
													</div>
												</div>
												<div className="form_row">
													<div className="form_column column_full">
														<div className="form-group">
															<label>Card Number*</label>
															<div className="payment-simple border border-secondary rounded">
																<div id="card-element">
                                                                    <CardElement />
                                                                </div>
															</div>
														</div>
													</div>
												</div>
												<div className="form-btn profile_update_btn">
                                                    {error && <div className="text-error">{error}</div>}
													<button type="submit" id="submit" className="submitbtn" disabled={!stripe || processing}>{processing ? 'Processing...' : 'Submit'}</button>
												</div>
											</div>
										</div>
										
									</form>
								</div>
                                </>
                                ) : (
                                    <>
                                    -
                                 </>
                                )}
							
                            
						</div>
                        </>
                        )}
					</div>
				</div>
			</div>
			<div className="clearfix"></div>
		</div>
	</div>

    );
};

const PaymentWrapper = () => (
    <Elements stripe={stripePromise}>
        <CardPage />
    </Elements>
);
export default PaymentWrapper;