// src/components/HomePage.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { format } from 'date-fns';
const apiUrl = process.env.REACT_APP_API_URL;
const LocalTemplesPage = () => {
    const [posts, setPosts] = useState({ allcategories:[], alllocations:[], alllocalstemples:{ data:[] }, allevents:[]});
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);
    const [viewType, setViewType] = useState('list');
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [processing, setProcessing] = useState(false);
     const [alertMessage, setAlertMessage] = useState({ visible: false, success: false, title:'', message: '' });
    const toggleView = (type) => {
        setViewType(type);
    };
    useEffect(() => {
        const fetchPosts = async (page) => {
             try {
                 setLoading(true);
                const response = await axios.get(`${apiUrl}/local-temples?page=${page}`);
                setPosts(response.data);
               setCurrentPage(response.data.alllocalstemples.current_page);
                setTotalPages(response.data.alllocalstemples.last_page);
           } catch (error) {
                setError('Error fetching blogs');
                setLoading(false);
                console.error('Error fetching blogs:', error);
            } finally {
            setLoading(false);
            }
            };
            fetchPosts(currentPage);
        }, [currentPage]);
    
   useEffect(() => {
       if (posts.allevents.length > 0) {
          
                 window.$('.event_deal_carousel').owlCarousel({
                    items: 3,
                    loop: true,
                    lazyLoad: true,
                    autoplay: true,
                    nav: true,
                    dots: false,
                    navText: [
                        '<img src="/img/carousel-prev-arrow.svg" alt="" />',
                        '<img src="/img/carousel-next-arrow.svg" alt="" />'
                    ],
                    autoplayTimeout: 3000,
                    autoplayHoverPause: true,
                    responsive: {
                        0: {
                            items: 1,
                            margin: 0,
                        },
                        768: {
                            items: 2,
                            margin: 20,
                        },
                        1000: {
                            items: 3,
                            margin: 28,
                        },
                    },
                });

                 window.$(".event_deal_carousel").on("initialized.owl.carousel changed.owl.carousel", function(e) {
                    if (!e.namespace) {
                        return;
                    }
                     window.$(".slider_counter").text(
                        e.relatedTarget.relative(e.item.index) + 1 + "/" + e.item.count
                    );
                });
            
        }
    }, [posts.allevents.length]);
    
    const onFavDeal = async (productId,type) => {
        const token = sessionStorage.getItem('authToken');
         if (!token) {
            setAlertMessage({
                visible: true,
                success: false,
                message: 'Please log in to add this deal to your favorites.',
                title: 'Login Required!'
            });
            return;
        }

    try {
       setProcessing(true);   
        const data = new FormData();
         data.append('id', productId);
         data.append('type', type);
      const response = await axios.post(`${apiUrl}/addfavorite`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authorization': `Bearer ${sessionStorage.getItem('authToken')}`
            }
        });
        if(response.data.success){
            setAlertMessage({ visible: true, success: true, message: response.data.message,title:'Awesome!' });
        }else{
           setAlertMessage({ visible: true, success: false, message: response.data.error,title:'Alert!' });
        }
    } catch (error) {
        setProcessing(false);   
        setAlertMessage({ visible: true, success: false, message: error,title:'Alert!' });
      
    } finally {
        setProcessing(false);  
            
    }
  };
    const generateKey = (section, id) => `${section}-${id}`;
    
    return (
    <>
       <div className="main_content">
			<div className="main_title">
				<div className="container-fluid">
					<div className="inner_title">
						<h2>Local Temples</h2>
					</div>
				</div>
			</div>
			<div className="container-fluid">
				<div className="filter_deal_list">
					<div className="sidebar_filter">
						<div className="google_map">
							<img src="/img/google-map-img.jpg" alt=""/>
						</div>
						<div className="category_list">
							<div className="filter_title">
								<h4>I’m looking for:</h4>
							</div>
							<div className="sidebar_list">
								<ul>
									<li key={generateKey('acat', 1)}><span>All Categories</span></li>
                                    {posts.allcategories.length > 0 ? posts.allcategories.map(cate => (
                                        <li key={generateKey('allcat', cate.id)} ><a href={`/category/${cate.slug}`}>{cate.name}</a><span className="cate_count">{cate.localtemples_count}</span></li>
									)) : !loading && <p>No posts available.</p>}
								</ul>
							</div>
							<div className="filter_title">
								<h4>Locations :</h4>
							</div>
							<div className="sidebar_list">
								<ul>
									<li key={generateKey('aloc', 1)} ><span>All Locations</span></li>
                                    {posts.alllocations.length > 0 ? posts.alllocations.map(loc => (
                                        <li key={generateKey('allloc', loc.id)}><a href={`/category/${loc.slug}`}>{loc.name}</a><span className="cate_count">{loc.localtemples_count}</span></li>
									)) : !loading && <p>No posts available.</p>}
								</ul>
							</div>
						</div>
					</div>
					<div className="filter_content_list">
						<div className="breadcrumb">
							<ul>
								<li><a href="/">Home</a></li>
								<li><span><i className="fa fa-angle-right"></i></span></li>
								<li><span>Local Temples</span></li>
							</ul>
						</div>
						<div className="filter_tabs">
							<div className="filter_left filter_col">
								<ul>
									<li><a href="#">View</a></li>
									<li><a href="#" onClick={(e) => {
                                                            e.preventDefault();
                                                            toggleView('list');
                                                        }} ><img src="/img/fltr_grid-icon.svg" alt=""/></a></li>
									<li><a href="#" onClick={(e) => {
                                                            e.preventDefault();
                                                            toggleView('grid');
                                                        }}><img src="/img/fltr_bars-icon.svg" alt=""/></a></li>
								</ul>
							</div> 
							<div className="filter_right filter_col">
								<ul>
									<li><a href="#"><img src="/img/fltr_clock-icon.svg" alt=""/> <span>Time Left</span></a></li>
									<li><a href="#"><img src="/img/fltr_star-icon.svg" alt=""/> <span>Ratings</span></a></li>
									<li><a href="#"><img src="/img/fltr_calendar-icon.svg" alt=""/> <span>Date</span></a></li>
								</ul>
							</div>
						</div>
                        {viewType === 'grid' ? (
                            <div className="temple_list custom_prod_list cus_listview">
                            {posts.alllocalstemples.data.length > 0 ? posts.alllocalstemples.data.map(post => (
                                <div key={generateKey('temples', post.id)} className="temple_col prod_col">
									<div className="temple_img prod_img">
										<img src={`${post.image}`} alt="" />
										<div className="temple_btn prod_btn">
											<div className="view_btn">
												<a href={`/local-temples/${post.slug}`}>Visit</a>
											</div>
										</div>
									</div>
									<div className="temple_content prod_content">
										<div className="temple_title">
											<h4>{post.name}</h4>
											<div className="temp_fav prod_fav"><img  src="/img/prod_fav_grey.svg" onClick={(e) => { e.preventDefault(); onFavDeal(post.id, 'local'); }} alt="" style={{ cursor: 'pointer' }} />{processing ? 'Processing...' : ''}</div>
										</div>
										<div className="temp_loc_time">
											<div className="temp_time prod_time">
												<img src="/img/clock-timer.svg"  alt="" /> <span>Closes {post.closing_time}</span>
											</div>
											<div className="temp_loc prod_loc">
												<p><img src="/img/map-marker.svg"  alt="" /> <span>{post.address}</span></p>
											</div>
										</div>
									</div>
								</div>
							)) : !loading && <p>No posts available.</p>}
						</div>
                        ) : (
                            <div className="temple_list custom_prod_list cus_gridview">
                            {posts.alllocalstemples.data.length > 0 ? posts.alllocalstemples.data.map(post => (
                                <div key={generateKey('temples', post.id)} className="temple_col prod_col">
									<div className="temple_img prod_img">
										<img src={`${post.image}`} alt="" />
										<div className="temple_btn prod_btn">
											<div className="view_btn">
												<a href={`/local-temples/${post.slug}`}>Visit</a>
											</div>
										</div>
									</div>
									<div className="temple_content prod_content">
										<div className="temple_title">
											<h4>{post.name}</h4>
											<div className="temp_fav prod_fav"><img  src="/img/prod_fav_grey.svg" onClick={(e) => { e.preventDefault(); onFavDeal(post.id, 'local'); }} alt="" style={{ cursor: 'pointer' }} />{processing ? 'Processing...' : ''}</div>
										</div>
										<div className="temp_loc_time">
											<div className="temp_time prod_time">
												<img src="/img/clock-timer.svg"  alt="" /> <span>Closes {post.closing_time}</span>
											</div>
											<div className="temp_loc prod_loc">
												<p><img src="/img/map-marker.svg"  alt="" /> <span>{post.address}</span></p>
											</div>
										</div>
									</div>
								</div>
							)) : !loading && <p>No posts available.</p>}
						</div>
                        )}
						<div className="clearfix"></div>
                              <div className="cus_navigation">
                                    <Pagination
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    onPageChange={page => setCurrentPage(page)}
                                />
                                
                            </div>
					</div>
				</div>
				<div className="clearfix"></div>
			</div>
			<div className="event_listing cus_listing_sec upcoming_event">
				<div className="container-fluid">
					<div className="row">
						<div className="col-md-12">
							<div className="listing_title">
								<h3>Upcoming Events</h3>
							</div>
						</div>
					</div>
					<div className="row">
						<div className="col-md-12">
							<div className="owl-carousel owl-theme event_deal_carousel common_deal_carousel"> 
                                {posts.allevents.length > 0 ? posts.allevents.map(event => (
                                    <div key={generateKey('event', event.id)} className="item">
                                        <div className="event_box">
                                            <div className="event_img">
                                                <img style={{ height: '296px' }} src={`${posts.urllink}/${event.image}`} alt={`${event.name}`} />
                                            </div>
                                            <div className="event_content">
                                                <div className="event_date_loc"> 
                                                    <div className="event_date"> 
                                                        <p><img src="/img/calendar-icon.svg" alt="" /> <span>{format(new Date(event.start_date), 'do MMMM, yyyy / h:mma')}</span></p>
                                                    </div>
                                                </div>
                                                <h4>{event.name}</h4>
                                                <div className="event_btn">
                                                    <a href={event.payment_url}>Visit</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
								)) : !loading && <p>No posts available.</p>}
							</div>
							<div className="slider_counter"></div>
						</div>
					</div>
				</div>
			</div>
		</div>
        {alertMessage.visible && (
            <AlertModal
              title={alertMessage.title}
              message={alertMessage.message}
              success={alertMessage.success}
              onClose={() => setAlertMessage({ visible: false })}
            />
          )}
          </>
    );
};

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
    const handlePrevPage = () => {
        if (currentPage > 1) {
            onPageChange(currentPage - 1);
        }
    };

    const handleNextPage = () => {
        if (currentPage < totalPages) {
            onPageChange(currentPage + 1);
        }
    };

    const handlePageClick = (page) => {
        onPageChange(page);
    };

    return (
        <ul >
            <li className={`first ${currentPage === 1 ? 'disabled' : ''}`}>
                <a onClick={handlePrevPage}><i className="fa fa-angle-left"></i></a>
            </li>
            {[...Array(totalPages).keys()].map(i => (
                <li
                    key={i + 1}
                    className={`page-item ${currentPage === i + 1 ? 'active' : ''}`}
                >
                    <a onClick={() => handlePageClick(i + 1)}>
                       <span> {i + 1} </span>
                    </a>
                </li>
            ))}
            <li className={`last ${currentPage === totalPages ? 'disabled' : ''}`}>
                <a onClick={handleNextPage}><i className="fa fa-angle-right"></i></a>
            </li>
        </ul>
    );
};
const AlertModal = ({ title,message, success, onClose }) => {
  return (
    <div className={`modal fade show cust_modal_overlay`} style={{ display: 'block' }} id="myModalalertmessage" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
      <div className={`modal-dialog modal-confirm ${success ? 'success' : 'danger'}`}>
        <div className="modal-content">
          <div className="modal-header">
            <div className="icon-box">
						<i className="fa fa-check"></i>
					</div>
            <h4 className="modal-title">{title}</h4>
           
          </div>
          <div className="modal-body">
            <p className="text-center viewmessage">{message}</p>
          </div>
          <div className="modal-footer">
            <button type="button" className="btn btn-success btn-block" onClick={onClose}>
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LocalTemplesPage;