// src/components/HomePage.js
import React, { useState   } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
const apiUrl = process.env.REACT_APP_API_URL;
const PasswordReset = () => {
     const [step, setStep] = useState('request');
    const [email, setEmail] = useState('');

    const handleOtpSent = (email) => {
        setEmail(email);
        setStep('verify');
    };

    const handleOtpVerified = () => {
        setStep('reset');
    };
     const handleOtpExpired = () => {
        setStep('request'); // Go back to the Forgot Password form
    };
    return (
        <div>
            {step === 'request' && <ForgotPassword onOtpSent={handleOtpSent} />}
            {step === 'verify' && <VerifyOtp email={email} onSuccess={handleOtpVerified}   onOtpExpired={handleOtpExpired} />}
            {step === 'reset' && <SetNewPassword email={email} />}
        </div>
    );
};


const VerifyOtp = ({ email, onSuccess, onOtpExpired }) => {
    const [otp, setOtp] = useState('');
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const handleVerifyOtp = async (e) => {
        e.preventDefault();
        setLoading(true);
        try {
            const response = await axios.post(`${apiUrl}/verify-otp`, { email, otp });
            if (response.data.success) {
                setMessage('OTP verified. Please set a new password.');
                onSuccess();
            } else if (response.data.expired) {
               setMessage('OTP has expired. Redirecting to Forgot Password...');  
                setTimeout(() => {
                     onOtpExpired();
                }, 3000);
             
            }else {
                setError(response.data.message);
            }
        } catch (error) {
           
             setError('Error verifying OTP.');
         }finally {
            setLoading(false);
        }
    };

    return (
        <div className="main_content">
            <div className="main_title">
                <div className="container-fluid">
                    <div className="inner_title">
                        <h2>Verify OTP</h2>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
				<div className="breadcrumb">
					<ul>
						<li><a href="/">Home</a></li>
						<li><span><i className="fa fa-angle-right"></i></span></li>
						<li><span>Verify OTP</span></li>
					</ul>
				</div>
				<div className="common_account_sec">
					<h4>Verify OTP</h4>
					<div className="cus_account_form login_form">
						<form onSubmit={handleVerifyOtp}>
							<div className="form_field">
								<div className="form_row">
										<div className="form_col colfull">
                                            {error && <span className="alert alert-danger">{error}</span>}
                                            {message && <span className="alert alert-success">{message}</span>}
										</div>
									</div>
                                <div className="form_row">
									<div className="form_col colfull">
										<div className="form-group">
											<div className="input_group input_field">
												<img src="/img/username-icon.svg" alt="" />
                                                 <input
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Enter Otp to verify"
                                                    autoComplete="off"
                                                    value={otp}
                                                    onChange={(e) => setOtp(e.target.value)}
                                                    required
                                                />
											</div>
										</div>
									</div>
									
									
									<div className="form_col colfull">
										<div className="form-btn">
											<button type="submit"  disabled={loading} name="submit" className="cus_form_btn submit_btn">{loading ? 'Processing...' : 'Verify OTP'}</button>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
				<div className="clearfix"></div>
			</div>
        </div>
    );
};


const SetNewPassword = ({ email }) => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [message, setMessage] = useState('');
     const [error, setError] = useState('');
     const [loading, setLoading] = useState(false);
     const navigate = useNavigate(); 
    const handleSetNewPassword = async (e) => {
        e.preventDefault();
       
        if (password !== confirmPassword) {
            setError('Passwords do not match.');
             setLoading(false);
            return;
        }
             setLoading(true);
        try {
            const response = await axios.post(`${apiUrl}/set-new-password`, { email, password });
            if (response.data.success) {
                setMessage('Password updated successfully. Redirecting...');
                
                 setTimeout(() => {
                    navigate('/login');
                }, 4000);
            } else {
                setError('Failed to update password.');
            }
        } catch (error) {
            setError('Error updating password.');
        }finally {
            setLoading(false);
        }
    };

    return (
        <div className="main_content">
            <div className="main_title">
                <div className="container-fluid">
                    <div className="inner_title">
                        <h2>New Password</h2>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
				<div className="breadcrumb">
					<ul>
						<li><a href="/">Home</a></li>
						<li><span><i className="fa fa-angle-right"></i></span></li>
						<li><span>New Password</span></li>
					</ul>
				</div>
				<div className="common_account_sec">
					<h4>New Password</h4>
					<div className="cus_account_form login_form">
						<form onSubmit={handleSetNewPassword}>
							<div className="form_field">
								<div className="form_row">
										<div className="form_col colfull">
                                            {error && <span className="alert alert-danger">{error}</span>}
                                            {message && <span className="alert alert-success">{message}</span>}
										</div>
									</div>
                                <div className="form_row">
									<div className="form_col colfull">
										<div className="form-group">
											<div className="input_group input_field">
												<img src="/img/username-icon.svg" alt="" />
                                                 <input
                                                    type="password"
                                                    className="form-control"
                                                    placeholder="New Password"
                                                    autoComplete="off"
                                                    value={password}
                                                    onChange={(e) => setPassword(e.target.value)}
                                                    required
                                                />
											</div>
										</div>
									</div>
									<div className="form_col colfull">
										<div className="form-group">
											<div className="input_group input_field">
												<img src="/img/username-icon.svg" alt="" />
                                                 <input
                                                    type="password"
                                                    className="form-control"
                                                    placeholder="Confirm Password"
                                                    autoComplete="off"
                                                    value={confirmPassword}
                                                    onChange={(e) => setConfirmPassword(e.target.value)}
                                                    required
                                                />
											</div>
										</div>
									</div>
									
									<div className="form_col colfull">
										<div className="form-btn">
											<button type="submit"  disabled={loading} name="submit" className="cus_form_btn submit_btn">{loading ? 'Processing...' : 'Update Password'}</button>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
				<div className="clearfix"></div>
			</div>
        </div>
    );
};


const ForgotPassword = ({ onOtpSent }) => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const [loading, setLoading] = useState(false);
      const [error, setError] = useState('');
    const handleRequestOtp = async (e) => {
        e.preventDefault();

        setLoading(true);
        setError('');
        try {
            const response = await axios.post(`${apiUrl}/request-otp`, { email });
            if (response.data.success) {
                setMessage('OTP sent to your email.');
               onOtpSent(email); 
            } else {
                setError(response.data.error);
            }
        } catch (error) {
            setError('Error sending OTP.');

        }finally {
            setLoading(false);
        }
    };

    return (
       <div className="main_content">
            <div className="main_title">
                <div className="container-fluid">
                    <div className="inner_title">
                        <h2>Forgot Password</h2>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
				<div className="breadcrumb">
					<ul>
						<li><a href="/">Home</a></li>
						<li><span><i className="fa fa-angle-right"></i></span></li>
						<li><span>Forgot Password</span></li>
					</ul>
				</div>
				<div className="common_account_sec">
					<h4>Forgot Password</h4>
					<div className="cus_account_form login_form">
						<form onSubmit={handleRequestOtp}>
							<div className="form_field">
								<div className="form_row">
										<div className="form_col colfull">
                                             {error && <span className="alert alert-danger">{error}</span>}
                                             {message && <span className="alert alert-success">{message}</span>}
										</div>
									</div>
                                <div className="form_row">
									<div className="form_col colfull">
										<div className="form-group">
											<div className="input_group input_field">
												<img src="/img/username-icon.svg" alt="" />
												<input autoComplete="off" type="text" name="email" className="form-control" placeholder="Email" onChange={(e) => setEmail(e.target.value)} required value={email} />
											</div>
										</div>
									</div>
									
									
									<div className="form_col colfull">
										<div className="form-btn">
											<button type="submit"  disabled={loading} name="submit" className="cus_form_btn submit_btn">{loading ? 'Processing...' : 'Submit'}</button>
										</div>
									</div>
								</div>
							</div>
						</form>
					</div>
				</div>
				<div className="clearfix"></div>
			</div>
        </div>
    );
};

export default PasswordReset;